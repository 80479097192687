import { resolveStoreParams } from '@core/params';
import { updateVideoLiveStreamParams } from '@embed/helpers';
/**
 *
 */
export const actions = (set, get) => ({
    // Common actions between Widgets and fw.tv
    setContext(appContext) {
        // Resets engagement tracking state when the context changes
        set({
            appContext,
            engagement: {},
        }, false, 'setContext');
    },
    // Widgets specific actions
    fetchedParams(fetchedRemoteParams) {
        const state = get();
        const { params, fetchedParams } = resolveStoreParams(state, {
            fetchedParams: fetchedRemoteParams,
        });
        if (fetchedParams) {
            set({
                params,
                fetchedParams,
            }, false, 'fetchedParams');
        }
    },
    setCohortParams(fromCohortParams) {
        set(() => ({ fromCohortParams }), false, 'setCohortParams');
    },
    /**
     * Sets fetched videos to the store for later use.
     * @param videos
     */
    setVideos: (videos) => {
        set({
            videos: videos.reduce((map, video) => {
                map[video.encoded_id] = video;
                return map;
            }, {}),
            navigation: videos.map((video) => video.encoded_id),
            placeholders: [],
        }, false, 'setVideos');
    },
    /**
     * Updates video livestream data from the livestream object
     * received from the WebSocket. WebSocket is used to provide
     * livestream status updates.
     * @param liveStreamId
     * @param liveStream
     */
    updateVideoFromLiveStream: (liveStreamId, liveStream) => {
        set((state) => ({
            videos: {
                ...state.videos,
                [liveStream.video_id]: {
                    ...state.videos[liveStream.video_id],
                    ...updateVideoLiveStreamParams(state.videos[liveStream.video_id], liveStream),
                },
            },
        }), false, 'updateVideoFromLiveStream');
    },
});
