import { GATED_VIDEO_SLICE_KEY } from './slice';
/**
 * Partializes gated video state for local storage
 */
export function partialize(state) {
    return {
        [GATED_VIDEO_SLICE_KEY]: {
            unlockedVideoIds: state[GATED_VIDEO_SLICE_KEY].unlockedVideoIds,
        },
    };
}
/**
 * Merges the persisted data with the current state
 */
export function merge(persistedState, currentState) {
    return {
        [GATED_VIDEO_SLICE_KEY]: {
            ...currentState[GATED_VIDEO_SLICE_KEY],
            ...persistedState[GATED_VIDEO_SLICE_KEY],
        },
    };
}
