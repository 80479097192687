import { ProductBuilder } from '@core/shopping/utils/factories/builders/productBuilder';
/**
 * Product factory able to produce a Api_Product object. Used
 * for hydration purposes.
 */
export const productFactory = (callback, verifyRequiredFields) => {
    const builder = new ProductBuilder(verifyRequiredFields);
    callback(builder);
    return builder.build();
};
