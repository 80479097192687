import { SLICE_KEY } from './slice';
/**
 * Fetch channel actions
 */
export function channelActionsSelector(state) {
    return state[SLICE_KEY]?.actions;
}
/**
 * Fetch channel by username
 */
export function channelByUsernameSelector(username) {
    return (state) => {
        return Object.values(state[SLICE_KEY]?.byId || {}).find((ch) => ch.username === username);
    };
}
/**
 * Fetch channel by encodedId
 */
export function channelByIdSelector(encodedId) {
    return (state) => {
        return state[SLICE_KEY]?.byId[encodedId];
    };
}
