import { getLocalStorage } from '@core/storage';
const toThenable = (fn) => (input) => {
    try {
        const result = fn(input);
        if (result instanceof Promise) {
            return result;
        }
        return {
            then(onFulfilled) {
                return toThenable(onFulfilled)(result);
            },
            catch() {
                return this;
            },
        };
    }
    catch (e) {
        return {
            then() {
                return this;
            },
            catch(onRejected) {
                return toThenable(onRejected)(e);
            },
        };
    }
};
/**
 *
 */
export const persist = (config, baseOptions) => (set, get, api) => {
    let options = {
        getStorage: () => getLocalStorage(),
        serialize: JSON.stringify,
        deserialize: JSON.parse,
        partialize: (state) => state,
        version: 0,
        merge: (persistedState, currentState) => ({
            ...currentState,
            ...persistedState,
        }),
        ...baseOptions,
    };
    if (options.blacklist || options.whitelist) {
        // eslint-disable-next-line no-console
        console.warn(`The ${options.blacklist ? 'blacklist' : 'whitelist'} option is deprecated and will be removed in the next version. Please use the 'partialize' option instead.`);
    }
    let hasHydrated = false;
    const hydrationListeners = new Set();
    const finishHydrationListeners = new Set();
    let storage;
    try {
        storage = options.getStorage();
    }
    catch (e) {
        // prevent error if the storage is not defined (e.g. when server side rendering a page)
    }
    if (!storage) {
        return config(((...args) => {
            // eslint-disable-next-line no-console
            console.warn(`[zustand persist middleware] Unable to update item '${options.name}', the given storage is currently unavailable.`);
            set(...args);
        }), get, api);
    }
    else if (!storage.removeItem) {
        // eslint-disable-next-line no-console
        console.warn(`[zustand persist middleware] The given storage for item '${options.name}' does not contain a 'removeItem' method, which will be required in v4.`);
    }
    const thenableSerialize = toThenable(options.serialize);
    const setItem = () => {
        const state = options.partialize({ ...get() });
        if (options.whitelist) {
            ;
            Object.keys(state).forEach((key) => {
                !options.whitelist?.includes(key) && delete state[key];
            });
        }
        if (options.blacklist) {
            options.blacklist.forEach((key) => delete state[key]);
        }
        let errorInSync;
        const thenable = thenableSerialize({ state, version: options.version })
            .then((serializedValue) => storage.setItem(options.name, serializedValue))
            .catch((e) => {
            errorInSync = e;
        });
        if (errorInSync) {
            throw errorInSync;
        }
        return thenable;
    };
    const savedSetState = api.setState;
    api.setState = (state, replace) => {
        savedSetState(state, replace);
        void setItem();
    };
    const configResult = config(((...args) => {
        set(...args);
        void setItem();
    }), get, api);
    // a workaround to solve the issue of not storing rehydrated state in sync storage
    // the set(state) value would be later overridden with initial state by create()
    // to avoid this, we merge the state from localStorage into the initial state.
    let stateFromStorage;
    // rehydrate initial state with existing stored state
    const hydrate = () => {
        if (!storage) {
            return;
        }
        hasHydrated = false;
        hydrationListeners.forEach((cb) => cb(get()));
        const postRehydrationCallback = options.onRehydrateStorage?.(get()) || undefined;
        // bind is used to avoid `TypeError: Illegal invocation` error
        return toThenable(storage.getItem.bind(storage))(options.name)
            .then((storageValue) => {
            if (storageValue) {
                return options.deserialize(storageValue);
            }
        })
            .then((deserializedStorageValue) => {
            if (deserializedStorageValue) {
                if (typeof deserializedStorageValue.version === 'number' &&
                    deserializedStorageValue.version !== options.version) {
                    if (options.migrate) {
                        return options.migrate(deserializedStorageValue.state, deserializedStorageValue.version);
                    }
                    console.error(`State loaded from storage couldn't be migrated since no migrate function was provided`);
                }
                else {
                    return deserializedStorageValue.state;
                }
            }
        })
            .then((migratedState) => {
            stateFromStorage = options.merge(migratedState, get() ?? configResult);
            set(stateFromStorage, true);
            return setItem();
        })
            .then(() => {
            postRehydrationCallback?.(stateFromStorage, undefined);
            hasHydrated = true;
            finishHydrationListeners.forEach((cb) => cb(stateFromStorage));
        })
            .catch((e) => {
            postRehydrationCallback?.(undefined, e);
        });
    };
    api.persist = {
        setOptions: (newOptions) => {
            options = {
                ...options,
                ...newOptions,
            };
            if (newOptions.getStorage) {
                storage = newOptions.getStorage();
            }
        },
        clearStorage: () => {
            storage?.removeItem?.(options.name);
        },
        rehydrate: () => hydrate(),
        hasHydrated: () => hasHydrated,
        onHydrate: (cb) => {
            hydrationListeners.add(cb);
            return () => {
                hydrationListeners.delete(cb);
            };
        },
        onFinishHydration: (cb) => {
            finishHydrationListeners.add(cb);
            return () => {
                finishHydrationListeners.delete(cb);
            };
        },
        getOptions: () => options,
    };
    hydrate();
    return stateFromStorage || configResult;
};
