import { getGlobalConfig } from '@core/globalConfig';
import { pruneNilValues } from '@core/object';
import { parseQueryParams } from '@core/window';
import { debugParams } from './debugParams';
import { sanitizeWidgetParams } from './sanitizeWidgetParams';
import { urlWidgetParams } from './urlWidgetParams';
/**
 * Resolves params in particular order and returns store structure.
 */
export function resolveStoreParams(state, // On store creation initialState is provided
attrs) {
    const { defaultParams, fromCohortParams } = state;
    // Initial widget specific params
    const initialParams = attrs?.initialParams ?? state.initialParams;
    // Fetched as channel.config from API source
    const fetchedParams = attrs?.fetchedParams
        ? sanitizeWidgetParams(pruneNilValues(attrs.fetchedParams), {
            skip: ['max_videos', 'mode'], // TODO(CS-2991, CS-6770): Remove this when we remove max_videos from channel.config
        })
        : state.fetchedParams;
    // Widget params specified via integration code as a html attributes
    const widgetParams = attrs?.widgetParams
        ? sanitizeWidgetParams(attrs.widgetParams)
        : state.widgetParams;
    // Specified by a url query params `fwparam_{name}` likely to quickly override other params
    const urlParams = sanitizeWidgetParams(urlWidgetParams(parseQueryParams()));
    // Specified by a `data-{name}` html attributes in a <script> tag to provide
    // global config params to all widgets on a page
    const globalConfigParams = sanitizeWidgetParams(getGlobalConfig() ?? {});
    const params = {
        ...defaultParams,
        ...initialParams,
        ...fetchedParams,
        ...globalConfigParams,
        ...widgetParams,
        ...urlParams,
        ...fromCohortParams,
    };
    debugParams({
        defaultParams,
        initialParams,
        fetchedParams,
        globalConfigParams,
        widgetParams,
        urlParams,
    }, params);
    return {
        params,
        initialParams,
        fetchedParams,
        widgetParams,
        urlParams,
        globalConfigParams,
    };
}
