import styled from '@emotion/styled';
export const Title = styled.div `
  font-size: ${({ size }) => `${size ?? 28}px`};
  font-weight: 500;
  margin: 0;
  padding: 20px 10px;
  text-align: ${({ position }) => position ?? 'left'};
`;
export const TitlePlaceholder = styled.div `
  height: 74px;
`;
