import { useContext, useMemo } from 'react';
import { createApiService } from '@core/api';
import { ServiceApiContext } from '@core/api/context/serviceApi';
import { defaultApiHost } from '@src/helpers';
/** @returns the service API object from the context. */
export function useApi() {
    const api = useContext(ServiceApiContext);
    if (!api) {
        throw new Error('Service API is not set in the context');
    }
    return api;
}
/**
 * Use this hook if the service api is not provided from the context.
 * @returns the memoized service API object.
 */
export function useApiWithParams(params) {
    const api = useMemo(() => createApiService(params?.api_host ?? defaultApiHost), [params?.api_host]);
    return api;
}
