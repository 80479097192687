import { attachListeners } from './attachListeners';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'playersStateSync')) {
        Object.defineProperty(window._fwn, 'playersStateSync', {
            value: {},
            writable: false,
        });
        attachListeners();
    }
}
