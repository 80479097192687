/**
 *
 */
export default function testWebpSupport(callback) {
    if (typeof Image === 'function') {
        const lossyWebp = 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
        const img = new Image();
        img.onload = () => {
            callback(img.width > 0 && img.height > 0);
        };
        img.onerror = () => {
            callback(false);
        };
        img.src = 'data:image/webp;base64,' + lossyWebp;
    }
    else {
        callback(false);
    }
}
