/**
 * Returns URL object out of `template` string which might not be a valid URL.
 * @param template
 * @returns URL Object
 */
export function getShareTemplate(template) {
    if (!template) {
        return null;
    }
    try {
        return new URL(template);
    }
    catch (err) {
        try {
            return new URL(`http://${template}`);
        }
        catch (err) {
            return null;
        }
    }
}
