import { fwnConsole } from './fwnConsole';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'console')) {
        // Setting up console
        Object.defineProperty(window._fwn, 'console', {
            value: fwnConsole(),
            writable: false,
        });
    }
}
