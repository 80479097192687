import { initialState as commonInitialState } from './common';
export const initialState = {
    ...commonInitialState,
    params: {},
    fromCohortParams: {},
    initialParams: {},
    fetchedParams: {},
    widgetParams: {},
    urlParams: {},
    videos: {},
    liveStreams: {},
    navigation: [],
    placeholders: [],
    loading: false,
    loaded: false,
    error: null,
};
