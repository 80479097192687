import { createSlice as createChannelSlice } from '@core/channel/model/slice';
import { createDeviceSettingsSlice } from '@core/deviceSettings';
import { createGatedVideoSlice } from '@core/gatedVideo/model/slice';
import { createHydrationSlice } from '@core/hydration/model';
import { createLiveStreamSlice } from '@core/livestream/model';
import { createPlayerSlice } from '@core/player/model';
import { createShoppingSlice } from '@core/shopping/model';
import { createSharedGlobalState } from '@core/store/sharedState/state';
/**
 *
 */
export const createState = (set) => ({
    ...createHydrationSlice(set),
    ...createPlayerSlice(),
    ...createLiveStreamSlice(),
    ...createDeviceSettingsSlice(),
    ...createShoppingSlice(),
    ...createSharedGlobalState(set),
    ...createGatedVideoSlice(),
    ...createChannelSlice(),
    setState: (state) => set(state),
});
