import { importVideoPlayer } from '@embed/components/VideoPlayer';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'play')) {
        Object.defineProperty(window._fwn, 'play', {
            get() {
                return (...args) => {
                    importVideoPlayer();
                    import(/* webpackChunkName: "play" */ './play').then(({ play }) => {
                        play(...args);
                    });
                };
            },
        });
    }
}
