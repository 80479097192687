export var CssValueTypeEnum;
(function (CssValueTypeEnum) {
    CssValueTypeEnum["COLOR"] = "color";
    CssValueTypeEnum["LENGTH"] = "length";
    CssValueTypeEnum["FONT_STACK"] = "font-stack";
    CssValueTypeEnum["GRADIENT"] = "gradient";
    CssValueTypeEnum["BORDER"] = "border-shorthand";
    CssValueTypeEnum["FONT_WEIGHT"] = "font-weight";
    CssValueTypeEnum["LENGTH_LIST"] = "length-list";
    CssValueTypeEnum["SHADOW"] = "shadow";
    CssValueTypeEnum["TEXT_TRANSFORM"] = "text-transform";
    CssValueTypeEnum["OBJECT_FIT"] = "object-fit";
})(CssValueTypeEnum || (CssValueTypeEnum = {}));
