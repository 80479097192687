/**
 * @fileoverview
 * Hook to process cohorts from the params and
 * update params with fetched playlist.
 */
import { useEffect, useMemo } from 'react';
import { useApi } from '@core/api/hooks/useApi';
import { logWarning } from '@core/logging';
import { useStore } from '@core/widgets/store';
import { paramsSelector } from '@core/widgets/store/selectors';
/**
 *
 */
export function useCohorts({ channel }) {
    const api = useApi();
    const actions = useStore((state) => state.actions);
    const params = useStore(paramsSelector);
    const cohortsSerialized = useMemo(() => JSON.stringify(Object.entries(params)
        .filter(([key]) => key.startsWith('cohort_'))
        .sort()), [params]);
    useEffect(() => {
        ;
        (async () => {
            try {
                const cohortsEntries = JSON.parse(cohortsSerialized);
                if (channel && cohortsEntries.length) {
                    const response = await api.getPlaylistFromCohorts(channel.username, Object.fromEntries(cohortsEntries));
                    actions.setCohortParams({ playlist: response.playlist_id });
                }
            }
            catch (err) {
                logWarning(`Error when fetching playlist from cohorts: ${err.data.error}`);
            }
        })();
    }, [cohortsSerialized, api, actions, channel]);
}
