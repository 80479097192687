/** An array of DOM events that should be interpreted as user activity. */
export const DEFAULT_ACTIVITY_EVENTS = [
    'click',
    'mousedown',
    'mousemove',
    'keydown',
    'scroll',
    'touchstart',
];
/**
 * Detects activities and triggers the listener. The listener will be triggered
 * if any of the allowed activities are detected.
 * @param listener The listener to trigger when activities are detected.
 * @param activities List of activities to listen to.
 * @param once If true, the listener will only be triggered once. Default to true.
 * @returns A function to unsubscribe all the listeners.
 */
export function addActivityDetector(listener, activities = DEFAULT_ACTIVITY_EVENTS, once = true) {
    let calledOnce = false;
    const cb = () => {
        if (!once || !calledOnce) {
            listener();
            calledOnce = true;
        }
    };
    const unsubscribers = [];
    activities.forEach((eventName) => {
        document.addEventListener(eventName, cb, {
            capture: true,
            once,
            passive: true, // needed for improving scrolling performance.
        });
        unsubscribers.push(() => {
            document.removeEventListener(eventName, cb, { capture: true });
        });
    });
    return () => {
        unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
}
