/**
 * Partial state to be persisted.
 */
export function partialize(state) {
    return {
        persistedData: state.persistedData,
    };
}
/**
 * Merge persisted state with current state.
 */
export function merge(persistedState, currentState) {
    return {
        ...currentState,
        ...persistedState,
    };
}
