import { AdFetchingStatus } from '@expPlatform/embeds/ads/constants';
import { fetchFwaiAds, fetchSsaiAds } from '@expPlatform/embeds/ads/utils/api';
import { fetchGAM } from '@expPlatform/embeds/ads/utils/gam';
/**
 * Returns ad tag URL with vastAttrs overwriting existing params
 */
export const mergeCustomVastAttrs = (adTag, vastAttrs) => {
    if (vastAttrs && !adTag.includes(vastAttrs)) {
        const adTagUrl = new URL(adTag);
        const customAttrs = new URLSearchParams(vastAttrs);
        for (const [key, _value] of customAttrs.entries()) {
            if (adTagUrl.searchParams.has(key)) {
                adTagUrl.searchParams.delete(key);
            }
        }
        return decodeURIComponent(String(adTagUrl)) + vastAttrs;
    }
    return adTag;
};
/**
 *
 */
export const getFWAIAds = async (adUnit, params) => {
    const adTagWithVastAttrs = mergeCustomVastAttrs(adUnit.ad_tag, params?.vast_attrs);
    const xmlTags = await fetchGAM(adTagWithVastAttrs);
    if (xmlTags.length === 0) {
        return { status: AdFetchingStatus.EMPTY_VAST };
    }
    const ads = (await Promise.all(xmlTags.map((xml) => fetchFwaiAds({
        xml,
        params,
    })))).flat();
    if (ads.length > 0) {
        return { status: AdFetchingStatus.OK, ads };
    }
    return { status: AdFetchingStatus.NO_NABOO_ADS };
};
/**
 *
 */
export const getSSAIAds = async ({ adUnit, adConfig, appContext, params, }) => {
    const ads = await fetchSsaiAds({
        adUnit,
        adConfig,
        appContext,
        params,
    });
    if (ads.length > 0) {
        return { status: AdFetchingStatus.OK, ads };
    }
    return { status: AdFetchingStatus.NO_NABOO_ADS };
};
/**
 *
 */
export const getAdsForAdTag = ({ adUnit, adConfig, appContext, params, }) => {
    switch (true) {
        case adUnit.ad_insertion_method === 'SSAI':
            return getSSAIAds({
                adConfig,
                adUnit,
                appContext,
                params,
            });
        case adUnit.ad_insertion_method === 'FWAI':
            return getFWAIAds(adUnit, params);
        default: {
            return {
                status: AdFetchingStatus.BAD_INSERTION_METHOD,
            };
        }
    }
};
