import { css } from '@emotion/react';
import { BREAKPOINT_SIZES } from '@core/components/Layout/Breakpoint/constants';
import { COLUMN_PREFIX_CLS, ROW_PREFIX_CLS, } from '@core/components/Layout/Grid/constants';
export const GLOBAL_ROW_STYLE = css `
  [class^='${ROW_PREFIX_CLS}'],
  [class*=' ${ROW_PREFIX_CLS}'] {
    box-sizing: border-box;
  }

  [class^='${ROW_PREFIX_CLS}']::before,
  [class*=' ${ROW_PREFIX_CLS}']::before,
  [class^='${ROW_PREFIX_CLS}']::after,
  [class*=' ${ROW_PREFIX_CLS}']::after {
    box-sizing: border-box;
  }

  [class^='${ROW_PREFIX_CLS}'] [class^='${ROW_PREFIX_CLS}'],
  [class*=' ${ROW_PREFIX_CLS}'] [class^='${ROW_PREFIX_CLS}'],
  [class^='${ROW_PREFIX_CLS}'] [class*=' ${ROW_PREFIX_CLS}'],
  [class*=' ${ROW_PREFIX_CLS}'] [class*=' ${ROW_PREFIX_CLS}'] {
    box-sizing: border-box;
  }

  [class^='${ROW_PREFIX_CLS}'] [class^='${ROW_PREFIX_CLS}']::before,
  [class*=' ${ROW_PREFIX_CLS}'] [class^='${ROW_PREFIX_CLS}']::before,
  [class^='${ROW_PREFIX_CLS}'] [class*=' ${ROW_PREFIX_CLS}']::before,
  [class*=' ${ROW_PREFIX_CLS}'] [class*=' ${ROW_PREFIX_CLS}']::before,
  [class^='${ROW_PREFIX_CLS}'] [class^='${ROW_PREFIX_CLS}']::after,
  [class*=' ${ROW_PREFIX_CLS}'] [class^='${ROW_PREFIX_CLS}']::after,
  [class^='${ROW_PREFIX_CLS}'] [class*=' ${ROW_PREFIX_CLS}']::after,
  [class*=' ${ROW_PREFIX_CLS}'] [class*=' ${ROW_PREFIX_CLS}']::after {
    box-sizing: border-box;
  }

  .${ROW_PREFIX_CLS} {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
  }

  .${ROW_PREFIX_CLS}::before, .${ROW_PREFIX_CLS}::after {
    display: flex;
  }

  .${ROW_PREFIX_CLS}-no-wrap {
    flex-wrap: nowrap;
  }

  .${ROW_PREFIX_CLS}-start {
    justify-content: flex-start;
  }

  .${ROW_PREFIX_CLS}-center {
    justify-content: center;
  }

  .${ROW_PREFIX_CLS}-end {
    justify-content: flex-end;
  }

  .${ROW_PREFIX_CLS}-space-between {
    justify-content: space-between;
  }

  .${ROW_PREFIX_CLS}-space-evenly {
    justify-content: space-evenly;
  }

  .${ROW_PREFIX_CLS}-space-around {
    justify-content: space-around;
  }

  .${ROW_PREFIX_CLS}-top {
    align-items: flex-start;
  }

  .${ROW_PREFIX_CLS}-middle {
    align-items: center;
  }

  .${ROW_PREFIX_CLS}-bottom {
    align-items: flex-end;
  }
`;
/**
 * Generates styles for grid columns that are defined based on breakpoint sizes and a specified number of grid columns.
 *
 * @returns An object containing the generated styles for grid columns. eg. of one of the crated styles .fw-col-md-12 {...styles}
 */
export const genLoopGridColumnsStyle = () => {
    const gridColumns = 24;
    const gridColumnsStyle = {};
    gridColumnsStyle[`.${COLUMN_PREFIX_CLS}`] = {
        position: 'relative',
        maxWidth: '100%',
        // Prevent columns from collapsing when empty
        minHeight: 1,
    };
    BREAKPOINT_SIZES.forEach((size) => {
        for (let i = gridColumns; i >= 0; i--) {
            if (i === 0) {
                gridColumnsStyle[`.${COLUMN_PREFIX_CLS}-${size}-${i}`] = {
                    display: 'none',
                };
            }
            else {
                gridColumnsStyle[`.${COLUMN_PREFIX_CLS}-${size}-${i}`] = {
                    display: 'block',
                    flex: `0 0 ${(i / gridColumns) * 100}%`,
                    maxWidth: `${(i / gridColumns) * 100}%`,
                };
            }
        }
    });
    return gridColumnsStyle;
};
export const GLOBAL_COL_STYLE = genLoopGridColumnsStyle();
