/**
 * Checks if `value` is a plain object, that is, an object created by the
 * `Object` constructor or one with a `[[Prototype]]` of `null`.
 */
export function isPlainObject(value) {
    if (!isObject(value)) {
        return false;
    }
    if (Object.getPrototypeOf(value) === null) {
        return true;
    }
    let proto = value;
    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto);
    }
    return Object.getPrototypeOf(value) === proto;
}
/** @returns Whether an input value is an object. */
function isObject(x) {
    return !!x && typeof x === 'object' && !Array.isArray(x) && x !== null;
}
/**
 * Recursively prune nil values from object. Nil values includes:
 * - null
 * - undefined
 * - empty string
 * - empty array
 *
 * @returns A new object with nil values removed.
 */
export const pruneNilValues = (obj) => {
    if (!isObject(obj)) {
        return obj;
    }
    return Object.keys(obj).reduce((acc, key) => {
        const val = obj[key];
        const isArray = Array.isArray(val);
        if (val !== null &&
            val !== undefined &&
            val !== '' &&
            (!isArray || (isArray && val.length > 0))) {
            if (isPlainObject(val)) {
                acc[key] = pruneNilValues(val);
            }
            else {
                acc[key] = val;
            }
        }
        return acc;
    }, {});
};
