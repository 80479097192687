import { lens } from '@dhmk/zustand-lens';
import { createShoppingActions } from './actions';
import { createShoppingState } from './state';
export const SHOPPING_SLICE_KEY = 'shopping';
/**
 *
 */
export function createShoppingSlice() {
    return {
        [SHOPPING_SLICE_KEY]: lens((set) => ({
            ...createShoppingState(),
            actions: createShoppingActions(set),
        })),
    };
}
