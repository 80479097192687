/**
 * @fileoverview
 *
 * WidgetAppContext is a HOC which makes sure the Widget component
 * get appContext resolved and stored in the store.
 */
import { lazy, Suspense, useEffect, useRef } from 'react';
import { ABTestID, allTests, getGuestBucket } from '@core/abTesting';
import { useFeedQueryContext } from '@core/feed';
import { persistFWReferrer } from '@core/tracking';
import { usePersistChannelId } from '@core/tracking/funnelTracking/hooks';
import { useStore } from '@core/widgets/store';
import { paramsSelector } from '@core/widgets/store/selectors';
import { useTrackEmbedInstance } from '@core/widgets/tracking/useTrackEmbedInstance';
import { useEmbedAppContext } from '@embed/hooks/useEmbedAppContext';
import { isShopifyEditor } from '@embed/tools/shopify';
const PlgIntersectionObserver = lazy(() => import('@embed/components/common/PlgIntersectionObserver'));
/**
 * HOC that builds the app context and keeps it updated in the widget store.
 */
export const WidgetAppContext = (props) => {
    const { children, appContextType } = props;
    const wasEmbedInstanceTracked = useRef(false);
    const actions = useStore((state) => state.actions);
    const params = useStore(paramsSelector);
    const [{ config }] = useFeedQueryContext();
    const appContext = useEmbedAppContext({
        config,
        appContextType,
    });
    const trackEmbedInstance = useTrackEmbedInstance();
    // Data team wants the last channel ID to be set at this level and not within player.
    // This is because with things like 'embed-feed' a user can watch multiple videos
    // and never click into the player, so the channel is persisted here.
    usePersistChannelId(appContext?.channel?.encoded_id);
    useEffect(() => {
        // Important to update fwreferrer from params.page_url in case widget is
        // being used in an iframe and access to window.location is limited.
        persistFWReferrer(params.page_url ?? '');
    }, [params.page_url]);
    const plgTest = allTests.get(ABTestID.PLG);
    const guestBucket = getGuestBucket(plgTest);
    useEffect(() => {
        if (appContext &&
            !wasEmbedInstanceTracked.current &&
            (!guestBucket || guestBucket.id === 'visible')) {
            trackEmbedInstance(appContext);
            wasEmbedInstanceTracked.current = true;
        }
    }, [appContext, guestBucket, trackEmbedInstance]);
    useEffect(() => {
        if (appContext) {
            actions.setContext(appContext);
        }
    }, [appContext, actions]);
    return guestBucket?.id === 'invisible' && !isShopifyEditor() ? (<Suspense fallback={null}>
      <PlgIntersectionObserver />
    </Suspense>) : (<>{children}</>);
};
