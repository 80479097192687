import { useCallback } from 'react';
import { pruneNilValues } from '@core/object';
import { appContextToTrackingDataPixel, sendPixelTrackingWithSession, } from '@core/tracking';
import { useStore } from '@core/widgets/store';
import { paramsSelector } from '@core/widgets/store/selectors';
import { FEED_CREATE_EMBED_INSTANCE } from '@core/widgets/tracking/endpoints';
/**
 * Sends Embed Instance pixel event.
 */
export const useTrackEmbedInstance = () => {
    const params = useStore(paramsSelector);
    return useCallback((appContext) => {
        const pixelTrackingData = appContextToTrackingDataPixel({ appContext });
        const pixelData = {
            event_properties: pruneNilValues({
                autoplay: params.autoplay,
                placement: params.placement,
                widget_size: params.size,
                ...pixelTrackingData,
            }),
        };
        sendPixelTrackingWithSession({
            endpoint: FEED_CREATE_EMBED_INSTANCE,
            data: pixelData,
            host: params.api_host_tracking,
        });
    }, [params]);
};
