/**
 * @fileoverview
 * VideoElementCache is a class that creates a cache
 * obj with simple key-value store. Keys can be any string,
 * typically its a widget_id uuid, `videoplayer` or
 * params.player_key attribute. Value is a HTMLVideoElement object.
 */
/**
 *
 */
export class VideoElementCache {
    constructor() {
        this.cache = {};
    }
    /**
     *
     */
    get(key) {
        return this.cache[key];
    }
    /**
     *
     */
    set(key, value) {
        return (this.cache[key] = value);
    }
    /**
     *
     */
    remove(key) {
        delete this.cache[key];
    }
}
