import merge from '@core/lodash/merge';
import { logInfo } from '@core/logging';
import { pruneNilValues } from '@core/object';
import { shouldBlockEndpointWithConsent } from '@core/tracking/consent';
import { persistLastEngagementTimestamp, persistVideoId, } from '@core/tracking/funnelTracking';
import { getGlobalEventProperties } from '@core/tracking/senders/helpers/globalEventProperties';
import { sendBeaconPixel } from './helpers/beacon';
import { getGlobalTrackingFields } from './helpers/globalTrackingFields';
/**
 * Sends a pixel tracking event to the backend. It will attach the client
 * metadata to the payload.
 */
export const sendPixelTracking = async ({ endpoint, data = {}, host, }) => {
    if (endpoint.isUserEngagement) {
        persistLastEngagementTimestamp();
        if (data?.event_properties?._video_id) {
            persistVideoId(data?.event_properties?._video_id);
        }
    }
    if (shouldBlockEndpointWithConsent(endpoint.consent)) {
        return;
    }
    const pixelTrackingFields = {
        ...getGlobalTrackingFields(),
        event_properties: getGlobalEventProperties(),
    };
    const mergedData = pruneNilValues(merge({}, pixelTrackingFields, data));
    logInfo('Pixel', endpoint.url, mergedData);
    return sendBeaconPixel({ path: endpoint.url, data: mergedData, host });
};
