var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _CustomElementClass_isVisibilityLogged, _CustomElementClass_abortController, _CustomElementClass_promise, _CustomElementClass_promiseParams;
import { createApiService } from '@core/api';
import { customEvent, EventType } from '@core/customEvent';
import { WidgetTypesEnum } from '@core/types';
import { AppContextType } from '@embed/components/helpers';
import { BaseElement } from '../bases/BaseElement';
import { MutationMixin } from '../bases/MutationMixin';
import { ParamsMixin } from '../bases/ParamsMixin';
import { VisibilityMixin } from '../bases/VisibilityMixin';
const BUTTON = 'fw-button';
const LABEL = 'fw-label';
/**
 * A <fw-button-wrapper> custom element class
 */
export class CustomElementClass extends VisibilityMixin(MutationMixin(ParamsMixin(BaseElement))) {
    constructor() {
        super(...arguments);
        _CustomElementClass_isVisibilityLogged.set(this, false);
        _CustomElementClass_abortController.set(this, void 0);
        _CustomElementClass_promise.set(this, void 0);
        _CustomElementClass_promiseParams.set(this, '');
        this.button_id = '';
        /**
         * Actual onClick handler based on fetch response
         */
        this.setOnClick = () => {
            __classPrivateFieldGet(this, _CustomElementClass_promise, "f").then((response) => {
                customEvent({
                    type: EventType.FW_BUTTONWRAPPER_CLICK,
                    data: {
                        widget_id: this.widgetId,
                        appContextType: AppContextType.EMBED_BUTTON_WRAPPER,
                        ...this.getAttributes(),
                    },
                });
                if (response.action.type.startsWith('play')) {
                    window._fwn?.play?.({
                        type: WidgetTypesEnum.BUTTON_WRAPPER,
                        widget_id: this.widgetId,
                        appContextType: AppContextType.EMBED_BUTTON_WRAPPER,
                        tap_to_watch: false,
                        ...response.action?.config,
                    }, { muted: false });
                }
                else if (response.action.type === 'open_oto') {
                    window._fwn?.liveHelper.actions.showWidget({
                        chat_channel: response.action.config.chat_channel,
                    });
                }
            });
        };
    }
    /**
     * Disable shadow root creation
     * @returns
     */
    createRenderRoot() {
        return this;
    }
    /**
     * Render the element
     */
    render() {
        this.fetch();
        this.setAppearance();
        this.detachOnClick();
        this.attachOnClick();
    }
    /**
     * Override connectedCallback to trigger custom event
     */
    connectedCallback() {
        super.connectedCallback();
        customEvent({
            type: EventType.FW_BUTTONWRAPPER_RENDER,
            data: {
                widget_id: this.widgetId,
                appContextType: AppContextType.EMBED_BUTTON_WRAPPER,
                ...this.getAttributes(),
            },
        });
    }
    /**
     * Fetch button configuration
     * @returns
     */
    fetch() {
        const currentParams = JSON.stringify(this.getAttributes());
        if (!__classPrivateFieldGet(this, _CustomElementClass_promise, "f") || __classPrivateFieldGet(this, _CustomElementClass_promiseParams, "f") !== currentParams) {
            if (__classPrivateFieldGet(this, _CustomElementClass_abortController, "f") && !__classPrivateFieldGet(this, _CustomElementClass_abortController, "f").signal.aborted) {
                __classPrivateFieldGet(this, _CustomElementClass_abortController, "f").abort();
            }
            const { getSmartButton } = createApiService(this.params.api_host ?? '');
            __classPrivateFieldSet(this, _CustomElementClass_abortController, new AbortController(), "f");
            __classPrivateFieldSet(this, _CustomElementClass_promiseParams, JSON.stringify(this.getAttributes()), "f");
            __classPrivateFieldSet(this, _CustomElementClass_promise, getSmartButton(this.getAttributes(), __classPrivateFieldGet(this, _CustomElementClass_abortController, "f").signal), "f");
        }
        return __classPrivateFieldGet(this, _CustomElementClass_promise, "f");
    }
    /**
     * Find wrapped button element
     */
    get buttonElement() {
        return (this.querySelector(`.${BUTTON}`) ||
            this.querySelector('button') ||
            this.querySelector('a'));
    }
    /**
     * Find wrapped label element, fallback to button element
     */
    get labelElement() {
        return this.querySelector(`.${LABEL}`) || this.buttonElement;
    }
    /**
     * Visibility change handler triggering the custom event.
     * @param isVisible
     */
    onVisibilityChange(isVisible) {
        if (!__classPrivateFieldGet(this, _CustomElementClass_isVisibilityLogged, "f") && isVisible) {
            customEvent({
                type: EventType.FW_BUTTONWRAPPER_IMPRESSION,
                data: {
                    widget_id: this.widgetId,
                    appContextType: AppContextType.EMBED_BUTTON_WRAPPER,
                    ...this.getAttributes(),
                },
            });
            __classPrivateFieldSet(this, _CustomElementClass_isVisibilityLogged, true, "f");
        }
    }
    /**
     * Set appearance based fetch response
     */
    setAppearance() {
        __classPrivateFieldGet(this, _CustomElementClass_promise, "f").then((response) => {
            const hideAppearance = response.appearance.find((a) => a.type === 'hide');
            if (hideAppearance) {
                this.style.display = 'none';
            }
            const classnameAppearance = response.appearance.find((a) => a.type === 'set_classname');
            const classname = classnameAppearance?.config?.value || '';
            if (this.buttonElement && classname) {
                this.buttonElement.classList.add(classname);
            }
            const labelAppearance = response.appearance.find((a) => a.type === 'set_label');
            const label = labelAppearance?.config?.value || '';
            if (this.labelElement && label) {
                this.labelElement.innerHTML = label;
            }
        });
    }
    /**
     * Setup onClick handler
     */
    attachOnClick() {
        this.buttonElement?.addEventListener('click', this.setOnClick);
    }
    /**
     * Destroy onClick handler
     */
    detachOnClick() {
        this.buttonElement?.removeEventListener('click', this.setOnClick);
    }
}
_CustomElementClass_isVisibilityLogged = new WeakMap(), _CustomElementClass_abortController = new WeakMap(), _CustomElementClass_promise = new WeakMap(), _CustomElementClass_promiseParams = new WeakMap();
