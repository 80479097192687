/**
 * Function to merge theme variables. Source variables are overridden by overrides where
 * applicable and remaining variables are appended to the end of the array.
 *
 * - First occurrence in source secures position
 * - Last occurrence in overrides sets value
 *
 * @param source
 * @param overrides
 */
export function mergeVariables(source, ...overrides) {
    return Array.from(new Map([...source, ...overrides.flat()]));
}
