import { FeedType } from '@core/feed';
import { AppContextType } from '@embed/components/helpers';
/**
 * Construct a playlist-specific config (used for resolving the correct config for playlist_collection feeds)
 */
export const createPlaylistConfig = ({ appContextType, params, channel, }) => {
    if ((params.channel || params.channel_id) && params.playlist) {
        if (params.channel_id && !channel) {
            return null;
        }
        return {
            type: FeedType.PLAYLIST,
            username: params.channel ?? channel?.username,
            playlistId: params.playlist,
            firstVideoId: params.video || params.video_id,
            maxVideos: appContextType === AppContextType.EMBED_GRID_CONTEXT_TYPE
                ? undefined
                : params.max_videos,
        };
    }
    return null;
};
