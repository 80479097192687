var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Cache_consent, _Cache_keys;
import { getGlobalConfig } from '@core/globalConfig';
import { isTruthy } from '@core/helpers/boolean';
/**
 * Base for all caches.
 */
export class Cache {
    /**
     * Constructor method
     * @param storage
     * @param keys
     */
    constructor(keys) {
        _Cache_consent.set(this, undefined);
        _Cache_keys.set(this, void 0);
        __classPrivateFieldSet(this, _Cache_keys, keys ? new Set(keys) : undefined, "f");
    }
    /**
     * Test for consent of the storage instance*
     * @returns
     */
    isConsentGiven() {
        // Opt-in behavior
        if (isTruthy(getGlobalConfig().cookies_opt_in)) {
            return Boolean(__classPrivateFieldGet(this, _Cache_consent, "f"));
        }
        // Default behavior
        switch (__classPrivateFieldGet(this, _Cache_consent, "f")) {
            case false:
                return false;
            case true:
            case undefined:
            default:
                return true;
        }
    }
    /**
     * Grant consent for the storage instance
     */
    grantConsent() {
        __classPrivateFieldSet(this, _Cache_consent, true, "f");
    }
    /**
     * Revoke consent for the storage instance
     */
    revokeConsent() {
        __classPrivateFieldSet(this, _Cache_consent, false, "f");
    }
    /**
     * Validate if key belongs to the storage
     * @param key
     * @returns
     */
    validateKey(key) {
        return __classPrivateFieldGet(this, _Cache_keys, "f") ? __classPrivateFieldGet(this, _Cache_keys, "f").has(key) : true;
    }
    /**
     * Populate the cache with the current browser cookies/storage.
     */
    populate(_opts) {
        throw new Error('Method not implemented.');
    }
    /**
     * Flush the cached items to the browser cookies/storage.
     */
    flush() {
        throw new Error('Method not implemented.');
    }
    /**
     * Clear the storage for test purposes. Skip consent checks.
     */
    clearForTest() {
        throw new Error('Method not implemented.');
    }
}
_Cache_consent = new WeakMap(), _Cache_keys = new WeakMap();
