import { isDebug } from '@core/debug';
import { features } from './features';
const REMOTE_POLYFILL_TIMEOUT = 1000;
/**
 * Returns a promise that will reject after a timeout.
 */
export function requestTimeout() {
    return new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Request Timeout')), REMOTE_POLYFILL_TIMEOUT);
    });
}
/**
 * Returns a promise that will resolve when the backup polyfill is loaded.
 * Backup polyfill is large static file generated from polyfill service by the IE11 browser.
 */
export function requestBackupPolyfill(docDI = document) {
    return new Promise((resolve, reject) => {
        const callbackName = '_fwnRender';
        window[callbackName] = () => {
            resolve('Polyfill served from backup source');
        };
        const script = docDI.createElement('script');
        script.async = true;
        script.src = `https://asset.fireworktv.com/js/static-polyfills.js`;
        script.type = 'text/javascript';
        script.onerror = (err) => {
            reject(err);
        };
        docDI.head.appendChild(script);
    });
}
/**
 * Returns a promise that will resolve when the remote
 * request to polyfill.io is loaded.
 */
export function requestRemotePolyfill(featuresDI = features, docDI = document) {
    return new Promise((resolve, reject) => {
        const callbackName = '_fwnRender_io';
        window[callbackName] = () => {
            resolve('Polyfill served from remote source');
        };
        const script = docDI.createElement('script');
        script.src = `https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=${featuresDI
            .map(([name]) => name)
            .join('%2C')}&flags=gated&callback=${callbackName}`;
        script.async = true;
        script.type = 'text/javascript';
        script.crossOrigin = 'anonymous';
        script.referrerPolicy = 'no-referrer';
        script.onerror = (err) => {
            reject(err);
        };
        docDI.head.appendChild(script);
    });
}
/**
 * Create a promise that will resolve if all features are
 * supported given the local detects (tests).
 */
export function checkFeaturesSupport(featuresDI = features) {
    return new Promise((resolve, reject) => {
        // All tests have passed and there is no need to load the polyfill
        // from any remote source.
        let allSupported = true;
        const featuresEvaluated = featuresDI.map(([name, source]) => {
            try {
                const isSupported = source === null || (source && source.default && source.default());
                allSupported = allSupported && isSupported;
                return [name, isSupported];
            }
            catch (err) {
                allSupported = false;
                return [name, false];
            }
        });
        if (!allSupported) {
            // Note: Disable logging for now.
            // logMessage(
            //   'Features evaluation failed',
            //   {
            //     captureContext: {
            //       extra: {
            //         features: featuresEvaluated
            //           .filter(([, isSupported]) => !isSupported)
            //           .map(([name]) => name),
            //       },
            //     },
            //   },
            //   1,
            // )
            if (isDebug()) {
                // eslint-disable-next-line no-console
                console.table(featuresEvaluated);
            }
        }
        if (allSupported) {
            resolve('No polyfill needed');
        }
        else {
            reject(new Error('Polyfills needed'));
        }
    });
}
