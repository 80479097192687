import { shoppingApi } from './api';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'shopping')) {
        Object.defineProperty(window._fwn, 'shopping', {
            value: shoppingApi,
            writable: false,
        });
    }
}
