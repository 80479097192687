import { getNextJsPublicEnv } from '@src/helpers';
const sampleValue = Math.random();
const sampleRate = process.env.STAGE_ENV === 'production' ? 0.1 : 1;
/**
 * Whether current page load is eligible for message log to Sentry.
 * @returns
 */
export const eligibleForMessageLog = (rate = sampleRate, valueDI = sampleValue) => valueDI < rate;
/**
 * Sentry BrowserClient configuration.
 */
export const sentryConfig = {
    dsn: 'https://1ef60321bd194094ade30c73d9d39252@sentry.io/2054398',
    environment: process.env.STAGE_ENV,
    sampleRate: sampleRate,
    release: getNextJsPublicEnv('RELEASE_VERSION') ?? process.env.RELEASE_VERSION,
    ignoreErrors: process.env.STAGE_ENV === 'production'
        ? [
            // TODO(CS-273): Re-enable this error after we implement a fallback
            // logic when detecting cookie cannot be used.
            `Failed to read the 'cookie' property from 'Document'`,
            // [AD-302]: Ignore error coming from weebly.com editor used by our internal team.
            "null is not an object (evaluating 't.i.appendChild')",
            // [ZE-354]: Silencing errors about missing chunks. These are available just fine.
            new RegExp(/Loading chunk (\d+) failed\./),
            // [CS-4646]: Suppressing unidentified RangeError from few sites using RebuyEngine for Shopify
            // See the beforeSend function below as well. We are suppressing this error for all sites
            // since its not clear what is causing it.
            `Invalid array length`,
            // [CS-4893]: Suppressing unidentified TypeError from stylis
            `Cannot read properties of undefined (reading 'apply')`,
            // [CS-5222]: Suppressing DECODER_ERROR_NOT_SUPPORTED: video decoder initialization failed
            `DECODER_ERROR_NOT_SUPPORTED: video decoder initialization failed`,
        ]
        : [],
    denyUrls: [],
    beforeSend: (event) => {
        const url = event?.request?.url;
        const firstException = event?.exception?.values?.[0];
        // [CS-4646]: Suppressing unidentified RangeError from few sites using RebuyEngine for Shopify
        // https://firework.sentry.io/issues/4527502765
        if ((url?.includes('beachwaver.com') ||
            url?.includes('staycourant.com') ||
            url?.includes('transparentlabs.com')) &&
            firstException?.type?.includes('RangeError') &&
            firstException?.value?.includes('Invalid array length')) {
            return null;
        }
        return event;
    },
};
