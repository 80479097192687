import { getSessionStorage } from '@core/storage';
import { useStorage } from './useStorage';
/**
 * Simple hook to read and write to session storage. Does not synchronize or listen
 * to changes in other tabs.
 */
export function useSessionStorage(key, initial) {
    const storage = getSessionStorage();
    return useStorage(key, initial, {
        storage,
    });
}
