import { mergeVariables } from '../tools/mergeVariables';
import { theme as defaultTheme } from './default';
export const theme = {
    key: 'light',
    variables: mergeVariables(defaultTheme.variables, [
        ['--font-color', 'var(--blk3)'],
        ['--chat-pin-background', 'rgba(33,137,255, 1)'],
        ['--chat-reply-background', 'rgb(var(--blk3-rgb) / 15%)'],
        ['--chat-reply-font-color', 'var(--font-color)'],
        ['--chat-reply-initial-msg-background', 'rgb(var(--blk3-rgb) / 15%)'],
        ['--chat-reply-initial-msg-font-color', 'var(--font-color)'],
        ['--chat-reply-initial-msg-text-shadow', 'none'],
        ['--chat-reply-text-shadow', 'none'],
        //
        ['--player-background', 'rgba(0,0,0,0.9)'],
        ['--player-chat-background', 'var(--white)'],
        ['--player-chat-font-color', 'var(--font-color)'],
        ['--player-chat-text-shadow', 'none'],
        ['--player-header-font-color', 'var(--font-color)'],
        ['--player-pinned-font-color', 'var(--white)'],
        ['--player-shop-background', 'var(--white)'],
        ['--player-shop-font-color', 'var(--font-color)'],
        //
        ['--product-description-font-color', 'var(--font-color)'],
        ['--product-name-font-color', 'var(--font-color)'],
        ['--product-price-font-color', 'var(--font-color)'],
        ['--product-progress-bar-background', 'var(--gray6)'],
    ]),
};
