import { shoppingActionsSelector, shoppingCartSelector, shoppingCartUrlSelector, } from '@core/shopping/model';
import { productFactory as origProductFactory } from '@core/shopping/utils/factories/product';
import { getGlobalStore } from '@core/store';
/**
 * @returns A list of cart items.
 */
function getCart() {
    return shoppingCartSelector(getGlobalStore().getState());
}
/**
 * @returns The URL to navigate to when the user clicks on the checkout button.
 */
function getCheckoutUrl() {
    return shoppingCartUrlSelector(getGlobalStore().getState());
}
/**
 * Configures cart properties.
 */
function configureCart(payload) {
    if (payload.addToCartText) {
        // eslint-disable-next-line no-console
        console.warn('addToCartText is deprecated, please use shopping_cart_style widget param instead. Example: <fw-storyblock shopping_cart_style="bag" />');
    }
    if (payload.itemInCartText) {
        // eslint-disable-next-line no-console
        console.warn('itemInCartText is deprecated, please use shopping_cart_style widget param instead. Example: <fw-storyblock shopping_cart_style="bag" />');
    }
    const { configureCart } = shoppingActionsSelector(getGlobalStore().getState());
    configureCart(payload);
    // Create _fwnStoreFront object for backward compatibility.
    // The legacy implementation requires customers to manually add a
    // _fwnStoreFront object to enable cart sync capability. We now recommend
    // customer to call `configureCart` instead. Until all customers are moving
    // to this new pattern, we will have to create this object.
    if (!window._fwnStoreFront) {
        window._fwnStoreFront = {};
    }
}
/**
 * Hydrates products. When the hydrated products external Id matches an existing
 * FW product, the properties will merge. A new FW product will be created
 * otherwise.
 */
function hydrateProducts(products) {
    const { hydrateProducts } = shoppingActionsSelector(getGlobalStore().getState());
    hydrateProducts(products);
}
/**
 * Hydrates the cart. It replaces all the items in the FW cart with the provided
 * cart items.
 */
function hydrateCart(cart) {
    const { updateUnitCartQuantity, resetCart } = shoppingActionsSelector(getGlobalStore().getState());
    // Reset cart in case items in the FW cart has been removed from the external
    // cart.
    resetCart();
    for (const cartItem of cart) {
        updateUnitCartQuantity({
            productUnit: cartItem.unit,
            targetQuantity: cartItem.quantity,
        });
    }
}
/**
 * Sets cart updated callback. The callback will be called when the cart items
 * are updated.
 */
function onCartUpdated(callback) {
    const { setCartUpdatedCallback } = shoppingActionsSelector(getGlobalStore().getState());
    setCartUpdatedCallback(callback);
}
/**
 * Sets cart displayed callback. The callback will be called when the cart is
 * rendered.
 */
function onCartDisplayed(callback) {
    const { setCartDisplayedCallback } = shoppingActionsSelector(getGlobalStore().getState());
    setCartDisplayedCallback(callback);
}
/**
 * Sets product loaded callback. The callback will be called when a product is
 * loaded.
 */
function onProductsLoaded(callback) {
    const { setProductsLoadedCallback } = shoppingActionsSelector(getGlobalStore().getState());
    setProductsLoadedCallback(callback);
}
const productFactory = (callback, verifyRequiredFields) => {
    return origProductFactory(callback, verifyRequiredFields);
};
export const shoppingApi = {
    configureCart,
    getCart,
    getCheckoutUrl,
    hydrateCart,
    hydrateProducts,
    onCartUpdated,
    onCartDisplayed,
    onProductsLoaded,
    productFactory,
};
