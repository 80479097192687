import { StorageKeyFunctionalEnum, StorageKeyPerformanceEnum, } from '@core/storage/types';
export var EnumFunnelTrackingCookies;
(function (EnumFunnelTrackingCookies) {
    EnumFunnelTrackingCookies["FW_CHAT_CHANNEL_ID"] = "fw_cchid";
    EnumFunnelTrackingCookies["FW_CHANNEL_ID"] = "fw_chid";
    EnumFunnelTrackingCookies["FW_GUEST_ID"] = "fw_uid";
    EnumFunnelTrackingCookies["FW_UTM"] = "fw_utm";
    EnumFunnelTrackingCookies["FW_LAST_VIDEO_ID"] = "fw_vid";
    EnumFunnelTrackingCookies["FW_LAST_ENGAGEMENT_TIMESTAMP"] = "fw_lets";
    EnumFunnelTrackingCookies["FW_BUSINESS_MEMBERSHIP_ID"] = "fw_bmid";
    EnumFunnelTrackingCookies["FW_SHOWROOM_ID"] = "fw_dsid";
    EnumFunnelTrackingCookies["FW_ASSISTANCE_ID_WITH_ANSWER"] = "fw_asidwa";
    EnumFunnelTrackingCookies["FW_LAST_AVA_ENGAGEMENT_WITH_ANSWER_TIMESTAMP"] = "fw_laewats";
})(EnumFunnelTrackingCookies || (EnumFunnelTrackingCookies = {}));
export var EnumUtmKeys;
(function (EnumUtmKeys) {
    EnumUtmKeys["SOURCE"] = "utm_source";
    EnumUtmKeys["SUB_SOURCE"] = "utm_subsource";
    EnumUtmKeys["MEDIUM"] = "utm_medium";
    EnumUtmKeys["CAMPAIGN"] = "utm_campaign";
    EnumUtmKeys["CONTENT"] = "utm_content";
})(EnumUtmKeys || (EnumUtmKeys = {}));
