export var IPresentation;
(function (IPresentation) {
    IPresentation["ADD_TO_CALENDAR"] = "add_to_calendar";
    IPresentation["BUTTON_WRAPPER"] = "button_wrapper";
    IPresentation["CAROUSEL"] = "carousel";
    IPresentation["DYNAMIC_CAROUSEL"] = "dynamic_carousel";
    IPresentation["GRID"] = "grid";
    IPresentation["HERO"] = "hero";
    IPresentation["PLAYER"] = "player";
    IPresentation["SHARE"] = "share";
    IPresentation["STORYBLOCK"] = "storyblock";
    IPresentation["STORYLINK"] = "storylink";
    IPresentation["SHOWROOM_SCHEDULE"] = "showroom_schedule";
})(IPresentation || (IPresentation = {}));
