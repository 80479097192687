import { logMessage } from '@core/logging';
import { StorageKeyFunctionalEnum } from '@core/storage/types';
import { getCookieWithFallback, removeCookieAndFallback, setCookieWithFallback, } from '@core/tracking/funnelTracking';
const sessionKey = StorageKeyFunctionalEnum.fw_se;
/**
 * Reads the session string from cookies, parses it and create Session object.
 * Session object returned can be invalid and need to be validated later.
 */
export const getStoredSession = () => {
    let storedSession;
    try {
        // Skip reading for now
        storedSession = getCookieWithFallback(sessionKey)?.value;
        if (storedSession) {
            const sessionArray = storedSession.split('.');
            return {
                version: sessionArray[0],
                id: sessionArray[1],
                session_count: parseInt(sessionArray[2]),
                type: 'embed_session',
                started_at: parseInt(sessionArray[3]),
            };
        }
    }
    catch (error) {
        // this may be out of our control but would like to see if we have some high error rate after deployment
        logMessage(error.message, {
            captureContext: {
                extra: {
                    storedSession,
                },
            },
        });
    }
    return null;
};
/**
 * Creates session string from Session object and stores it inside cookies
 * Example: fws2.fe73bd67-cf64-44e9-a430-863dee1df5d0.3.1694441101864
 */
export const setSessionIntoStore = (session) => {
    try {
        const sessionString = `${session.version}.${session.id}.${session.session_count}.${session.started_at}`;
        setCookieWithFallback(sessionKey, sessionString);
    }
    catch (error) {
        // Pass silently
    }
};
/**
 * Remove session from cookies, used only in tests
 */
export const clearSessionStore = () => {
    try {
        removeCookieAndFallback(sessionKey);
    }
    catch (error) {
        // Pass silently
    }
};
