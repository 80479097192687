/**
 * @fileoverview
 * Creates HTMLVideoElement and caches it for later use.
 */
import { lazyCacheGetter } from './lazyCacheGetter';
const createVideoElement = (options) => {
    const videoElement = (typeof window !== 'undefined' ? document.createElement('video') : {});
    const opts = {
        autoplay: true,
        playsInline: true,
        ...options,
    };
    videoElement.autoplay = !!opts.autoplay;
    videoElement.muted = true;
    videoElement.playsInline = !!opts.playsInline;
    if (typeof opts.tabIndex === 'number') {
        videoElement.tabIndex = opts.tabIndex;
    }
    //   //if (subtitles) setSubtitles(videoElement, subtitles)
    return videoElement;
};
/**
 *
 */
export const discardCachedVideoElement = (key) => {
    lazyCacheGetter().remove(key);
};
/**
 *
 */
export const ensureCachedVideoElement = (options) => {
    return (lazyCacheGetter().get(options.key) ||
        lazyCacheGetter().set(options.key, createVideoElement(options)));
};
