import { pruneNilValues } from '@core/object';
/**
 * Helper to create FWPlayerParam object out of appContext, video and startTime.
 * @param appContext
 * @param video
 * @param startTime
 * @returns FWPlayerParam object
 */
export const createShareLinkParamsObject = (appContext, video, startTime) => {
    return {
        ...pruneNilValues({
            channel: appContext?.params?.channel,
            channel_id: appContext?.params?.channel_id,
            hashtag: appContext?.params?.hashtag,
            playlist_id: appContext?.params?.playlist,
            start_time: startTime,
        }),
        video_id: video?.encoded_id ?? '',
    };
};
