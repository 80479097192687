import { logError } from '@core/logging';
import { EnumFunnelTrackingCookies, getCookieWithFallback, } from '@core/tracking/funnelTracking';
/**
 * Read UTM from cookies/localStorage and returns object which is appended
 * to every tracking pixel
 */
export const getUTM = () => {
    try {
        const utms = getCookieWithFallback(EnumFunnelTrackingCookies.FW_UTM)?.value;
        return utms ? JSON.parse(utms) : undefined;
    }
    catch (e) {
        logError(e);
    }
};
