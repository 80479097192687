import React, { Fragment, lazy, Suspense, useCallback, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
import { useIsPlgControlGroup } from '@core/hooks/useIsPlgControlGroup/useIsPlgControlGroup';
import { useResizeObserver } from '@core/hooks/useResizeObserver';
import { useEmbedFeedStore } from '@core/widgets/embedFeed/store';
import { isIframe } from '@core/window';
import { PlayerTitle } from '@embed/components/common/PlayerTitle';
import { AppContextType } from '@embed/components/helpers';
import { importVideoPlayer, VideoPlayer } from '@embed/components/VideoPlayer';
import { Container, Wrapper } from './Grid.styles';
import { columnsByVideoCount, columnsForWidth } from './GridConstants';
import { GridPlaceholders } from './GridPlaceholders';
import { PlaylistVideoPlayer } from './Playlist';
const GridBranding = React.lazy(() => import('@embed/components/Grid/GridBranding'));
let gridComponentPromise;
const GridComponent = lazy(() => gridComponentPromise || import('./GridComponent'));
/**
 * Grid component for embed feed.
 */
export const Grid = (props) => {
    const { appContext, params, videos, fetchMoreVideos, largeCarousel, loading, } = props;
    const [showV2Card, setShowV2Card] = useState(false);
    useEffect(() => {
        // This is a workaround to make sure GridComponent is loaded asap while also lazy loaded.
        // Component is downloaded while API request resolves. Helps to prevent bundle size
        // increase and long-task blocking.
        gridComponentPromise = import('./GridComponent');
    }, []);
    const { hideVideoPlayer } = useEmbedFeedStore((state) => state.actions);
    const placeholders = useEmbedFeedStore((state) => state.placeholders);
    const player = useEmbedFeedStore((state) => state.player);
    const appContextEmbed = useMemo(() => appContext
        ? {
            ...appContext,
            appContextType: AppContextType.EMBED_CONTEXT_TYPE,
            isInIframe: isIframe(),
            pageUrl: params.page_url,
            variant: player.video?.variant,
        }
        : appContext, [appContext, player.video, params]);
    const handleColumns = useCallback((width) => {
        if (params.thumbnail_style === 'dynamic') {
            const { columns, showV2Card } = columnsByVideoCount({
                containerWidth: width,
                size: params.size,
                count: videos.length,
            });
            setShowV2Card(showV2Card);
            setColumns(columns);
        }
        else {
            setColumns(columnsForWidth(width));
        }
    }, [params.size, params.thumbnail_style, videos.length]);
    const gridRef = useResizeObserver(useCallback(([entry]) => {
        const width = entry.target.clientWidth;
        handleColumns(width);
    }, [handleColumns]));
    const [columns, setColumns] = useState(0);
    useLayoutEffect(() => {
        if (gridRef.current) {
            const width = gridRef.current.clientWidth;
            handleColumns(width);
        }
    }, [gridRef, handleColumns]);
    const onNavigateToVideo = useCallback(({ countOfNextVideos }) => {
        fetchMoreVideos(countOfNextVideos);
    }, [fetchMoreVideos]);
    const onVideoPlayerClose = useCallback(() => {
        hideVideoPlayer();
    }, [hideVideoPlayer]);
    const isPlgControlGroup = useIsPlgControlGroup();
    return (<Container className="fwn-grid" dir={params.dir} ref={gridRef} onMouseOver={importVideoPlayer} onKeyUp={(e) => {
            /*
             * Prevent keyup events from propagating past our widgets to prevent interference
             * from other javascript on the embedding page causing unexpected behavior.
             */
            e.stopPropagation();
        }}>
      {params.title && (<PlayerTitle className="cy-title" role="presentation" hasVideos={videos.length > 0} position={params.title_alignment} size={params.title_size}>
          {params.title}
        </PlayerTitle>)}

      <Wrapper className={`cy-grid cy-${params.mode}`} aria-hidden={player.isOpen} gridMode={params.mode} aria-label={params.title} role="region" aria-roledescription="carousel">
        {appContext && !loading ? (<Suspense fallback={<Fragment />}>
            <GridComponent {...props} appContext={appContext} columns={columns} showV2Card={showV2Card}/>
            <GridBranding params={params} video={player.video}/>
          </Suspense>) : isPlgControlGroup ? null : (<>
            <GridPlaceholders columns={columns} params={params} placeholders={placeholders} largeCarousel={largeCarousel}/>
            <Suspense fallback={null}>
              <GridBranding params={params}/>
            </Suspense>
          </>)}
      </Wrapper>

      {appContextEmbed && player.isOpen && player.video && (<VideoPlayer appContext={appContextEmbed} onClose={onVideoPlayerClose} params={params} onNavigateToVideo={onNavigateToVideo} videos={videos} video={player.video} muted={params.muted} pip={true}/>)}

      {appContextEmbed && player.isOpen && player.playlist && (<PlaylistVideoPlayer params={params} playlist={player.playlist}/>)}
    </Container>);
};
