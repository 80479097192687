/**
 * @fileoverview
 * Helpers to enhance tracking payload with appContext data.
 */
import { AppContextType, Context } from '@embed/components/helpers';
/**
 * TODO(GROW-1089): Remove this function once we stop hitting pixel endpoint.
 */
export const appContextToTrackingData = (params) => {
    const { appContext } = params;
    const { appContextType } = appContext ?? {};
    switch (appContextType) {
        case AppContextType.CHANNEL_CONTEXT_TYPE: {
            const { channelId, pageUrl } = appContext ?? {};
            return {
                app_context_type: appContextType,
                channel_id: channelId,
                context: Context.CONTEXT_CHANNEL,
                page_url: pageUrl,
            };
        }
        case AppContextType.VIDEO_DETAIL_CONTEXT_TYPE: {
            return {
                app_context_type: appContextType,
                context: Context.CONTEXT_VIDEO_DETAIL,
            };
        }
        // All widget contexts
        default: {
            if (appContext && 'trackingData' in appContext) {
                return appContext.trackingData;
            }
            return;
        }
    }
};
/**
 *
 */
export const appContextToTrackingDataPixel = ({ appContext, }) => {
    switch (appContext?.appContextType) {
        case AppContextType.CHANNEL_CONTEXT_TYPE: {
            const { channelId, pageUrl } = appContext;
            return {
                app_context_type: appContext.appContextType,
                _channel_id: channelId,
                context: Context.CONTEXT_CHANNEL,
                page_url: pageUrl,
            };
        }
        case AppContextType.VIDEO_DETAIL_CONTEXT_TYPE: {
            return {
                app_context_type: appContext.appContextType,
                context: Context.CONTEXT_VIDEO_DETAIL,
            };
        }
        // All widget contexts
        default: {
            return appContext?.['trackingDataPixel'];
        }
    }
};
