/**
 * @fileoverview
 * Hook providing lazy access to widget's i18next instance.
 * Using `translation` namespace only.
 */
import { createHook } from '@core/react-i18next-lazy';
import { useStore } from '@core/widgets/store';
export const useTranslation = createHook(async () => {
    const module = await import(/* webpackChunkName: 'i18next' */ './i18next');
    return module.default;
}, () => {
    return useStore((state) => state.params.lang) || 'en';
});
