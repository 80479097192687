import { productFactory } from '@core/shopping/utils/factories/product';
import { videoFileFactory } from './videoFile';
/**
 * Video builder used for hydration purposes. Creates partial Api_Video object
 * which is then merged with a video from API.
 */
export class VideoBuilder {
    /**
     * Constructor
     */
    constructor() {
        this.video = {};
    }
    /**
     * @deprecated Use `id`` instead.
     */
    set_id(value) {
        return this.id(value);
    }
    /**
     * Set video id.
     * @param value
     * @returns
     */
    id(value) {
        this.video.encoded_id = value;
        return this;
    }
    /**
     * @deprecated Use `actionType` instead.
     */
    cta_type(value) {
        return this.actionType(value);
    }
    /**
     * Set CTA action type. Try `actionTypeTranslation` for a localized version.
     * @param value
     * @returns
     */
    actionType(value) {
        this.video.action_type = value;
        return this;
    }
    /**
     * @deprecated Use `actionUrl instead.
     */
    cta_url(value) {
        return this.actionUrl(value);
    }
    /**
     *
     * @param value
     * @returns
     */
    actionUrl(value) {
        this.video.action_url = value;
        return this;
    }
    /**
     * @deprecated Use `caption` instead.
     */
    set_caption(value) {
        return this.caption(value);
    }
    /**
     * Set video caption. Set to empty value to hide the caption.
     * @param value
     * @returns
     */
    caption(value) {
        this.video.caption = value;
        return this;
    }
    /**
     * @deprecated Use `downloadUrl` instead.
     */
    set_download_url(value) {
        return this.downloadUrl(value);
    }
    /**
     * Set video download url. When emptied, video wont play.
     * @param value
     * @returns
     */
    downloadUrl(value) {
        this.video.download_url = value;
        return this;
    }
    /**
     * @deprecated Use `videoFile` instead.
     */
    add_video_file(callback) {
        return this.videoFile(callback);
    }
    /**
     * Add video file to a video.
     * @param callback
     * @returns
     */
    videoFile(callback) {
        this.video.video_files = this.video.video_files || [];
        this.video.video_files.push(videoFileFactory(callback));
        return this;
    }
    /**
     * Clear video files from a video. Use `videoFile` to populate them again.
     * @returns
     */
    clearVideoFiles() {
        this.video.video_files = [];
        return this;
    }
    /**
     * Add product to a video.
     * @param callback
     * @returns
     */
    product(callback) {
        this.video.products = this.video.products || [];
        this.video.products.push(productFactory(callback, true));
        return this;
    }
    /**
     * Remove all products from video.
     * @returns
     */
    clearProducts() {
        this.video.products = [];
        return this;
    }
    /**
     * Build method used to return the product of video builder.
     * @returns
     */
    build() {
        return this.video;
    }
}
