import { logInfo } from '@core/logging';
/**
 *
 */
export function createShoppingActions(set) {
    return {
        resetCart: () => set({ cart: [] }, false, 'resetCart'),
        configureCart: (payload) => set((state) => ({
            cartUrl: payload?.url || state.cartUrl,
            cartCurrency: payload?.currency || state.cartCurrency,
            cartAddToCartText: payload?.addToCartText || state.cartAddToCartText,
            cartItemInCartText: payload?.itemInCartText || state.cartItemInCartText,
        }), false, 'configureCart'),
        hydrateProducts: (products) => {
            set((state) => {
                const newProductsHydrated = {
                    ...state.productsHydrated,
                };
                for (const product of products) {
                    newProductsHydrated[product.product_ext_id] = product;
                }
                return {
                    productsHydrated: newProductsHydrated,
                };
            }, false, 'hydrateProducts');
        },
        updateUnitCartQuantity: (payload) => {
            const { productUnit, targetQuantity, product } = payload;
            set((state) => {
                if (productUnit) {
                    let newCart;
                    if (state.cart.find((ci) => String(ci.unit.unit_ext_id) ===
                        String(productUnit?.unit_ext_id))) {
                        newCart = [
                            ...state.cart.map((ci) => {
                                if (String(ci.unit.unit_ext_id) ===
                                    String(productUnit?.unit_ext_id)) {
                                    return {
                                        unit: ci.unit,
                                        quantity: targetQuantity || 0,
                                        product: ci.product,
                                    };
                                }
                                else {
                                    return ci;
                                }
                            }),
                        ];
                    }
                    else {
                        newCart = [
                            {
                                unit: productUnit,
                                quantity: targetQuantity ?? 1,
                                product,
                            },
                            ...state.cart,
                        ];
                    }
                    return {
                        cart: newCart.filter((ci) => ci.quantity && ci.quantity > 0),
                    };
                }
                else {
                    return state;
                }
            }, false, 'updateUnitCartQuantity');
        },
        setCartUpdatedCallback: (callback) => {
            set({
                cartUpdatedCallback: wrapWithLogger(callback, 'cartUpdatedCallback'),
            }, false, 'setCartUpdatedCallback');
        },
        setCartDisplayedCallback: (callback) => {
            set({
                cartDisplayedCallback: wrapWithLogger(callback, 'cartDisplayedCallback'),
            }, false, 'setCartDisplayedCallback');
        },
        setProductsLoadedCallback: (callback) => {
            set({
                productsLoadedCallback: wrapWithLogger(callback, 'productsLoadedCallback'),
            }, false, 'setProductsLoadedCallback');
        },
        cartUpdateStarted: () => {
            set((state) => ({
                pendingCartUpdate: state.pendingCartUpdate + 1,
            }), false, 'cartUpdateStarted');
        },
        cartUpdateEnded: () => {
            set((state) => ({
                pendingCartUpdate: state.pendingCartUpdate - 1,
                lastCartUpdateTs: Date.now(),
            }), false, 'cartUpdateEnded');
        },
    };
}
function wrapWithLogger(callback, name) {
    return (...args) => {
        logInfo(`[fw-shopping] ${name}`, args);
        return callback(...args);
    };
}
