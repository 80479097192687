import { LIVESTREAM_SLICE_KEY } from './slice';
/**
 * @returns The partialized livestream state that needs to persist.
 */
export function partializeForLocalStorage(state) {
    const { username } = state[LIVESTREAM_SLICE_KEY];
    return {
        [LIVESTREAM_SLICE_KEY]: {
            username,
        },
    };
}
/**
 * @returns The partialized livestream state that needs to persist in session storage.
 */
export function partializeForSessionStorage(state) {
    const { activeWatches, usernameHydrated, liveStreamGiveaways } = state[LIVESTREAM_SLICE_KEY];
    /**
     * This removes the `dismissed` property from being stored in sessionStorage so that on refresh,
     * even if the viewer dismissed the giveaway modals, they'll show again.
     * The shape of liveStreamGiveaways is
     * {
     *   [livestreamId]: {
     *     [interactionId]: { entered: boolean, dismissed: boolean, winnerRevealed: boolean}
     *   }
     * }
     *
     * So all the Object.fromEntries(Object.entries....) are breaking down the liveStreamGiveaways object and reassembling it without the dismissed prop
     */
    const liveStreamGiveawaysWithoutDismissedProp = Object.fromEntries(Object.entries(liveStreamGiveaways).map(([livestreamId, giveaway]) => [
        livestreamId,
        Object.fromEntries(Object.entries(giveaway).map(([giveawayId, { dismissed: _, ...rest }]) => [giveawayId, rest])),
    ]));
    return {
        [LIVESTREAM_SLICE_KEY]: {
            activeWatches,
            usernameHydrated,
            liveStreamGiveaways: liveStreamGiveawaysWithoutDismissedProp,
        },
    };
}
/**
 * Merges persisted state with current state.
 */
export function merge(persistedState, currentState) {
    return {
        [LIVESTREAM_SLICE_KEY]: {
            ...currentState[LIVESTREAM_SLICE_KEY],
            ...persistedState[LIVESTREAM_SLICE_KEY],
        },
    };
}
