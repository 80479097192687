import { PIP_IFRAME_SCROLL_POSITION } from '@core/pictureInPicture/constants';
import { getSessionStorage } from '@core/storage';
/**
 * Restore scroll if player was closed in PIP
 */
export function restoreScroll() {
    const storage = getSessionStorage();
    const scrollY = storage.getItem(PIP_IFRAME_SCROLL_POSITION);
    if (scrollY) {
        window.scrollTo(0, +scrollY);
        storage.removeItem(PIP_IFRAME_SCROLL_POSITION);
    }
}
