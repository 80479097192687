/**
 * @fileoverview
 * Provides lazy access to the VideoElementCache stored
 * on _fwn web sdk object.
 */
import { getFWN } from '@core/fwn';
import { VideoElementCache } from './VideoElementCache';
let defaultCache = undefined;
/**
 *
 */
export const lazyCacheGetter = () => {
    const fwn = getFWN();
    const cache = fwn?.videoElementCache ||
        defaultCache ||
        (defaultCache = new VideoElementCache());
    if (fwn && !fwn?.videoElementCache) {
        Object.defineProperty(fwn, 'videoElementCache', {
            writable: false,
            value: cache,
        });
    }
    return cache;
};
