/**
 * List of embed-feed variables.
 */
export const VARIABLES = [
    ['--thumbnail-border-radius', { external: true }],
    ['--thumbnail-font-size', { external: true }],
    ['--thumbnail-font-weight', { external: true }],
    ['--thumbnail-gap', { external: true }],
    ['--thumbnail-height', { external: true }],
    ['--thumbnail-width', { external: true }],
    ['--thumbnail-aspect-ratio', { external: true }],
];
