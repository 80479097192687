import { FWNElement } from './bases/FWNElement';
class AddToCalendarElement extends FWNElement {
    render() {
        import('@embed/addToCalendarRender').then(({ addToCalendarRender }) => {
            if (this.shadowRoot) {
                addToCalendarRender({
                    target: this.shadowRoot,
                    widgetId: this.widgetId,
                    widgetParams: this.getAttributes(),
                });
            }
        });
    }
}
if (!customElements.get('fw-add-to-calendar')) {
    customElements.define('fw-add-to-calendar', AddToCalendarElement);
}
