export var Api_Video_Type;
(function (Api_Video_Type) {
    Api_Video_Type["COLLAB_MIRROR"] = "collab_mirror";
    Api_Video_Type["COLLAB_REACT"] = "collab_react";
    Api_Video_Type["COLLAB_SPLIT"] = "collab_split";
    Api_Video_Type["COLLAB_STITCH"] = "collab_stitch";
    Api_Video_Type["FRAMELESS"] = "frameless";
    Api_Video_Type["LIVESTREAM"] = "live_stream";
    Api_Video_Type["MULTICAM"] = "multicam";
    Api_Video_Type["OPEN_AUCTION_AD"] = "open_auction_ad";
})(Api_Video_Type || (Api_Video_Type = {}));
export var Api_Video_CTA_Type;
(function (Api_Video_CTA_Type) {
    Api_Video_CTA_Type["DEFAULT_CTA"] = "cta";
    Api_Video_CTA_Type["REMIND_ME"] = "reminder";
})(Api_Video_CTA_Type || (Api_Video_CTA_Type = {}));
export var Api_Video_AspectRatio;
(function (Api_Video_AspectRatio) {
    Api_Video_AspectRatio["HORIZONTAL"] = "16:9";
    Api_Video_AspectRatio["VERTICAL"] = "9:16";
})(Api_Video_AspectRatio || (Api_Video_AspectRatio = {}));
export var VideoEmailEmbedType;
(function (VideoEmailEmbedType) {
    VideoEmailEmbedType["ANIMATED"] = "animated";
    VideoEmailEmbedType["STATIC"] = "static";
})(VideoEmailEmbedType || (VideoEmailEmbedType = {}));
export var VideoEmailEmbedSize;
(function (VideoEmailEmbedSize) {
    VideoEmailEmbedSize["SMALL"] = "small";
    VideoEmailEmbedSize["BEST_FIT"] = "large";
})(VideoEmailEmbedSize || (VideoEmailEmbedSize = {}));
export var FlowInteractionEnum;
(function (FlowInteractionEnum) {
    FlowInteractionEnum["MULTIPLE_CHOICE"] = "multiple_choice";
    FlowInteractionEnum["CTA"] = "cta";
    FlowInteractionEnum["PRODUCT"] = "product";
    FlowInteractionEnum["QUESTION"] = "question";
    FlowInteractionEnum["POLL"] = "poll";
})(FlowInteractionEnum || (FlowInteractionEnum = {}));
