/**
 * This is the list of polyfills detected by running the following command:
 * `npx create-polyfill-service-url analyse --file ./build/js/fwn.js --file ./build/js/videoplayer-{hash}.js`
 *
 * The list is then manually sorted and cleaned up. `null` means that the polyfill
 * is no longer available (see the comment bellow) or is an alias to some other polyfill.
 *
 * =================================================================================================
 * Bellow is the list of polyfills removed from polyfill.io as the
 * support for IE8 was removed. See https://github.com/Financial-Times/polyfill-library/pull/1153
 *
 * Array.isArray
 * Array.prototype.every
 * Array.prototype.filter
 * Array.prototype.forEach
 * Array.prototype.indexOf
 * Array.prototype.lastIndexOf
 * Array.prototype.map
 * Array.prototype.reduce
 * Array.prototype.reduceRight
 * Array.prototype.some
 * Date.now
 * Date.prototype.toISOString
 * document
 * document.getElementsByClassName
 * document.head
 * document.querySelector
 * Element
 * Element.prototype.cloneNode
 * Element.prototype.nextElementSibling
 * Element.prototype.previousElementSibling
 * Function.prototype.bind
 * getComputedStyle
 * navigator.geolocation
 * Object.create
 * Object.defineProperties
 * Object.defineProperty
 * Object.getPrototypeOf
 * String.prototype.anchor
 * String.prototype.big
 * String.prototype.blink
 * String.prototype.bold
 * String.prototype.fixed
 * String.prototype.fontcolor
 * String.prototype.fontsize
 * String.prototype.italics
 * String.prototype.link
 * String.prototype.small
 * String.prototype.strike
 * String.prototype.sub
 * String.prototype.sup
 * XMLHttpRequest
 * ~viewport
 *
 * This is the list of polyfills removed from polyfill.io as the support
 * for <IE8 was removed. See https://github.com/Financial-Times/polyfill-library/pull/1149
 *
 * JSON
 * localStorage
 * Window
 */
/**
 * Keeping `null` values for features which are still reported by
 * the `create-polyfill-service-url` tool but no longer supported by polyfill.io
 *
 * Ignored by prettier for easier maintenance of one-liners.
 */
// prettier-ignore
export const features = [
    ['AbortController', require('./detects/polyfill-library/polyfills/AbortController/detect')],
    ['Array.from', require('./detects/polyfill-library/polyfills/Array/from/detect')],
    ['Array.isArray', null],
    ['Array.prototype.entries', require('./detects/polyfill-library/polyfills/Array/prototype/entries/detect')],
    ['Array.prototype.every', null],
    ['Array.prototype.fill', require('./detects/polyfill-library/polyfills/Array/prototype/fill/detect')],
    ['Array.prototype.filter', null],
    ['Array.prototype.find', require('./detects/polyfill-library/polyfills/Array/prototype/find/detect')],
    ['Array.prototype.findIndex', require('./detects/polyfill-library/polyfills/Array/prototype/findIndex/detect')],
    ['Array.prototype.flat', require('./detects/polyfill-library/polyfills/Array/prototype/flat/detect')],
    ['Array.prototype.forEach', null],
    ['Array.prototype.includes', require('./detects/polyfill-library/polyfills/Array/prototype/includes/detect')],
    ['Array.prototype.indexOf', null],
    ['Array.prototype.keys', require('./detects/polyfill-library/polyfills/Array/prototype/keys/detect')],
    ['Array.prototype.lastIndexOf', null],
    ['Array.prototype.map', null],
    ['Array.prototype.reduce', null],
    ['Array.prototype.some', null],
    ['Array.prototype.sort', require('./detects/polyfill-library/polyfills/Array/prototype/sort/detect')],
    ['Array.prototype.values', require('./detects/polyfill-library/polyfills/Array/prototype/values/detect')],
    ['ArrayBuffer', require('./detects/polyfill-library/polyfills/ArrayBuffer/detect')],
    ['Blob', require('./detects/polyfill-library/polyfills/Blob/detect')],
    ['console', require('./detects/polyfill-library/polyfills/console/detect')],
    ['CustomEvent', require('./detects/polyfill-library/polyfills/CustomEvent/detect')],
    ['DataView', require('./detects/polyfill-library/polyfills/ArrayBuffer/detect')], // Alias for ArrayBuffer
    ['Date.now', null],
    ['Date.prototype.toISOString', null],
    ['document', null],
    ['document.currentScript', require('./detects/polyfill-library/polyfills/document/currentScript/detect')],
    ['document.visibilityState', require('./detects/polyfill-library/polyfills/document/visibilityState/detect')], // new
    ['Element', null],
    ['Event', require('./detects/polyfill-library/polyfills/Event/detect')],
    ['fetch', require('./detects/polyfill-library/polyfills/fetch/detect')],
    ['Float32Array', null], // Alias for `ArrayBuffer`
    ['Function.prototype.bind', null],
    ['getComputedStyle', null],
    ['IntersectionObserver', require('./detects/polyfill-library/polyfills/IntersectionObserver/detect')],
    // TODO(ZE-336): The polyfill.io code raises an error. We need to investigate why.
    // ['Intl.DateTimeFormat', require('./detects/polyfill-library/polyfills/Intl/DateTimeFormat/detect')],
    // ['Intl.NumberFormat', require('./detects/polyfill-library/polyfills/Intl/NumberFormat/detect')],
    ['JSON', null],
    ['localStorage', null],
    ['Map', require('./detects/polyfill-library/polyfills/Map/detect')],
    ['modernizr:es5object', null], // Alias to `Object.keys`, `Object/getOwnPropertyNames` and `Object/getOwnPropertyDescriptor`
    ['MutationObserver', require('./detects/polyfill-library/polyfills/MutationObserver/detect')],
    ['Number.isNaN', require('./detects/polyfill-library/polyfills/Number/isNaN/detect')],
    ['Number.MAX_SAFE_INTEGER', require('./detects/polyfill-library/polyfills/Number/MAX_SAFE_INTEGER/detect')],
    ['Number.MIN_SAFE_INTEGER', require('./detects/polyfill-library/polyfills/Number/MIN_SAFE_INTEGER/detect')],
    ['Object.assign', require('./detects/polyfill-library/polyfills/Object/assign/detect')],
    ['Object.defineProperties', null],
    ['Object.defineProperty', null],
    ['Object.freeze', require('./detects/polyfill-library/polyfills/Object/freeze/detect')],
    ['Object.getOwnPropertyDescriptor', require('./detects/polyfill-library/polyfills/Object/getOwnPropertyDescriptor/detect')],
    ['Object.getOwnPropertyDescriptors', require('./detects/polyfill-library/polyfills/Object/getOwnPropertyDescriptors/detect')],
    ['Object.getOwnPropertyNames', require('./detects/polyfill-library/polyfills/Object/getOwnPropertyNames/detect')],
    ['Object.getOwnPropertySymbols', null], // Alias for `Symbol`
    ['Object.isFrozen', require('./detects/polyfill-library/polyfills/Object/isFrozen/detect')],
    ['Object.seal', require('./detects/polyfill-library/polyfills/Object/seal/detect')],
    ['Object.setPrototypeOf', require('./detects/polyfill-library/polyfills/Object/setPrototypeOf/detect')],
    ['Promise', require('./detects/polyfill-library/polyfills/Promise/detect')],
    ['Reflect', require('./detects/polyfill-library/polyfills/Reflect/detect')],
    ['Reflect.construct', require('./detects/polyfill-library/polyfills/Reflect/construct/detect')],
    ['requestAnimationFrame', require('./detects/polyfill-library/polyfills/requestAnimationFrame/detect')],
    ['ResizeObserver', require('./detects/polyfill-library/polyfills/ResizeObserver/detect')],
    ['Set', require('./detects/polyfill-library/polyfills/Set/detect')],
    ['String.prototype.includes', require('./detects/polyfill-library/polyfills/String/prototype/includes/detect')],
    ['String.prototype.padEnd', require('./detects/polyfill-library/polyfills/String/prototype/padEnd/detect')],
    ['String.prototype.startsWith', require('./detects/polyfill-library/polyfills/String/prototype/startsWith/detect')],
    ['String.prototype.trim', require('./detects/polyfill-library/polyfills/String/prototype/trim/detect')],
    ['Symbol', require('./detects/polyfill-library/polyfills/Symbol/detect')],
    ['Symbol.for', null], // Alias for `Symbol`
    ['Symbol.iterator', require('./detects/polyfill-library/polyfills/Symbol/iterator/detect')],
    ['Symbol.prototype.description', require('./detects/polyfill-library/polyfills/Symbol/prototype/description/detect')],
    ['Symbol.toStringTag', require('./detects/polyfill-library/polyfills/Symbol/toStringTag/detect')],
    ['Uint16Array', null], // Alias for `ArrayBuffer`
    ['Uint8Array', null], // Alias for `ArrayBuffer`
    ['URL', require('./detects/polyfill-library/polyfills/URL/detect')],
    ['URLSearchParams', null], // Alias for `URL`
    ['WeakMap', require('./detects/polyfill-library/polyfills/WeakMap/detect')],
    ['WeakSet', require('./detects/polyfill-library/polyfills/WeakSet/detect')],
    ['XMLHttpRequest', null],
];
