var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
import { ensureIntersectionObserver } from '@core/polyfills';
/**
 * Mixin providing onVisibilityChange method.
 *
 * Docs on mixins: https://lit.dev/docs/composition/mixins/
 * @param superClass
 * @returns
 */
export const VisibilityMixin = (superClass) => {
    var _VisibilityMixinClass_visibilityObserver;
    class VisibilityMixinClass extends superClass {
        constructor() {
            super(...arguments);
            _VisibilityMixinClass_visibilityObserver.set(this, null
            /**
             * Initiate and connect IntersectionObserver to the element.
             */
            );
        }
        /**
         * Initiate and connect IntersectionObserver to the element.
         */
        connectedCallback() {
            super.connectedCallback();
            ensureIntersectionObserver().then((IO) => {
                __classPrivateFieldSet(this, _VisibilityMixinClass_visibilityObserver, new IO((entries) => {
                    const entry = entries[0];
                    this?.onVisibilityChange(entry.isIntersecting);
                }), "f");
                __classPrivateFieldGet(this, _VisibilityMixinClass_visibilityObserver, "f").observe(this);
            });
        }
        /**
         * Disconnect the observer as the element is removed from the DOM.
         */
        disconnectedCallback() {
            super.disconnectedCallback();
            __classPrivateFieldGet(this, _VisibilityMixinClass_visibilityObserver, "f")?.disconnect();
        }
        /**
         * Noop method to be overridden by the component.
         */
        onVisibilityChange(_isVisible) {
            // noop
        }
    }
    _VisibilityMixinClass_visibilityObserver = new WeakMap();
    return VisibilityMixinClass;
};
