import { FeedType } from '@core/feed';
import { Context } from '@embed/components/helpers';
/**
 * Helper function to build trackingData payload out
 * of widget params and fetched channel/publisherClient
 */
export const mapConfigToTrackingData = (config, channel) => {
    if (!config) {
        return null;
    }
    switch (true) {
        case config.type === FeedType.PLAYLIST: {
            return {
                trackingData: {
                    context: Context.CONTEXT_PLAYLIST,
                    channel: channel?.username,
                    channel_id: channel?.encoded_id,
                    playlist_id: config.playlistId,
                },
                trackingDataPixel: {
                    context: Context.CONTEXT_PLAYLIST,
                    channel: channel?.username,
                    _channel_id: channel?.encoded_id,
                    _playlist_id: config.playlistId,
                },
            };
        }
        case config.type === FeedType.PLAYLIST_COLLECTION: {
            return {
                trackingData: {
                    context: Context.CONTEXT_PLAYLIST_COLLECTION,
                    channel: channel?.username,
                    channel_id: channel?.encoded_id,
                    playlist_id: config.playlistId,
                },
                trackingDataPixel: {
                    context: Context.CONTEXT_PLAYLIST_COLLECTION,
                    channel: channel?.username,
                    _channel_id: channel?.encoded_id,
                    _playlist_id: config.playlistId,
                },
            };
        }
        case config.type === FeedType.PRODUCT_SKU: {
            return {
                trackingData: {
                    context: Context.CONTEXT_SKUS,
                    channel: channel?.username,
                    channel_id: channel?.encoded_id,
                    skus: config.skus,
                },
                trackingDataPixel: {
                    context: Context.CONTEXT_SKUS,
                    channel: channel?.username,
                    _channel_id: channel?.encoded_id,
                    skus: config.skus,
                },
            };
        }
        case config.type === FeedType.USER_TIMELINE_HASHTAG: {
            return {
                trackingData: {
                    context: Context.CONTEXT_HASHTAG,
                    hashtag: config.hashtagExpression,
                    channel: channel?.username,
                    channel_id: channel?.encoded_id,
                },
                trackingDataPixel: {
                    context: Context.CONTEXT_HASHTAG,
                    hashtag: config.hashtagExpression,
                    channel: channel?.username,
                    _channel_id: channel?.encoded_id,
                },
            };
        }
        case config.type === FeedType.USER_TIMELINE: {
            return {
                trackingData: {
                    context: Context.CONTEXT_CHANNEL,
                    channel: channel?.username,
                    channel_id: channel?.encoded_id,
                },
                trackingDataPixel: {
                    context: Context.CONTEXT_CHANNEL,
                    channel: channel?.username,
                    _channel_id: channel?.encoded_id,
                },
            };
        }
        case config.type === FeedType.SHOWROOM_SCHEDULE: {
            return {
                trackingData: {
                    context: Context.CONTEXT_SHOWROOM_SCHEDULE,
                    channel: channel?.username,
                    channel_id: channel?.encoded_id,
                    showroom_id: config.showroomId,
                },
                trackingDataPixel: {
                    context: Context.CONTEXT_SHOWROOM_SCHEDULE,
                    channel: channel?.username,
                    _channel_id: channel?.encoded_id,
                    _showroom_id: config.showroomId,
                },
            };
        }
        default: {
            return null;
        }
    }
};
