/**
 * This function will put ad videos to specific position inside feed videos
 */
export function putAdsToPositionInFeed(props) {
    const { videos, adVideos, positions } = props;
    if (positions.length === 0 || adVideos.length === 0) {
        return videos;
    }
    const positionSet = new Set(positions);
    const videosClone = [...videos];
    const adVideosClone = [...adVideos];
    const stitchedVideos = [];
    while (videosClone.length > 0) {
        const index = stitchedVideos.length;
        if (positionSet.has(index) && adVideosClone.length > 0) {
            stitchedVideos.push(adVideosClone.shift());
        }
        else {
            stitchedVideos.push(videosClone.shift());
        }
    }
    return stitchedVideos;
}
