export const UPDATE_PLAYER_STATE_SYNC = 'fwn:update_player_state_sync';
export const REMOVE_PLAYER_STATE_SYNC = 'fwn:remove_player_state_sync';
export const GLOBAL_PLAYERS_STATE_SYNC = 'fwn:global_players_state_sync';
// Minimized (PIP) player desktop screen dimensions
export const PIP_STANDARD_SHORT_LENGTH = 180;
export const PIP_STANDARD_LONG_LENGTH = 320;
// Minimized (PIP) player mobile screen dimensions
export const PIP_REDUCED_SHORT_LENGTH = 106;
export const PIP_REDUCED_LONG_LENGTH = 188;
// Desktop/Mobile breakpoint
export const DESKTOP_WIDTH_BREAKPOINT = 768;
