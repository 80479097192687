/* eslint-disable no-console */
import { isDebug } from '@core/debug';
import { debugInfoGradient, logError } from '@core/logging/helpers';
/**
 * Console log for resolved params
 */
export function debugParams(paramsSource, resolvedParams) {
    if (!isDebug()) {
        return;
    }
    try {
        const customElement = `fw-` + resolvedParams.type?.replace('_', '-');
        const element = Array.from(document.querySelectorAll(customElement)).find((el) => el['widgetId'] === resolvedParams.widget_id) || resolvedParams.target;
        console.groupCollapsed(`%cFW-DEBUG <${customElement}> (${resolvedParams.widget_id})`, debugInfoGradient);
        console.log('Matched elements:', element);
        console.dir(element);
        console.log('---');
        let styleMap;
        try {
            styleMap = element.computedStyleMap() ?? [];
        }
        catch {
            styleMap = [];
        }
        const cssVars = Array.from(styleMap).filter(([prop]) => prop.startsWith('--fw-'));
        console.groupCollapsed('CSS Variables:', cssVars.length);
        console.table(cssVars.map(([prop, value]) => [prop, `${value}`]));
        console.groupEnd();
        console.log('---');
        const arrayOfParams = [
            ...Object.keys(paramsSource).map((key) => [key, paramsSource[key]]),
            ['= Resolved params:', resolvedParams],
        ];
        arrayOfParams.forEach(([title, params]) => {
            console.groupCollapsed(title, Object.values(params ?? {}).length);
            if (params && Object.values(params).length) {
                console.table(Object.entries(params));
            }
            console.groupEnd();
        });
        console.groupEnd();
    }
    catch (e) {
        logError(e);
    }
}
