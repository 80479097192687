import { mergeVariables } from '../tools/mergeVariables';
import { theme as defaultTheme } from './default';
export const theme = {
    key: 'dark',
    variables: mergeVariables(defaultTheme.variables, [
        ['--font-color', 'var(--white)'],
        ['--player-chat-background', 'rgb(var(--blk2-rgb) / 90%)'],
        ['--player-shop-background', 'rgb(var(--blk2-rgb) / 90%)'],
    ]),
};
