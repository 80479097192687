/**
 * List of share widget variables.
 */
export const VARIABLES = [
    ['--share-border', { external: true }],
    ['--share-color', { external: true }],
    ['--share-font-family', { external: true }],
    ['--share-font-size', { external: true }],
    ['--share-font-weight', { external: true }],
    ['--share-height', { external: true }],
    ['--share-hover-border', { external: true }],
    ['--share-hover-color', { external: true }],
    ['--share-hover-font-size', { external: true }],
    ['--share-hover-font-weight', { external: true }],
    ['--share-width', { external: true }],
    ['--share-background', { external: true }],
    ['--share-hover-background', { external: true }],
];
