import { getShareTemplate } from './getShareTemplate';
/**
 * Combining the logic to get the base URL of a video, in order of importance:
 * shared template url > store params page URL >  window location href
 */
export function getBaseUrl({ params, }) {
    const shareTemplateURL = getShareTemplate(params?.share_template);
    let baseUrl = '';
    try {
        baseUrl = shareTemplateURL
            ? shareTemplateURL.toString()
            : params?.page_url ?? window.location.href;
    }
    catch (err) {
        baseUrl = params?.page_url ?? window.location.href;
    }
    return baseUrl;
}
