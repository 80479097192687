/**
 * @fileoverview
 * Functions for gathering client data and building core tracking payloads.
 */
import { pruneNilValues } from '@core/object';
import { StorageKeyPerformanceEnum } from '@core/storage/types';
import { getTrackingProduct, locale, nowAndNowLocalToString, } from '@core/tracking';
import { getCookieWithFallback } from '@core/tracking/funnelTracking';
import { getOS } from '@core/userAgent';
import { getPlatform } from '@embed/tools/utils';
import { getNextJsPublicEnv } from '@src/helpers';
const commonFields = () => ({
    platform: getPlatform(),
    product: getTrackingProduct(),
    product_version: getNextJsPublicEnv('RELEASE_VERSION') ?? process.env.RELEASE_VERSION,
    track_version: getNextJsPublicEnv('TRACK_VERSION') ?? process.env.TRACK_VERSION,
});
/**
 * Get root fields for Naboo tracking
 * @deprecated
 */
export const getGlobalTrackingFieldsLegacy = () => ({
    ...commonFields(),
    country: locale?.split('-')[1],
    os: getOS(),
});
/**
 * Get root fields for pixel tracking
 */
export const getGlobalTrackingFields = () => {
    const { now } = nowAndNowLocalToString();
    return pruneNilValues({
        ...commonFields(),
        client_event_time: now,
        os_name: getOS(),
        host: document.location.host,
        _business_id: getCookieWithFallback(StorageKeyPerformanceEnum.fw_bid)
            ?.value,
    });
};
