import { useCallback, useRef } from 'react';
import { useStore } from '@core/widgets/store';
import { customEmbedEvent } from '@embed/customEmbedEvent';
/**
 * Dispatches an embed event passing a video, if any.
 */
export const useDispatchEmbedImpression = () => {
    const params = useStore((state) => state.params);
    const isTracked = useRef(false);
    return useCallback(({ type, video }) => {
        if (!isTracked.current) {
            isTracked.current = true;
            customEmbedEvent({
                type,
                params,
                data: video ? { video } : {},
            });
        }
    }, [params]);
};
