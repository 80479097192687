import { WidgetSizeEnum } from '@core/types';
import { TitlePlaceholder } from '@embed/components/common/PlayerTitle.styles';
import { Grid, GridInner, GridWrapper } from './GridComponent.styles';
import Placeholder from './Placeholder';
/**
 * Grid's placeholders rendered while videos are loading.
 */
export const GridPlaceholders = ({ columns, largeCarousel, params, placeholders, }) => {
    return (<>
      {/* Reserve space for the title if there is one */}
      {params.title && <TitlePlaceholder />}
      <GridWrapper borderStyle={params.ui_border_style}>
        <GridInner>
          <Grid columns={columns} gridMode={params.mode}>
            {placeholders.map((_, i) => (<Placeholder isLarge={largeCarousel} key={i} mode={params.mode} isSmall={params.size === WidgetSizeEnum.SMALL}/>))}
          </Grid>
        </GridInner>
      </GridWrapper>
    </>);
};
