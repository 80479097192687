import { hydrateApi } from './api';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'hydrate')) {
        Object.defineProperty(window._fwn, 'hydrate', {
            value: hydrateApi,
            writable: false,
        });
    }
}
