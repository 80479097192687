import deepmerge from 'deepmerge';
/**
 *
 * @param args
 * @returns
 */
function merge(...args) {
    return deepmerge.all(args);
}
export default merge;
