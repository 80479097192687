import React, { useEffect, useMemo } from 'react';
import { useAdInFeed } from '@expPlatform/embeds/ads/hooks/useAdInFeed';
import { useAdsOnly } from '@expPlatform/embeds/ads/hooks/useAdsOnly';
import { putAdsToPositionInFeed } from '@expPlatform/embeds/ads/utils/putAdsToPositionInFeed';
import { useFeedQueryContext } from '@core/feed';
import { WidgetSizeEnum } from '@core/types';
import { WidgetWrapper } from '@core/widgets';
import { EmbedFeedStoreProvider } from '@core/widgets/embedFeed/store';
import { useStore } from '@core/widgets/store';
import { actionsSelector, appContextSelector, paramsSelector, } from '@core/widgets/store/selectors';
import { Grid } from '@embed/components/Grid';
import { AppContextType } from '@embed/components/helpers';
import { GlobalCustomCSS } from '@embed/styles/GlobalCustomCSS';
const EmbedFeedComponent = () => {
    const appContext = useStore(appContextSelector);
    const params = useStore(paramsSelector);
    const { setVideos } = useStore(actionsSelector);
    const [{ loading, videos: feedVideos, playlists }, feedActions] = useFeedQueryContext();
    const isAdsOnly = useAdsOnly({ appContext });
    const { adVideos, positions, loading: adVideosLoading, } = useAdInFeed({
        appContext,
        params,
    });
    const videos = useMemo(() => {
        if (isAdsOnly) {
            return adVideos;
        }
        return putAdsToPositionInFeed({
            adVideos,
            videos: feedVideos,
            positions,
        });
    }, [isAdsOnly, adVideos, feedVideos, positions]);
    useEffect(() => {
        if (!loading && !adVideosLoading) {
            setVideos(videos);
        }
    }, [loading, adVideosLoading, videos, setVideos]);
    const isPlaylistOrHasVideos = useMemo(() => {
        return !!playlists.length || !!videos.length;
    }, [videos.length, playlists.length]);
    if (isAdsOnly && !isPlaylistOrHasVideos) {
        return null;
    }
    // Skip rendering (collapse) the widget if there are no videos to show and
    // there are no playlists
    if (!loading && !adVideosLoading && !isPlaylistOrHasVideos) {
        return null;
    }
    return (<>
      <GlobalCustomCSS params={params}/>
      <Grid loading={loading} appContext={appContext} params={params} videos={!loading && !adVideosLoading ? videos : []} playlists={playlists} fetchMoreVideos={feedActions.loadMore} largeCarousel={params.size === WidgetSizeEnum.LARGE && params.mode !== 'pinned'}/>
    </>);
};
/**
 *
 */
const EmbedFeed = ({ initialParams, widgetParams }) => (<WidgetWrapper appContextType={AppContextType.EMBED_GRID_CONTEXT_TYPE} initialParams={initialParams} widgetParams={widgetParams}>
    <EmbedFeedStoreProvider>
      <EmbedFeedComponent />
    </EmbedFeedStoreProvider>
  </WidgetWrapper>);
export default EmbedFeed;
