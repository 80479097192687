import { fetcher } from '@embed/tools/fetchers';
function getFirstCDATASectionFromNodeList(nodeList) {
    return nodeList instanceof NodeList
        ? [...nodeList].find((e) => e instanceof CDATASection)
        : undefined;
}
/**
 * Given a VAST XML document, extract a redirect URL.
 *
 * There are three places where we might find redirect URLs in a VAST tag.
 * The first is from the direct text content of the VASTAdTagURI tag.
 * The other two cases are Playlist tags and VMap tags, which rely on CDATASections.
 * [CDATASection](https://developer.mozilla.org/en-US/docs/Web/API/CDATASection) is like a text node.
 */
function getRedirectUrlsFromVastXml(doc) {
    const adTagURIElements = doc.getElementsByTagName('VASTAdTagURI');
    if (adTagURIElements.length > 0) {
        return Array.from(adTagURIElements).map((element) => element?.childNodes[0].textContent);
    }
    const isPlaylist = doc.getElementsByTagName('Playlist').length === 1;
    if (isPlaylist) {
        return Array.from(doc.getElementsByTagName('Ad'))
            .map((AdNode) => getFirstCDATASectionFromNodeList(AdNode?.childNodes)?.wholeText?.trim())
            .filter(Boolean);
    }
    const redirectsForVmapAdTag = Array.from(doc.getElementsByTagName('vmap:AdTagURI'))
        .map((node) => getFirstCDATASectionFromNodeList(node?.childNodes)?.wholeText.trim())
        .filter(Boolean);
    if (redirectsForVmapAdTag.length > 0) {
        return redirectsForVmapAdTag;
    }
    return [];
}
/**
 * Test integration for DoubleVerify POC
 */
const modifyDoubleVerifyUrl = (urlObject, timestamp, pageUrl) => {
    const nestedVast = urlObject.searchParams.get('_vast');
    if (nestedVast) {
        const nestedVastUrlObject = new URL(nestedVast);
        nestedVastUrlObject.searchParams.set('correlator', timestamp);
        nestedVastUrlObject.searchParams.set('description_url', pageUrl);
        nestedVastUrlObject.searchParams.set('url', pageUrl);
        urlObject.searchParams.set('_vast', nestedVastUrlObject.href);
    }
    urlObject.searchParams.set('_api', '2');
    urlObject.searchParams.set('_ssm', '0');
    urlObject.searchParams.set('_tsm', timestamp);
    urlObject.searchParams.set('gdpr', '0');
    urlObject.searchParams.delete('gdpr_consent');
    urlObject.searchParams.delete('_abm');
    urlObject.searchParams.set('_pum', pageUrl);
};
/**
 * Since ad tags are static urls, we need to update them with correct page url where
 * ad tag was used
 */
const updateUrlWithPageInfo = (url) => {
    const urlObject = new URL(url);
    const timestamp = Date.now().toString();
    const pageUrl = location.href || '';
    const isDoubleVerify = urlObject.hostname
        .toLowerCase()
        .includes('doubleverify');
    if (isDoubleVerify) {
        modifyDoubleVerifyUrl(urlObject, timestamp, pageUrl);
    }
    else {
        urlObject.searchParams.set('correlator', timestamp);
        urlObject.searchParams.set('description_url', pageUrl);
        urlObject.searchParams.set('url', pageUrl);
    }
    return urlObject.href;
};
export const GAM_MAX_TRIES = 5;
/**
 * Hits the given GAM URL and returns an XML string.
 *
 * If the XML from GAM gives an empty VAST, we parse the XML for a redirect URL.
 * Then, we request that new GAM url, parse the XML, and look for non-empty VAST again...
 * This happens up to `MAX_TRIES` times.
 */
export async function fetchGAM(url, tries = 1) {
    const response = await fetcher({ url: updateUrlWithPageInfo(url) });
    const text = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/xml');
    const isVastValid = doc.getElementsByTagName('AdParameters').length > 0;
    if (isVastValid) {
        return [text];
    }
    else {
        const redirectUrls = getRedirectUrlsFromVastXml(doc);
        if (redirectUrls.length > 0 && tries < GAM_MAX_TRIES) {
            const result = await Promise.all(redirectUrls.map((redirectUrl) => fetchGAM(redirectUrl, tries + 1)));
            return result.flat(2);
        }
        return [];
    }
}
