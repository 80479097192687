import { createContext as reactCreateContext, createElement, useContext, useMemo, useRef, } from 'react';
/**
 *
 */
export function createContext() {
    const ZustandContext = reactCreateContext(undefined);
    const Provider = ({ store, children, }) => {
        const storeRef = useRef();
        if (!storeRef.current) {
            storeRef.current = store;
        }
        return createElement(ZustandContext.Provider, { value: storeRef.current }, children);
    };
    const useStore = (selector, equalityFn = Object.is) => {
        // ZustandContext value is guaranteed to be stable.
        const useProviderStore = useContext(ZustandContext);
        if (!useProviderStore) {
            throw new Error('Seems like you have not used zustand provider as an ancestor.');
        }
        return useProviderStore(selector, equalityFn);
    };
    const useStoreApi = () => {
        // ZustandContext value is guaranteed to be stable.
        const useProviderStore = useContext(ZustandContext);
        if (!useProviderStore) {
            throw new Error('Seems like you have not used zustand provider as an ancestor.');
        }
        return useMemo(() => ({
            getState: useProviderStore.getState,
            setState: useProviderStore.setState,
            subscribe: useProviderStore.subscribe,
            destroy: useProviderStore.destroy,
        }), [useProviderStore]);
    };
    return {
        Provider,
        useStore,
        useStoreApi,
    };
}
