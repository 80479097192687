var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Cookies_consentCaches;
import { StorageConsentLevel } from '@core/storage/types';
/**
 * Cookie/LocalStorage/SessionStorage consent handling module class.
 */
export class Cookies {
    constructor() {
        _Cookies_consentCaches.set(this, {}
        // Serves as public docs reference only
        );
        // Serves as public docs reference only
        this.consentLevels = Object.values(StorageConsentLevel);
    }
    /**
     * Parse comma separated consent levels from a string
     * @param consent
     * @returns
     */
    static parseConsents(consent) {
        return consent
            .split(',')
            .map((consent) => consent.trim())
            .filter((consent) => Object.values(StorageConsentLevel).includes(consent));
    }
    /**
     * Parse comma separated consent string into an array of consents,
     * return all if empty.
     * @param consent
     * @returns
     */
    static parseConsentsOrAll(consent) {
        return consent && consent.trim().length
            ? Cookies.parseConsents(consent)
            : Object.values(StorageConsentLevel);
    }
    /**
     * Revoke previous consents and set new level of consent.
     * @param consent
     */
    setConsent(consent) {
        Object.values(__classPrivateFieldGet(this, _Cookies_consentCaches, "f")).forEach((consent) => consent?.forEach((cache) => {
            cache.revokeConsent();
            cache.populate({ sweep: true });
        }));
        Cookies.parseConsents(consent).forEach((consent) => {
            __classPrivateFieldGet(this, _Cookies_consentCaches, "f")[consent]?.forEach((cache) => {
                cache.grantConsent();
                cache.flush();
            });
        });
    }
    /**
     * Enable cookies storage. Note this is public facing API.
     */
    enableCookies(consent) {
        Cookies.parseConsentsOrAll(consent).forEach((consent) => {
            __classPrivateFieldGet(this, _Cookies_consentCaches, "f")[consent]?.forEach((cache) => {
                cache.grantConsent();
                cache.flush();
            });
        });
    }
    /**
     * Disable cookies storage. Note this is public facing API.
     */
    disableCookies(consent) {
        Cookies.parseConsentsOrAll(consent).forEach((consent) => {
            __classPrivateFieldGet(this, _Cookies_consentCaches, "f")[consent]?.forEach((cache) => {
                cache.revokeConsent();
                cache.populate({ sweep: true });
            });
        });
    }
    /**
     * Register consent cache instance for flushing.
     * @param instance
     */
    registerConsentCache(instance, consent) {
        if (!__classPrivateFieldGet(this, _Cookies_consentCaches, "f")[consent]) {
            __classPrivateFieldGet(this, _Cookies_consentCaches, "f")[consent] = new Set();
        }
        __classPrivateFieldGet(this, _Cookies_consentCaches, "f")[consent]?.add(instance);
    }
    /**
     * Testing only. List all registered consent cache instances.
     * @returns
     */
    listCacheInstances() {
        return __classPrivateFieldGet(this, _Cookies_consentCaches, "f");
    }
}
_Cookies_consentCaches = new WeakMap();
