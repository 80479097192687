/**
 * i18next `t` function implementation for lazy i18next. Handles interpolation and
 * removes namespace.
 *
 * @param tKey
 * @param tOptions
 * @returns
 */
export function tFunctionImplementation(tKey, tOptions) {
    /** Handle interpolation */
    if (tOptions && Object.values(tOptions).length) {
        Object.entries(tOptions).forEach(([interpolationKey, interpolationValue]) => {
            const regex = new RegExp(`{{${interpolationKey}}}`, 'ig');
            if (regex.test(tKey)) {
                tKey = tKey.replace(regex, interpolationValue);
            }
        });
    }
    /** Remove namespace */
    if (tKey.includes(':') && tKey.match(/^\w+:/)) {
        tKey = tKey.split(':').slice(1).join(':');
    }
    return tKey;
}
