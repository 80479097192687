import { PIP } from './pip';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'pip')) {
        Object.defineProperty(window._fwn, 'pip', {
            value: new PIP(),
            writable: false,
        });
    }
}
