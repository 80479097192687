import { VideoBuilder } from './videoBuilder';
/**
 * Video factory accepting a callback to build a Api_Video object.
 * @param callback
 * @returns
 */
export const videoFactory = (callback) => {
    const builder = new VideoBuilder();
    callback(builder);
    return builder.build();
};
