import { logError, logInfo } from '@core/logging';
import { getPIPIframe } from '@core/pictureInPicture/getPIPIframe';
import { hasPIPIframe } from '@core/pictureInPicture/hasPIPIframe';
import { postMessageWrapper } from './postMessageWrapper';
/** Remove unserializable values from object using JSON */
function filterUnserializable(obj) {
    return JSON.parse(JSON.stringify(obj));
}
/**
 * Propagates data to:
 * - window and parent window as messages.
 * - iframe and current document as custom events.
 * - widget target as custom events.
 */
export function customEvent({ target, type, data }) {
    const types = typeof type !== 'object' ? [type] : type;
    types.forEach((type) => {
        try {
            postMessageWrapper({
                ...filterUnserializable(data),
                type,
            });
            // Dispatch event from widget into iframe when PIP is active
            if (hasPIPIframe()) {
                getPIPIframe()?.document.dispatchEvent(new CustomEvent(type, {
                    detail: data,
                }));
            }
            document.dispatchEvent(new CustomEvent(type, {
                detail: data,
            }));
            (target?.host || target)?.dispatchEvent(new CustomEvent(type, {
                detail: data,
            }));
            logInfo('CustomEvent', type, data);
        }
        catch (err) {
            logError(err);
        }
    });
}
