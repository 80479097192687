import { useEffect, useMemo, useState } from 'react';
const DOCK_EVENT_NAME = 'fwn:dock:set';
const DISABLED_EVENT_NAME = 'fwn:dock:disable';
/**
 *
 */
export const useDock = (params) => {
    const [isDocked, setDocked] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    useEffect(() => {
        const onDock = (event) => {
            if (isDocked && event.detail.widget_id !== params.widget_id) {
                setDocked(false);
            }
        };
        const onDisable = () => {
            setDisabled(true);
        };
        if (!isDisabled) {
            document.addEventListener(DOCK_EVENT_NAME, onDock);
            document.addEventListener(DISABLED_EVENT_NAME, onDisable);
        }
        return () => {
            document.removeEventListener(DOCK_EVENT_NAME, onDock);
            document.removeEventListener(DISABLED_EVENT_NAME, onDisable);
        };
    }, [params.widget_id, isDocked, setDocked, isDisabled]);
    const actions = useMemo(() => ({
        dock: () => {
            const event = new CustomEvent(DOCK_EVENT_NAME, {
                detail: {
                    widget_id: params.widget_id ?? '',
                },
            });
            document.dispatchEvent(event);
            setDocked(true);
        },
        undock: () => {
            setDocked(false);
            const event = new CustomEvent(DOCK_EVENT_NAME, {
                detail: {
                    widget_id: null,
                },
            });
            document.dispatchEvent(event);
        },
        disableDock: () => {
            setDisabled(true);
            document.dispatchEvent(new CustomEvent(DISABLED_EVENT_NAME));
        },
    }), [setDocked, setDisabled, params.widget_id]);
    return [isDocked && !isDisabled, actions];
};
