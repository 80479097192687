import { useEffect, useMemo } from 'react';
import { createStore, Provider } from './store';
/**
 * Is a HOC that initializes the store specifically for EmbedFeed widget
 * and delivers it via a context provider.
 */
export const EmbedFeedStoreProvider = ({ children }) => {
    const store = useMemo(() => createStore(), []);
    useEffect(() => {
        return () => {
            store.destroy();
        };
    }, [store]);
    return <Provider store={store}>{children}</Provider>;
};
