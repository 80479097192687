import { playerRender } from '@embed/playerRender';
import { FWNElement } from './bases/FWNElement';
class PlayerElement extends FWNElement {
    constructor() {
        super(...arguments);
        this.height = '100%';
        this.width = '100%';
    }
    render() {
        const params = {
            height: this.height,
            width: this.width,
            ...this.getAttributes(),
        };
        if (this.shadowRoot) {
            playerRender({
                target: this.shadowRoot,
                widgetId: this.widgetId,
                widgetParams: params,
            });
        }
    }
}
if (!customElements.get('fw-player')) {
    customElements.define('fw-player', PlayerElement);
}
