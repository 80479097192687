/**
 * Actions for shared player state
 */
export function createPlayerActions(set) {
    return {
        playerLayoutSet: (widgetId, playerLayout) => {
            set((state) => ({
                playersLayouts: { ...state.playersLayouts, [widgetId]: playerLayout },
            }), false, 'playerLayoutSet');
        },
        playerLayoutUnset: (widgetId) => {
            set((state) => {
                const { [widgetId]: _, ...playersLayouts } = state.playersLayouts || {};
                return { playersLayouts };
            }, false, 'playerLayoutUnset');
        },
        setLivestreamJoinEventTracked: (videoId) => {
            set((state) => {
                return {
                    isLivestreamJoinEventTrackedByVideoId: {
                        ...state.isLivestreamJoinEventTrackedByVideoId,
                        [videoId]: true,
                    },
                };
            }, false, 'setLivestreamJoinEventTracked');
        },
        unsetLivestreamJoinEventTracked: (videoId) => {
            set((state) => {
                return {
                    isLivestreamJoinEventTrackedByVideoId: {
                        ...state.isLivestreamJoinEventTrackedByVideoId,
                        [videoId]: false,
                    },
                };
            }, false, 'unsetLivestreamJoinEventTracked');
        },
    };
}
