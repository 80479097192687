import { Fragment, lazy, Suspense } from 'react';
/**
 *
 * callback to import videoplayer that can be reused elsewhere to preload the script
 */
export const importVideoPlayer = () => import(
/* webpackChunkName: "videoplayer" */
'./VideoPlayer');
const Component = lazy(importVideoPlayer);
/**
 *
 */
export const VideoPlayer = (props) => (<Suspense fallback={<Fragment />}>
    <Component {...props}/>
  </Suspense>);
