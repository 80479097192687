import { devtools } from 'zustand/middleware';
/**
 * Adds Redux devtools.
 * https://github.com/pmndrs/zustand#redux-devtools
 */
export function addDevtool(creator) {
    return devtools(creator, {
        name: 'FWN Global Store',
    });
}
