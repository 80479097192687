import { allTests } from '@core/abTesting/allTests';
import { logInfo, logWarning } from '@core/logging/helpers';
import { getCookie } from '@core/storage';
import { StorageKeyPerformanceEnum } from '@core/storage/types';
import { removeCookieAndFallback } from '@core/tracking/funnelTracking';
import { setCookieWithRootDomain } from '@core/tracking/funnelTracking/cookies/setCookieWithRootDomain';
import { getGuestId } from '@core/tracking/guestId';
export const AB_TESTING_URL_PARAM = 'fwntests';
/**
 * [plg] set fw_abt cookie if not already set (confirmed with product this is acceptable for V1)
 */
export async function setABTestsCookie() {
    const { default: add } = await import('date-fns/add');
    const cookie = getCookie(StorageKeyPerformanceEnum.fw_abt);
    if (!cookie) {
        setCookieWithRootDomain(StorageKeyPerformanceEnum.fw_abt, JSON.stringify({ tests: getABTestsForTracking() }), {
            expirationDate: add(new Date(), { days: 30 }),
        });
        logInfo(`AB Tests: ${getCookie(StorageKeyPerformanceEnum.fw_abt)}`);
    }
}
/**
 * [plg] update fw_abt cookie
 */
export async function updateABTestsCookie() {
    const { default: add } = await import('date-fns/add');
    const tests = getABTestsForTracking();
    if (tests.length) {
        setCookieWithRootDomain(StorageKeyPerformanceEnum.fw_abt, JSON.stringify({ tests }), {
            expirationDate: add(new Date(), { days: 30 }),
        });
        logInfo(`AB Tests: ${getCookie(StorageKeyPerformanceEnum.fw_abt)}`);
    }
    else {
        removeCookieAndFallback(StorageKeyPerformanceEnum.fw_abt);
    }
}
/**
 *
 * @returns the given tests Map as an array of tests
 */
export function getABTestsArray(tests = allTests) {
    return Array.from(tests, ([, val]) => val);
}
/**
 * Takes a test and a guest ID, parses the test buckets and adds the test to allTests
 */
export function addTest(test, guestId = getGuestId()) {
    const totalWeight = getBucketsTotalWeight(test.buckets);
    if (totalWeight !== 100) {
        throw new Error(`The buckets of AB test ${test.id} have a combined weight of ${totalWeight}. Bucket weights must add up to 100`);
    }
    addBucketRanges(test);
    const guestIdBucketNumber = getGuestIdBucketNumber(guestId);
    const guestBucket = getGuestBucket(test, guestIdBucketNumber);
    const urlOverride = getURLBucketOverride(test.id);
    if (urlOverride) {
        const overrideBucket = getBucketById(test, urlOverride);
        if (!overrideBucket) {
            logWarning(`FWN Tests URL override "${urlOverride}" is invalid for test "${test.id}" No bucket with that ID exists`);
        }
        else {
            overrideBucket.guestBucket = true;
        }
    }
    else if (guestBucket) {
        guestBucket.guestBucket = true;
    }
    allTests.set(test.id, test);
}
/**
 * Takes a string and returns a number between 0 and 99 derived from
 * the first 8 characters of the string
 * @param guestId at least 2 characters long
 * @returns a number between 0 and 99
 */
export function getGuestIdBucketNumber(guestId) {
    // at least 8 characters long
    // a-f,0-9 characters only in the first 5 characters
    const firstCharsMatch = guestId.match(/^[a-f\d]{8}/i);
    if (!firstCharsMatch) {
        throw new Error(`Guest ID "${guestId}" is invalid`);
    }
    // 4294967295 = (16^8) - 1
    const num = Math.floor((parseInt(guestId.substring(0, 8), 16) / 4294967295) * 99);
    return num;
}
/**
 * Takes an array of AB tests and adds a min and max number to each bucket
 * based on its weight and the weights of the previous buckets
 */
export function addBucketRanges(test) {
    test.buckets.forEach((bucket, index) => {
        if (index === 0) {
            bucket.min = 0;
            bucket.max = bucket.weight - 1;
        }
        else {
            // we know max is set at this point because we're iterating
            // through the array and we already set max for the first bucket
            bucket.min = test.buckets[index - 1].max + 1;
            bucket.max = bucket.min + bucket.weight - 1;
        }
    });
}
/**
 * Takes a test and a guest ID bucket number and returns the bucket object
 */
export function getGuestBucket(test, guestIdBucketNumber = getGuestIdBucketNumber(getGuestId())) {
    const buckets = test?.buckets ?? [];
    const selectedBucket = buckets.find((bucket) => bucket.guestBucket);
    return (selectedBucket ??
        buckets.find((bucket) => {
            return (guestIdBucketNumber >= (bucket.min ?? 0) &&
                guestIdBucketNumber <= (bucket.max ?? 0));
        }));
}
/**
 * Takes a test and a bucket ID and returns the bucket object
 */
export function getBucketById(test, id) {
    return test.buckets.find((bucket) => {
        return bucket.id === id;
    });
}
/**
 * Parses any test overrides in the URL into an object with the key as the test ID and the value as the bucket ID
 */
export function parseURLOverrides() {
    const urlParam = new URL(window.location.href).searchParams.get(AB_TESTING_URL_PARAM);
    return urlParam?.split(',').reduce((obj, keyPair) => {
        const keyPairArr = keyPair.split(':');
        obj[keyPairArr[0]] = keyPairArr[1];
        return obj;
    }, {});
}
/**
 * Gets the total weight of all buckets in the given test
 */
function getBucketsTotalWeight(buckets) {
    return buckets.reduce((total, bucket) => {
        return total + bucket.weight;
    }, 0);
}
/**
 * gets the override bucket id for the given test slug
 */
export function getURLBucketOverride(testSlug) {
    return parseURLOverrides()?.[testSlug];
}
/**
 * Takes an AllTests object and returns an array of objects with the test ID and the guest bucket ID
 */
export function getABTestsForTracking(tests = allTests) {
    return getABTestsArray(tests).map((test) => {
        return {
            id: test.id,
            variant: test.buckets.find((bucket) => !!bucket.guestBucket)?.id,
        };
    });
}
