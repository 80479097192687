class VideoFileBuilder {
    constructor() {
        this.videoFile = {
            file_url: '',
            format: null,
            has_watermark: false,
            height: 0,
            video_file_type: '',
            width: 0,
            placeholder_url: null,
            placeholder_blur_hash: null,
        };
    }
    /**
     * Use `width` instead.
     * @deprecated
     * @param value
     * @returns
     */
    set_width(value) {
        return this.width(value);
    }
    /**
     * Set video width dimension.
     * @param value
     * @returns
     */
    width(value) {
        this.videoFile.width = value;
        return this;
    }
    /**
     * @deprecated Use `height` instead.
     * @param value
     * @returns
     */
    set_height(value) {
        return this.height(value);
    }
    /**
     * Set video height dimension.
     * @param value
     * @returns
     */
    height(value) {
        this.videoFile.height = value;
        return this;
    }
    /**
     * @deprecated Use `format` instead.
     * @param value
     * @returns
     */
    set_format(value) {
        return this.format(value);
    }
    /**
     *
     * @param value
     * @returns
     */
    format(value) {
        this.videoFile.format = value;
        return this;
    }
    /**
     * @deprecated Use `fileUrl` instead.
     * @param value
     * @returns
     */
    set_file_url(value) {
        return this.fileUrl(value);
    }
    /**
     * Set video file source url.
     * @param value
     * @returns
     */
    fileUrl(value) {
        this.videoFile.file_url = value;
        return this;
    }
    /**
     * Validate and build video file object.
     * @returns
     */
    build() {
        return this.videoFile;
    }
}
/**
 *
 */
export const videoFileFactory = (callback) => {
    const videoFile = new VideoFileBuilder();
    callback(videoFile);
    return videoFile.build();
};
