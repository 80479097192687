/**
 * Local storage key used to indicate browser is in "debug" mode
 *
 * Using direct access to local storage here as this is for internal
 * troubleshooting purposes only and should be dependency free.
 */
let isDebugCache;
export const DEBUG_KEY = 'fwdev_debug';
/**
 * @returns Whether the code is in debug environment.
 */
export const isDebug = () => {
    if (isDebugCache !== undefined) {
        return isDebugCache;
    }
    if (process.env.NODE_ENV === 'development') {
        return true;
    }
    try {
        // eslint-disable-next-line zeffo/no-window-storage
        isDebugCache = !!localStorage.getItem(DEBUG_KEY);
        return isDebugCache;
    }
    catch {
        return false;
    }
};
