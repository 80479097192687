import { useEffect, useState } from 'react';
import { isFalsy } from '@core/helpers/boolean';
import { createShareLinkParamsObject, getBaseUrl } from '@core/sharing/helpers';
import { buildShareLinkV2 } from '../helpers/buildShareLink';
/**
 * Creates and returns a share link based on the `params.share type`.
 * Fragment or Query will create share link v1, otherwise v0 which
 * is video.web_share_url with a utm_campaign param.
 */
export const useShareLink = ({ video, appContext, params, startTime, baseUrl, paramType, }) => {
    const [shareUrl, setShareUrl] = useState(null);
    useEffect(() => {
        const url = baseUrl || getBaseUrl({ params });
        if (isFalsy(params?.share) && !paramType) {
            setShareUrl(null);
        }
        else {
            setShareUrl(buildShareLinkV2(url, createShareLinkParamsObject(appContext, video, startTime)));
        }
    }, [appContext, params, video, startTime, baseUrl, paramType]);
    return shareUrl;
};
