import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_LOCALE_PREFIX } from './constants';
import { useTranslationImplementation } from './useTranslationImplementation';
/**
 *
 */
export function createHook(getI18NInstance, useFetchLanguage, eagerLocales = []) {
    return function (ns, options) {
        const language = useFetchLanguage();
        const [instance, setInstance] = useState(options?.i18n);
        const [postProcessFn] = useState();
        useEffect(() => {
            if (language &&
                (!language.startsWith(DEFAULT_LOCALE_PREFIX) ||
                    eagerLocales.includes(language.toLowerCase()))) {
                ;
                (async () => {
                    const instance = await getI18NInstance();
                    await instance.changeLanguage(language);
                    setInstance(instance);
                })();
            }
        }, [language]);
        // TODO(CS-2037): Enable when we have a way to toggle in dev only.
        // useEffect(() => {
        //   if (isDebug()) {
        //     ;(async () => {
        //       const { pseudoLocalize } = await import('./pseudoLocalize')
        //       setPostProcessFn(() => pseudoLocalize)
        //     })()
        //   }
        // }, [])
        if (instance && language && instance.language !== language) {
            // Keep instance in sync with language because we only use
            // single instance of i18next for all widgets on a page.
            instance.changeLanguage(language);
        }
        const optionsWithInstance = useMemo(() => ({
            ...options,
            i18n: instance,
        }), [instance, options]);
        return useTranslationImplementation(language, ns, optionsWithInstance, postProcessFn);
    };
}
