import { logError } from '@core/logging';
import { buildShareLinkSearchParams } from './buildShareLinkSearchParams';
import { cleanUrlFromShareLink } from './cleanUrlFromShareLink';
/**
 * Builds Share link v2 aka.  Universal URL with `fw_{name}` params.
 * @param url
 * @param params
 * @returns Share link or null.
 */
export function buildShareLinkV2(url, params) {
    try {
        const urlObj = new URL(cleanUrlFromShareLink(url));
        urlObj.search = buildShareLinkSearchParams(params, urlObj.searchParams).toString();
        return urlObj.toString();
    }
    catch (err) {
        logError(err);
    }
    return null;
}
