import { getGlobalConfig } from '@core/globalConfig';
import { SHOPPING_SLICE_KEY } from './slice';
/**
 *
 */
export function partialize(state) {
    // actions is needed to extract shopping state.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { actions, productsHydrated, ...shoppingState } = state[SHOPPING_SLICE_KEY];
    return {
        [SHOPPING_SLICE_KEY]: {
            ...shoppingState,
        },
    };
}
/**
 *
 */
export function merge(persistedState, currentState) {
    // We want to only persist our cart for certain enterprise client
    // such as walmart because walmart does not provide any product hydration
    // https://fwn.atlassian.net/browse/GROW-324
    if (getGlobalConfig().persist_cart) {
        return {
            [SHOPPING_SLICE_KEY]: {
                ...currentState[SHOPPING_SLICE_KEY],
                ...persistedState[SHOPPING_SLICE_KEY],
            },
        };
    }
    return {
        [SHOPPING_SLICE_KEY]: {
            ...currentState[SHOPPING_SLICE_KEY],
        },
    };
}
