import { getFWN } from '@core/fwn';
import { logWarning } from '@core/logging';
import { getConsentLevelByKey } from '@core/storage/getConsentByKey';
import { cookiesStorages } from './instances/cookiesStorages';
const consentCache = new Set();
function getInstance(key) {
    const consent = getConsentLevelByKey(key);
    const instance = cookiesStorages[consent];
    if (!consentCache.has(instance) && getFWN()?.cookies?.registerConsentCache) {
        getFWN()?.cookies.registerConsentCache(instance, consent);
        consentCache.add(instance);
    }
    return instance;
}
/**
 * Get cookie value by name.
 */
export function getCookie(key) {
    try {
        return getInstance(key).get(key);
    }
    catch (err) {
        logWarning(err);
    }
}
/**
 * Set cookie value by name.
 */
export function setCookie(key, value, opts = {}) {
    const { domain, expirationDate } = opts;
    try {
        const options = {
            domain,
            expires: expirationDate,
        };
        getInstance(key).set(key, value, options);
    }
    catch (err) {
        logWarning(err);
    }
}
/**
 * Remove cookie by name.
 */
export function removeCookie(key, opts = {}) {
    try {
        getInstance(key).remove(key, opts);
    }
    catch (err) {
        logWarning(err);
    }
}
