/** @returns IntersectionObserver. Loads the polyfill when needed. */
export const ensureIntersectionObserver = async () => {
    if (typeof window === 'undefined') {
        throw new Error('No IntersectionObserver in SSR');
    }
    if (!('IntersectionObserver' in window)) {
        await import('intersection-observer');
    }
    return window.IntersectionObserver;
};
