/**
 * @fileoverview
 *
 * WidgetStoreProvider is a HOC which makes sure the Widget component
 * gets zustand store properly initialized and store
 * attributes from props are populated.
 */
import { useEffect, useMemo } from 'react';
import { resolveStoreParams } from '@core/params';
import { createStore, initialState, Provider } from '@core/widgets/store';
import { hideInDevtool } from '@core/zustand';
/**
 *
 */
export const WidgetStoreProvider = ({ widgetParams, initialParams, children }) => {
    const initialStateSliceWithAllParams = useMemo(() => {
        hideInDevtool(initialParams.target);
        hideInDevtool(widgetParams.target);
        return resolveStoreParams(initialState, {
            initialParams,
            widgetParams,
        });
    }, [initialParams, widgetParams]);
    const store = useMemo(() => {
        const name = widgetParams.name ?? initialParams.widget_id ?? '';
        const extraState = () => initialStateSliceWithAllParams;
        return createStore(name, extraState);
    }, [
        initialParams.widget_id,
        initialStateSliceWithAllParams,
        widgetParams.name,
    ]);
    useEffect(() => {
        return () => {
            store.destroy();
        };
    }, [store]);
    return <Provider store={store}>{children}</Provider>;
};
