export const HYDRATION_SLICE_KEY = 'hydration';
/**
 *
 */
export function createHydrationSlice(set) {
    return {
        [HYDRATION_SLICE_KEY]: {
            hydratedVideos: {},
            hydrateVideo: (partialVideo) => set((previousState) => {
                if (!partialVideo.encoded_id) {
                    return previousState;
                }
                return {
                    [HYDRATION_SLICE_KEY]: {
                        ...previousState[HYDRATION_SLICE_KEY],
                        hydratedVideos: {
                            ...previousState[HYDRATION_SLICE_KEY].hydratedVideos,
                            [partialVideo.encoded_id]: partialVideo,
                        },
                    },
                };
            }),
        },
    };
}
/**
 *
 */
export const videoHydratedAction = (store, partialVideo) => {
    store.getState()[HYDRATION_SLICE_KEY].hydrateVideo(partialVideo);
};
/**
 *
 */
export const getHydratedVideosSelector = (state) => state[HYDRATION_SLICE_KEY].hydratedVideos;
