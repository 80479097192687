let pipController;
/** */
async function getOrCreatePIPController() {
    return import('./PIPController').then(({ PIPController }) => {
        return pipController || (pipController = new PIPController());
    });
}
/** */
export function enterPIP(props) {
    getOrCreatePIPController().then((controller) => controller.mount(props));
}
/** */
export function exitPIP() {
    getOrCreatePIPController().then((controller) => {
        controller.unmount();
        pipController = undefined;
    });
}
