import { customEvent, EventType } from '@core/customEvent';
/**
 *
 */
export function init(cb) {
    if (typeof window !== 'undefined' && !window._fwn) {
        window['_fwn'] = {};
    }
    window._fwn.version = process.env.RELEASE_VERSION ?? 'unknown';
    cb();
    customEvent({
        target: null,
        type: EventType.FW_READY,
        data: {},
    });
}
