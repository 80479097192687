export const FEED_CREATE_EMBED_INSTANCE = {
    url: '/trk/feed:create_embed_instance',
    consent: 'essential',
    isUserEngagement: false,
};
export const FEED_CREATE_FEED_IMPRESSION = {
    url: '/trk/feed:create_feed_impression',
    consent: 'essential',
    isUserEngagement: false,
};
export const FEED_CREATE_THUMBNAIL_IMPRESSION = {
    url: '/trk/feed:create_thumbnail_impression',
    consent: 'essential',
    isUserEngagement: false,
};
export const FEED_SCROLL_END_VIDEOS = {
    url: '/trk/feed:scroll_end_videos',
    consent: 'essential',
    isUserEngagement: true,
};
export const FEED_SCROLL_VIDEOS = {
    url: '/trk/feed:scroll_videos',
    consent: 'essential',
    isUserEngagement: true,
};
export const FEED_CLICK_VIDEO = {
    url: '/trk/feed:click_video',
    consent: 'essential',
    isUserEngagement: false,
};
export const SELECT_TARGET_CALENDAR = {
    url: '/trk/livestream:select_target_calendar',
    consent: 'essential',
    isUserEngagement: true,
};
export const FEED_CONTROL_GROUP_IMPRESSION = {
    url: '/trk/feed:control_group_feed_impression',
    consent: 'essential',
    isUserEngagement: true,
};
