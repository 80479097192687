function isTruthy(value) {
    return !!value;
}
/**
 * `filterTruthy` is a typed version of `[].filter(Boolean)
 *
 * @param list list of truthy and `Falsey` items
 * @returns a new list with `Falsey` values removed
 */
export const filterTruthy = (list) => list.filter(isTruthy);
