import { useMemo } from 'react';
import { ABTestID } from '@core/abTesting';
import { getCookie } from '@core/storage';
import { StorageKeyPerformanceEnum } from '@core/storage/types';
/**
 * Returns boolean representing whether visitor is in PLG control group, based on cookie
 * Cookie contains a value shaped like { _business_id: string, tests: TrackingTest[] }
 */
export const useIsPlgControlGroup = () => {
    return useMemo(() => {
        const cookie = getCookie(StorageKeyPerformanceEnum.fw_abt);
        if (cookie) {
            try {
                const parsed = JSON.parse(cookie);
                const test = parsed?.tests.find((test) => test.id === ABTestID.PLG);
                return test?.variant === 'invisible';
            }
            catch { }
        }
        return false;
    }, []);
};
