export var AppContextType;
(function (AppContextType) {
    // Widget contexts
    AppContextType["EMBED_ADD_TO_CALENDAR_CONTEXT_TYPE"] = "embed_add_to_calendar";
    AppContextType["EMBED_BUTTON_WRAPPER"] = "embed_button_wrapper";
    AppContextType["EMBED_CONTEXT_TYPE"] = "embed_player";
    AppContextType["EMBED_GRID_CONTEXT_TYPE"] = "embed_grid";
    AppContextType["EMBED_HERO_CONTEXT_TYPE"] = "embed_hero";
    AppContextType["EMBED_PLAYER_TYPE"] = "embed_player_widget";
    AppContextType["EMBED_SHARE_CONTEXT_TYPE"] = "embed_share";
    AppContextType["EMBED_STORYBLOCK_CONTEXT_TYPE"] = "embed_storyblock";
    AppContextType["EMBED_STORYLINK_CONTEXT_TYPE"] = "embed_storylink";
    AppContextType["EMBED_SHOWROOM_SCHEDULE_CONTEXT_TYPE"] = "embed_showroom_schedule";
    // fw.tv contexts (will be deprecated)
    AppContextType["VIDEO_DETAIL_CONTEXT_TYPE"] = "video_detail_player";
    AppContextType["CHANNEL_CONTEXT_TYPE"] = "channel_player";
    // Headless UI
    AppContextType["HEADLESS_FEED"] = "headless_feed";
})(AppContextType || (AppContextType = {}));
export var Context;
(function (Context) {
    Context["CONTEXT_CHANNEL"] = "channel";
    Context["CONTEXT_HASHTAG"] = "hashtag";
    Context["CONTEXT_PLAYLIST"] = "playlist";
    Context["CONTEXT_PLAYLIST_COLLECTION"] = "playlist_collection";
    Context["CONTEXT_VIDEO_DETAIL"] = "video_detail";
    Context["CONTEXT_SKUS"] = "skus";
    Context["CONTEXT_SHOWROOM_SCHEDULE"] = "showroom_schedule";
})(Context || (Context = {}));
export const DESKTOP_MEDIAQUERY = 'min-width: 1024px'; // 690px // 376px
export const TABLET_MEDIAQUERY = 'min-width: 690px'; // 690px // 376px
export { resizeThumbnailUrl } from './resizeThumbnailUrl';
