export var WidgetTypesEnum;
(function (WidgetTypesEnum) {
    WidgetTypesEnum["BUTTON_WRAPPER"] = "button_wrapper";
    WidgetTypesEnum["EMBED_FEED"] = "embed_feed";
    WidgetTypesEnum["STORYBLOCK"] = "storyblock";
    WidgetTypesEnum["STORYLINK"] = "storylink";
    WidgetTypesEnum["HEROUNIT"] = "herounit";
    WidgetTypesEnum["CHANNEL_BUTTON"] = "channel_button";
    WidgetTypesEnum["ADD_TO_CALENDAR"] = "add_to_calendar";
    WidgetTypesEnum["SHARE"] = "share";
    WidgetTypesEnum["PROGRAMMATIC"] = "programmatic";
    WidgetTypesEnum["SHOWROOM_SCHEDULE"] = "showroom_schedule";
    WidgetTypesEnum["PLAYER"] = "player";
})(WidgetTypesEnum || (WidgetTypesEnum = {}));
export var PlayerPlacementEnum;
(function (PlayerPlacementEnum) {
    PlayerPlacementEnum["TOP_LEFT"] = "top-left";
    PlayerPlacementEnum["TOP_RIGHT"] = "top-right";
    PlayerPlacementEnum["BOTTOM_LEFT"] = "bottom-left";
    PlayerPlacementEnum["BOTTOM_RIGHT"] = "bottom-right";
})(PlayerPlacementEnum || (PlayerPlacementEnum = {}));
export var PlayerTitleAlignmentEnum;
(function (PlayerTitleAlignmentEnum) {
    PlayerTitleAlignmentEnum["LEFT"] = "left";
    PlayerTitleAlignmentEnum["RIGHT"] = "right";
    PlayerTitleAlignmentEnum["CENTER"] = "center";
})(PlayerTitleAlignmentEnum || (PlayerTitleAlignmentEnum = {}));
export var ResetTransformEnum;
(function (ResetTransformEnum) {
    ResetTransformEnum["ALWAYS"] = "always";
    ResetTransformEnum["FULLSCREEN"] = "fullscreen";
    ResetTransformEnum["NEVER"] = "never";
})(ResetTransformEnum || (ResetTransformEnum = {}));
export var OpenInEnum;
(function (OpenInEnum) {
    OpenInEnum["DEFAULT"] = "default";
    OpenInEnum["BLANK"] = "_blank";
    OpenInEnum["SELF"] = "_self";
    OpenInEnum["MODAL"] = "_modal";
    OpenInEnum["IFRAME"] = "_iframe";
    OpenInEnum["FALSE"] = "false";
})(OpenInEnum || (OpenInEnum = {}));
export var ShareEnum;
(function (ShareEnum) {
    ShareEnum["HASH"] = "hash";
    ShareEnum["QUERY"] = "query";
    ShareEnum["FALSE"] = "false";
})(ShareEnum || (ShareEnum = {}));
export var ShoppingCartStyle;
(function (ShoppingCartStyle) {
    ShoppingCartStyle["BAG"] = "bag";
    ShoppingCartStyle["CART"] = "cart";
})(ShoppingCartStyle || (ShoppingCartStyle = {}));
export var UsernamePromptBehaviourEnum;
(function (UsernamePromptBehaviourEnum) {
    UsernamePromptBehaviourEnum["OFF"] = "off";
    UsernamePromptBehaviourEnum["CHAT"] = "chat";
    UsernamePromptBehaviourEnum["ACTIVE"] = "active";
    UsernamePromptBehaviourEnum["TRUE"] = "true";
})(UsernamePromptBehaviourEnum || (UsernamePromptBehaviourEnum = {}));
export var WidgetSizeEnum;
(function (WidgetSizeEnum) {
    WidgetSizeEnum["MEDIUM"] = "medium";
    WidgetSizeEnum["LARGE"] = "large";
    WidgetSizeEnum["SMALL"] = "small";
})(WidgetSizeEnum || (WidgetSizeEnum = {}));
export var ProductTagPlacement;
(function (ProductTagPlacement) {
    ProductTagPlacement["TOP"] = "top";
    ProductTagPlacement["BOTTOM"] = "bottom";
    ProductTagPlacement["FALSE"] = "false";
})(ProductTagPlacement || (ProductTagPlacement = {}));
/**
 *
 */
export const areStoryblockParams = (params) => params?.type === WidgetTypesEnum.STORYBLOCK;
/**
 *
 */
export const arePlayerParams = (params) => params?.type === WidgetTypesEnum.PLAYER;
