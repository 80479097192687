import { lens } from '@dhmk/zustand-lens';
import { createDeviceSettingsActions } from './actions';
import { createDeviceSettingsState } from './state';
export const DEVICE_SETTINGS_SLICE_KEY = 'deviceSettings';
/**
 *
 */
export function createDeviceSettingsSlice() {
    return {
        [DEVICE_SETTINGS_SLICE_KEY]: lens((set) => ({
            ...createDeviceSettingsState(),
            actions: createDeviceSettingsActions(set),
        })),
    };
}
