import { customEvent } from '@core/customEvent';
import { getGuestId } from '@core/tracking';
export { EventType } from '@core/customEvent';
/**
 *
 */
export const customEmbedEvent = async ({ type, params, data = {}, videoElement, }) => {
    if (!params) {
        return;
    }
    const { channel, hashtag, name, playlist, target, widget_id } = params;
    const guest_id = await getGuestId();
    // TODO better way of sanitizing
    // if fields need their own sanitization functions, this could become an array of objects
    // with a field for the key and an arrow function to sanitize
    if (data?.video?.encoded_id) {
        const partialFields = [
            'action_url',
            'action_type',
            'badge',
            'caption',
            'encoded_id',
            'hashtags',
            'live_stream_status',
            'video_type',
        ];
        const video = partialFields.reduce((previousValue, currentValue) => ({
            ...previousValue,
            [currentValue]: data.video?.[currentValue],
        }), {});
        data['video'] = video;
    }
    if (videoElement) {
        data['extra'] = {
            ...(data['extra'] || {}),
            duration: videoElement.duration,
            height: videoElement.height,
            progress: videoElement.currentTime,
            width: videoElement.width,
        };
    }
    customEvent({
        target,
        type,
        data: {
            channel,
            guest_id,
            hashtag,
            name,
            playlist,
            widget_id,
            ...data,
        },
    });
};
