/**
 *
 */
export class PlayerNoop {
    /**
     * Provide read only access to video object
     * which is undefined for noop player instance.
     */
    get video() {
        return undefined;
    }
    /**
     *
     */
    set currentTime(_currentTime) { }
    /**
     *
     */
    get currentTime() {
        return 0;
    }
    /**
     *
     */
    set muted(_value) { }
    /**
     *
     */
    get muted() {
        return false;
    }
    /**
     *
     */
    mute() { }
    /**
     *
     */
    unmute() { }
    /**
     *
     */
    close() { }
    /**
     *
     */
    minimize() { }
    /**
     *
     */
    fullscreen() { }
    /**
     *
     */
    play() { }
    /**
     *
     */
    pause() { }
}
