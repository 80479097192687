import { SLICE_KEY } from './slice';
/**
 * Partialize channels state for local storage
 */
export function partialize(state) {
    return {
        [SLICE_KEY]: {
            byId: state[SLICE_KEY].byId,
        },
    };
}
/**
 * Merges the persisted data with the current state
 */
export function merge(persistedState, currentState) {
    return {
        [SLICE_KEY]: {
            ...currentState[SLICE_KEY],
            ...persistedState[SLICE_KEY],
        },
    };
}
