import merge from '@core/lodash/merge';
import { ProductImageBuilder } from './productImageBuilder';
import { generateId } from './utils';
/**
 *
 */
export class ProductUnitBuilder {
    /**
     *
     */
    constructor(verifyRequiredFields = false) {
        this.verifyRequiredFields = verifyRequiredFields;
        this.images = [];
        this.unit = {
            id: generateId(),
            available: true,
            image_ext_id: '',
            product_ext_id: '',
            image_id: '',
            product_id: '',
            unit_ext_id: '',
            unit_id: generateId(),
            unit_name: '',
            unit_options: [],
            unit_original_price: 0,
            unit_original_price_string: '',
            unit_position: 0,
            unit_price: 0,
            unit_price_string: '',
            unit_url: '',
        };
    }
    /**
     *
     */
    getImages() {
        return this.images;
    }
    /**
     * @deprecated Use `extId()` instead.
     */
    ext_id(value) {
        // eslint-disable-next-line no-console
        console.warn('`ext_id()` is deprecated. Use `extId()` instead.');
        return this.extId(value);
    }
    /**
     *
     */
    extId(value) {
        this.unit.unit_ext_id = String(value);
        return this;
    }
    /**
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sku(value) {
        // TODO: Verify where SKU is stored?
        return this;
    }
    /**
     *
     */
    name(value) {
        this.unit.unit_name = String(value);
        return this;
    }
    /**
     *
     */
    url(value) {
        this.unit.unit_url =
            typeof value === 'string' ? value.replace(/^\/\//, 'https://') : value;
        return this;
    }
    /**
     *
     */
    price(value) {
        this.unit.unit_price = Number(value) || 0;
        this.unit.unit_price_string = String(value);
        return this;
    }
    /**
     *
     */
    originalPrice(value) {
        this.unit.unit_original_price = Number(value) || 0;
        this.unit.unit_original_price_string = String(value);
        return this;
    }
    /**
     *
     */
    isAvailable(value) {
        this.unit.available = !!value;
        return this;
    }
    /**
     *
     */
    image(callback) {
        const imageBuilder = new ProductImageBuilder();
        callback(imageBuilder);
        const image = imageBuilder.build();
        if (imageBuilder._primary) {
            this.primaryImageId = image.image_id;
            this.primaryImageExtId = image.image_ext_id;
        }
        // Backfill unit image id and ext id. Product unit doesn't hold images
        // directly. The builder puts the images in its a cache and to be merged
        // with product images in the ProductBuilder.
        image.unit_ids = [this.unit.unit_id];
        image.unit_ext_ids = [this.unit.unit_ext_id];
        image.image_alt_text ?? (image.image_alt_text = this.unit.unit_name);
        this.unit.image_id = image.image_id;
        this.unit.image_ext_id = image.image_ext_id;
        this.images.push(image);
        return this;
    }
    /**
     *
     */
    meta(value) {
        this.unit._meta = merge(this.unit._meta || {}, value);
        return this;
    }
    /**
     *
     */
    option({ name, value }) {
        this.unit.unit_options = [
            {
                ...this.unit.unit_options[0],
                [name]: value,
            },
        ];
        return this;
    }
    /**
     * Assigns a position to the unit variant.
     * @param value
     * @returns
     */
    position(value) {
        this.unit.unit_position = value;
        return this;
    }
    /**
     *
     */
    build() {
        this.validate();
        if (this.primaryImageId) {
            this.unit.image_id = this.primaryImageId;
            this.unit.image_ext_id = this.primaryImageExtId;
        }
        return this.unit;
    }
    /**
     *
     */
    validate() {
        const missingFields = [];
        if (!this.unit.unit_ext_id) {
            missingFields.push('extId');
        }
        if (this.verifyRequiredFields) {
            if (!this.unit.unit_url) {
                missingFields.push('url');
            }
            if (!this.unit.unit_price) {
                missingFields.push('price');
            }
        }
        if (missingFields.length > 0) {
            // Needed to show warnings to the external developer.
            // eslint-disable-next-line no-console
            console.warn(`FW Product Builder: Product variant is missing required fields: ${missingFields.join(', ')}`);
        }
    }
}
