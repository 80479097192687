/**
 *
 */
export const ensureResizeObserver = async () => {
    if (typeof window === 'undefined') {
        throw new Error('No ResizeObserver in SSR');
    }
    if (!('ResizeObserver' in window)) {
        const module = await import('@juggle/resize-observer');
        window.ResizeObserver = module.ResizeObserver;
    }
    return window.ResizeObserver;
};
