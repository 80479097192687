import { devtools } from 'zustand/middleware';
/**
 * Adds Redux devtools.
 * https://github.com/pmndrs/zustand#redux-devtools
 */
export function addDevtool(creator, name) {
    return devtools(creator, {
        name: `FWN Widget (${name})`,
    });
}
