import { SOFT_PIP_PLAYER_STATE_KEY } from '@core/pictureInPicture/constants';
import { useSessionStorage } from '@core/storage/hooks';
/**
 * Get stored Soft PIP player state from session storage.
 * @returns State
 */
export const useFetchSoftPIPPlayerState = () => {
    const [softPipPlayerState] = useSessionStorage(SOFT_PIP_PLAYER_STATE_KEY, undefined);
    return softPipPlayerState;
};
