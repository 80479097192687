import typeOf from 'just-typeof';
/**
 * Test for boolean type value.
 * @param value
 * @returns
 */
function isBoolean(value) {
    return typeOf(value) === 'boolean';
}
export default isBoolean;
