/**
 * Generates current time and localized current time
 * for tracking purpose
 */
export const nowAndNowLocalToString = () => {
    const now = new Date();
    const nowLocal = new Date(now.valueOf() - now.getTimezoneOffset() * 60 * 1000);
    return {
        now: now.toISOString().replace('Z', ''),
        nowLocal: nowLocal.toISOString().replace('Z', ''),
    };
};
