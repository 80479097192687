import { embedFeedRender } from '@embed/embedFeedRender';
import { FWNElement } from './bases/FWNElement';
class GridElement extends FWNElement {
    render() {
        if (this.shadowRoot) {
            embedFeedRender({
                mode: 'grid',
                widgetParams: this.getAttributes(),
                widgetId: this.widgetId,
                target: this.shadowRoot,
            });
        }
    }
}
class CarouselElement extends FWNElement {
    render() {
        if (this.shadowRoot) {
            embedFeedRender({
                mode: 'row',
                widgetParams: this.getAttributes(),
                widgetId: this.widgetId,
                target: this.shadowRoot,
            });
        }
    }
}
class EmbedFeedElement extends FWNElement {
    render() {
        if (this.shadowRoot) {
            embedFeedRender({
                mode: 'row',
                widgetParams: this.getAttributes(),
                widgetId: this.widgetId,
                target: this.shadowRoot,
            });
        }
        // import('@embed/embedFeedRender').then(({ embedFeedRender }) => {
        //   if (this.shadowRoot) {
        //     embedFeedRender({
        //       widgetParams: this.getAttributes(),
        //       widgetId: this.widgetId,
        //       target: this.shadowRoot,
        //     })
        //   }
        // })
    }
}
if (!customElements.get('fw-embed-feed')) {
    customElements.define('fw-embed-feed', EmbedFeedElement);
}
if (!customElements.get('fw-carousel')) {
    customElements.define('fw-carousel', CarouselElement);
}
if (!customElements.get('fw-grid')) {
    customElements.define('fw-grid', GridElement);
}
