import { logError, logInfo } from '@core/logging';
/**
 * Test for performance API availability
 */
const envWithPerformance = typeof window !== 'undefined' &&
    typeof window.performance !== 'undefined' &&
    'measure' in window.performance &&
    'mark' in window.performance;
/**
 * Round and pad number so its easy to read
 * @param num
 * @returns
 */
function roundAndPadNumber(num) {
    const decimals = 3;
    const divider = Math.pow(10, decimals);
    const seconds = Math.round(num) / divider;
    const split = `${seconds}`.split('.');
    return `${split[0]}.${(split[1] || '').padEnd(decimals, '0')}`;
}
/**
 * Format performance entry to a string
 * @param entry
 * @returns
 */
function formatMeasures(entry) {
    if (entry.startTime) {
        return (`${entry.name}` +
            ` at ${roundAndPadNumber(entry.startTime)}s` +
            ` took ${roundAndPadNumber(entry.duration)}s`);
    }
    else {
        return `${entry.name}` + ` at ${roundAndPadNumber(entry.duration)}s`;
    }
}
/**
 * Object wrapping window.performance and prefixing all entries with a key
 */
export class FWNPerformance {
    /**
     *
     */
    constructor({ key }) {
        this.prefix = (name) => `${this.key}/${name}`;
        this.mark = (name) => {
            try {
                if (envWithPerformance) {
                    window.performance.mark(this.prefix(name));
                }
            }
            catch (err) {
                logError(err);
            }
        };
        this.measure = (name, startMark, endMark) => {
            try {
                if (!envWithPerformance) {
                    return;
                }
                const prefixedName = this.prefix(name);
                if (endMark && startMark) {
                    window.performance.measure(prefixedName, this.prefix(startMark), this.prefix(endMark));
                }
                else if (startMark) {
                    window.performance.measure(prefixedName, this.prefix(startMark));
                }
                else if (endMark) {
                    window.performance.measure(prefixedName, undefined, this.prefix(endMark));
                }
                else {
                    window.performance.measure(prefixedName);
                }
                window.performance
                    .getEntriesByName(prefixedName, 'measure')
                    .forEach((entry) => {
                    window?._fwn?.console?.instance?.log({
                        entry: formatMeasures(entry),
                        group: this.key,
                    });
                });
            }
            catch (err) {
                // (CS-3314) Silencing Sentry issue where some browsers (Firefox on Android) reports missing
                // performance.measure mark. Sample mark: `embed_feed:86e98115/channel:dailymailus/playlist:v60zLo/VIDEOS_STARTED`
                logInfo(err);
            }
        };
        this.key = key;
    }
}
