import { useEffect } from 'react';
import { useApi } from '@core/api/hooks/useApi';
import { customEvent, EventType } from '@core/customEvent';
import { useGlobalStore } from '@core/hooks/useGlobalStore';
import { logWarning } from '@core/logging';
import { channelActionsSelector, channelByIdSelector, channelByUsernameSelector, } from '../model';
const requests = new Set();
/**
 * Resolve channel by providing username or encoded id. Fetches channel from API if needed, then stores it in
 * globalStore for shared access between widgets. Prevents multiple API requests for the same channel.
 * @param props
 */
export function useChannel(props) {
    const { username, encodedId, target } = props;
    const api = useApi();
    const actions = useGlobalStore(channelActionsSelector);
    const channel = useGlobalStore(encodedId
        ? channelByIdSelector(encodedId)
        : username
            ? channelByUsernameSelector(username)
            : () => null);
    useEffect(() => {
        if (!channel) {
            ;
            (async () => {
                try {
                    if (!username && !encodedId) {
                        throw new Error('Missing channel username or encodedId');
                    }
                    if (username) {
                        if (!requests.has(username)) {
                            api
                                .getChannel(username)
                                .then((result) => {
                                actions?.updateChannel(result.channel);
                            })
                                .catch((e) => {
                                logWarning(`Fetching channel ${username} failed`, e);
                            });
                            requests.add(username);
                        }
                    }
                    else if (encodedId) {
                        if (!requests.has(encodedId)) {
                            api
                                .getChannelById(encodedId)
                                .then((result) => {
                                actions?.updateChannel(result);
                            })
                                .catch((e) => {
                                logWarning(`Fetching channel ${encodedId} failed`, e);
                            });
                            requests.add(encodedId);
                        }
                    }
                }
                catch (e) {
                    customEvent({
                        target,
                        type: [EventType.FW_ERROR, EventType.FW_EMBEDFEED_ERROR],
                        data: {
                            detail: 'error',
                        },
                    });
                }
            })();
        }
    }, [channel, actions, username, encodedId, api, target]);
    return channel || null;
}
export const TEST_ONLY = {
    reset() {
        requests.clear();
    },
};
