import { useEffect } from 'react';
import { setABTestsCookie, updateABTestsCookie } from '@core/abTesting';
import { addPlgTest } from '@core/abTesting/addPlgTest';
import { useSubscriptionFlags } from '@embed/hooks/subscriptionFlags/useSubscriptionFlags';
/**
 * Check subscription flags for 50_percent_traffic_widget_display
 * and handle any corresponding cookies
 */
export const use50PercentTraffic = (channel) => {
    const subscriptionFlags = useSubscriptionFlags(channel);
    useEffect(() => {
        if (channel) {
            if (subscriptionFlags['50_percent_traffic_widget_display']) {
                addPlgTest();
                setABTestsCookie();
            }
            else {
                updateABTestsCookie();
            }
        }
    }, [channel, subscriptionFlags]);
};
