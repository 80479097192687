var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _CookiesCache_itemsToReplay, _CookiesCache_itemsValues;
import Cookies from 'js-cookie';
import { Cache } from './Cache';
/**
 * Cookie cache module class. Stores cookie request until
 * consent is given.
 */
export class CookiesCache extends Cache {
    /**
     * Constructor by the way.
     */
    constructor(keys) {
        super(keys);
        _CookiesCache_itemsToReplay.set(this, void 0);
        _CookiesCache_itemsValues.set(this, void 0);
        __classPrivateFieldSet(this, _CookiesCache_itemsToReplay, [], "f");
        __classPrivateFieldSet(this, _CookiesCache_itemsValues, new Map(), "f");
        this.populate();
    }
    /**
     * Add item into cache storage.
     * @param item
     */
    set(...args) {
        if (this.isConsentGiven()) {
            Cookies.set(...args);
        }
        else {
            __classPrivateFieldGet(this, _CookiesCache_itemsToReplay, "f").push(['set', args]);
            __classPrivateFieldGet(this, _CookiesCache_itemsValues, "f").set(args[0], args[1]);
        }
    }
    /**
     * Get value of cached item.
     * @param args
     * @returns
     */
    get(name) {
        if (this.isConsentGiven()) {
            return Cookies.get(name);
        }
        else {
            return __classPrivateFieldGet(this, _CookiesCache_itemsValues, "f").get(name);
        }
    }
    /**
     * Remove and item from cache storage.
     * @param name
     */
    remove(...args) {
        if (this.isConsentGiven()) {
            Cookies.remove(...args);
        }
        else {
            __classPrivateFieldGet(this, _CookiesCache_itemsValues, "f").delete(args[0]);
            __classPrivateFieldGet(this, _CookiesCache_itemsToReplay, "f").push(['remove', args]);
        }
    }
    /**
     * Populate the cache with the current browser cookies/storage. Sweeps
     * the storage if needed.
     */
    populate(opts = {}) {
        Object.entries(Cookies.get() || {})
            .filter(([key]) => this.validateKey(key))
            .forEach(([key, value]) => {
            __classPrivateFieldGet(this, _CookiesCache_itemsValues, "f").set(key, value);
            if (opts.sweep) {
                try {
                    Cookies.remove(key, { domain: location.host });
                }
                catch (e) {
                    // ignore
                }
            }
        });
    }
    /**
     * Flush the cached items to the browser cookies/storage.
     */
    flush() {
        __classPrivateFieldGet(this, _CookiesCache_itemsToReplay, "f").forEach(([fnName, fnArgs]) => {
            switch (fnName) {
                case 'set':
                    Cookies.set(...fnArgs);
                    break;
                case 'remove':
                    Cookies.remove(...fnArgs);
                    break;
            }
        });
        __classPrivateFieldSet(this, _CookiesCache_itemsToReplay, [], "f");
    }
    /**
     * Clear the cache for testing purposes.
     */
    clearForTest() {
        __classPrivateFieldSet(this, _CookiesCache_itemsToReplay, [], "f");
        __classPrivateFieldGet(this, _CookiesCache_itemsValues, "f").clear();
    }
}
_CookiesCache_itemsToReplay = new WeakMap(), _CookiesCache_itemsValues = new WeakMap();
