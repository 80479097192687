/**
 * Global state for live stream
 */
export function createLiveStreamState() {
    return {
        heartbeatInterval: undefined,
        joinedTopics: [],
        dismissedInvitations: {},
        invitations: {},
        username: { name: '', isUserDefined: false },
        activeWatches: {},
        liveStreamGiveaways: {},
    };
}
