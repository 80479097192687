/**
 * List of hero unit variables.
 */
export const VARIABLES = [
    ['--hero-unit-countdown-text-transform', { external: true }],
    ['--hero-unit-countdown-letter-spacing', { external: true }],
    ['--hero-unit-countdown-font-size', { external: true }],
    ['--hero-unit-countdown-font-weight', { external: true }],
    ['--hero-unit-title-font-family', { external: true }],
    ['--hero-unit-title-font-size', { external: true }],
    ['--hero-unit-title-font-weight', { external: true }],
    ['--hero-unit-title-line-height', { external: true }],
    ['--hero-unit-title-flex', { external: true }],
    ['--hero-unit-title-text-transform', { external: true }],
    ['--hero-unit-title-letter-spacing', { external: true }],
    ['--hero-unit-content-text-transform', { external: true }],
    ['--hero-unit-content-letter-spacing', { external: true }],
    ['--hero-unit-content-font-weight', { external: true }],
    ['--hero-unit-cta-text-transform', { external: true }],
    ['--hero-unit-cta-letter-spacing', { external: true }],
    ['--hero-unit-cta-font-weight', { external: true }],
    ['--hero-unit-cta-font-size', { external: true }],
    ['--hero-unit-start-time-font-size', { external: true }],
    ['--hero-unit-start-time-font-weight', { external: true }],
    ['--hero-unit-start-time-letter-spacing', { external: true }],
    ['--hero-unit-start-time-text-transform', { external: true }],
    ['--hero-unit-start-time-line-height', { external: true }],
];
