import { fwnPlayerGetter, fwnPlayerSetter } from './player';
import { fwnPlayersGetter } from './players';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'player')) {
        Object.defineProperty(window._fwn, 'player', {
            get: fwnPlayerGetter,
            set: fwnPlayerSetter,
        });
    }
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'players')) {
        Object.defineProperty(window._fwn, 'players', {
            get: fwnPlayersGetter,
        });
    }
}
