import { uuidv4 } from '@embed/helpers';
/**
 * Shared global state
 */
export function createSharedGlobalState(set) {
    return {
        actions: {
            setPersistedData: (key, value) => {
                set((state) => {
                    return {
                        ...state,
                        persistedData: {
                            ...state.persistedData,
                            [key]: value,
                        },
                    };
                });
            },
        },
        pageLoadId: uuidv4(),
        persistedData: {},
    };
}
