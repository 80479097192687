import { ABTestID } from '@core/abTesting/types';
import { addTest } from './abTesting';
/**
 * bucket users in PLG test
 */
export function addPlgTest() {
    addTest({
        id: ABTestID.PLG,
        buckets: [
            {
                id: 'invisible',
                weight: 50,
            },
            {
                id: 'visible',
                weight: 50,
            },
        ],
    });
}
