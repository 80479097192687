import { applyFocusVisible, disableFastClick } from '@core/accessibility';
import { logInfo } from '@core/logging';
import { FW_PLAYER_ID } from '@embed/constants';
import { BaseElement } from './BaseElement';
import { MutationMixin } from './MutationMixin';
import { ParamsMixin } from './ParamsMixin';
/**
 * Base class for all FWN web components. Applies observer to rerender
 * when widget params change.
 */
export class FWNElement extends MutationMixin(ParamsMixin(BaseElement)) {
    /**
     * Invoked when the component is added to the document's DOM.
     */
    connectedCallback() {
        super.connectedCallback();
        if (this.shadowRoot) {
            applyFocusVisible(this.shadowRoot);
            disableFastClick(this);
        }
    }
    /**
     * Invoked when the component is removed from the document's DOM.
     */
    disconnectedCallback() {
        super.disconnectedCallback();
        // If there is a fw-player element on the page, we do not unmount the
        // component immediately. For SPA, the fw-player needs to be kept to
        // continue playback in the floating player.
        import('@assets/webcomponents/bases/fwnRootCache').then(({ cacheRoot, unmountComponent, unmountComponentsInCache }) => {
            if (this.shadowRoot) {
                if (document.getElementById(FW_PLAYER_ID)) {
                    logInfo(`fw-player element found, cache root ${this.widgetId}`);
                    cacheRoot(this.shadowRoot, this.widgetId);
                }
                else {
                    logInfo(`Unmounting current component ${this.widgetId}`);
                    unmountComponent(this.shadowRoot, this.widgetId);
                    // After unmounting the current component, we need to clean up all the
                    // cached roots to prevent memory leak.
                    unmountComponentsInCache();
                }
            }
        });
    }
}
