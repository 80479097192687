import { PIP_STANDARD_LONG_LENGTH, PIP_STANDARD_SHORT_LENGTH, } from '@embed/components/VideoPlayer/constants';
export const PIP_HEIGHT_WIDTH_DIFFERENCE = PIP_STANDARD_LONG_LENGTH - PIP_STANDARD_SHORT_LENGTH;
/**
 * Helper function to resolve the new translate values. This is only required if
 * current video is horizontal and previous video (used for the initial
 * translate values) was vertical, or vice versa. This is because the center of
 * PIP changes when the orientation changes. This constraint is handled when it
 * is used.
 */
export const resolveMinimizedPlayerTranslate = ({ minimizedPlayerState, initialPlayerPlacement, }) => {
    if (!minimizedPlayerState) {
        return {
            newXValue: 0,
            newYValue: 0,
        };
    }
    let { x: newXValue, y: newYValue, playerPlacement, storedHorizontal, } = minimizedPlayerState;
    // we start with the x and y values as 0 because if prev video is horizontal
    // and new video is horizontal, we can just use the translate values as is
    let xSign = 0;
    let ySign = 0;
    if (playerPlacement !== initialPlayerPlacement) {
        // we check if the corner is adjacent by checking for if there is a shared
        // side (top, bottom, left, right). If there is a shared side, we know that
        // the corner is adjacent, and therefore we only need to translate in one
        // direction.
        if (playerPlacement?.includes('right') &&
            initialPlayerPlacement?.includes('right')) {
            ySign = initialPlayerPlacement?.includes('bottom') ? 1 : -1;
        }
        if (playerPlacement?.includes('bottom') &&
            initialPlayerPlacement?.includes('bottom')) {
            xSign = initialPlayerPlacement?.includes('right') ? 1 : -1;
        }
        if (playerPlacement?.includes('left') &&
            initialPlayerPlacement?.includes('left')) {
            ySign = initialPlayerPlacement?.includes('bottom') ? 1 : -1;
        }
        if (playerPlacement?.includes('top') &&
            initialPlayerPlacement?.includes('top')) {
            xSign = initialPlayerPlacement?.includes('right') ? 1 : -1;
        }
        // We then check for check for opposite corners by making sure both parts of
        // playerPlacement and initialPlayerPlacement are different. In this case, we will
        // need to update both x and y translate values
        const quadrantSplit = playerPlacement?.split('-');
        const initialSplit = initialPlayerPlacement?.split('-');
        if (quadrantSplit?.[0] !== initialSplit[0] &&
            quadrantSplit?.[1] !== initialSplit[1]) {
            if (initialSplit?.[0] === 'bottom') {
                xSign = initialSplit?.[1] === 'right' ? 1 : -1;
                ySign = 1;
            }
            else if (initialSplit?.[0] === 'top') {
                xSign = initialSplit?.[1] === 'right' ? 1 : -1;
                ySign = -1;
            }
        }
    }
    // Now, we update the x and y values based on the signs we calculated above.
    // NOTE: the positive direction is always to the right and down, because it is
    // based from the top-left corner of the container. For example, let's say
    // that  playerPlacement is top-left (meaning that's the quadrant PIP is in), and
    // initialPlayerPlacement is bottom-right. If the new video is horizontal and the
    // previous video was vertical, we need to add the difference in height and
    // width to the x value (to move PIP to the right, and back into frame) and
    // subtract the difference in height and width to the y value (to move PIP up,
    // to the same level as vertical video was previously).
    newXValue = storedHorizontal
        ? newXValue - PIP_HEIGHT_WIDTH_DIFFERENCE * xSign
        : newXValue + PIP_HEIGHT_WIDTH_DIFFERENCE * xSign;
    newYValue = storedHorizontal
        ? newYValue + PIP_HEIGHT_WIDTH_DIFFERENCE * ySign
        : newYValue - PIP_HEIGHT_WIDTH_DIFFERENCE * ySign;
    return {
        newXValue,
        newYValue,
    };
};
