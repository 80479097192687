import { uuidv4 } from '@embed/helpers';
const videoPlayIdStorage = new Map();
/**
 * @returns The video play id. It generates a new one if it doesn't exist yet.
 */
export function getVideoPlayId(widgetId, videoId, videoPlaySourceType) {
    const key = `${widgetId}_${videoId}_${videoPlaySourceType}`;
    // @TODO: This is a temporary fix. Remove the videoId when releasing only new VAST
    if (!videoPlayIdStorage.has(key) && videoId) {
        videoPlayIdStorage.set(key, uuidv4());
    }
    return videoPlayIdStorage.get(key); // safe because we just set it
}
/**
 * Removes the video play id from the map.
 */
export function removeVideoPlayId(widgetId, videoId, videoPlaySourceType) {
    widgetId &&
        videoId &&
        videoPlayIdStorage.delete(`${widgetId}_${videoId}_${videoPlaySourceType}`);
}
