import { attach as attachABTesting } from './fwnABTesting/attach';
import { attach as attachAnalytics } from './fwnAnalytics/attach';
import { attach as attachConsole } from './fwnConsole/attach';
import { attach as attachFwnCookies } from './fwnCookies/attach';
import { attach as attachHydrate } from './fwnHydrate/attach';
import { attach as attachMemoryStorage } from './fwnMemoryStorage/attach';
import { attach as attachPIP } from './fwnPIP/attach';
import { attach as attachPlay } from './fwnPlay/attach';
import { attach as attachPlayer } from './fwnPlayer/attach';
import { attach as attachPlayerStateSync } from './fwnPlayersStateSync/attach';
import { attach as attachScreenReader } from './fwnScreenReaderAlert/attach';
import { attach as attachShopping } from './fwnShopping/attach';
import { init } from './init';
import { loadInvitation } from './loadInvitation';
import { loadShareLinkOrSoftPIP } from './loadShareLinkOrSoftPIP';
import { restoreScroll } from './restoreScroll';
let hasInitialized = false;
/**
 *
 */
export function initAllFwnModules() {
    if (hasInitialized) {
        return;
    }
    hasInitialized = true;
    init(() => {
        // Order of attach might matter
        attachFwnCookies();
        attachAnalytics({ shouldSendPageLoadPixelEvent: true });
        attachConsole();
        attachHydrate();
        attachShopping();
        attachMemoryStorage();
        attachPIP();
        attachPlay();
        attachPlayer();
        attachScreenReader();
        attachPlayerStateSync();
        attachABTesting();
        restoreScroll();
        loadInvitation();
        loadShareLinkOrSoftPIP();
    });
}
export const TEST_ONLY = {
    reset() {
        hasInitialized = false;
    },
};
