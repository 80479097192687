import { addActivityDetector } from '@core/activityDetector';
import { logError } from '@core/logging';
import { getBrowser, getOS } from '@core/userAgent';
/**
 *
 */
export function shouldApplyFocusVisiblePolyfill() {
    return getBrowser() === 'Safari' || getOS() === 'iOS';
}
function createFocusVisibleStyle(isPolyfill) {
    const selector = isPolyfill ? '.focus-visible' : ':focus-visible';
    const style = document.createElement('style');
    style.setAttribute('data-testid', 'focusVisibleStyle');
    // Before any keyboard interaction, we do not show focus ring.
    // This is needed because focus-visible will be applied to the programmatic
    // focused element when a shadow DOM is created. (CS-721)
    style.textContent = `
button:focus {
  outline: none;
}`;
    // After a keyboard interaction is detected, we show focus ring when
    // focus-visible style is present.
    addActivityDetector(() => {
        style.textContent = `
button:focus:not(${selector}) {
  outline: none;
}`;
    }, ['keydown']);
    return style;
}
/**
 * Applies focus-visible polyfill and styles.
 * CS-127: Use focus-visible polyfill to ensure the focus ring only shows up
 * when interacting with the keyboard.
 */
export function applyFocusVisible(shadowRoot) {
    if (!shouldApplyFocusVisiblePolyfill()) {
        const style = createFocusVisibleStyle(false);
        shadowRoot.appendChild(style);
        return;
    }
    import('focus-visible').then(() => {
        if (window.applyFocusVisiblePolyfill) {
            window.applyFocusVisiblePolyfill(shadowRoot);
            const style = createFocusVisibleStyle(true);
            shadowRoot.appendChild(style);
        }
        else {
            logError(new Error('focus visible polyfill is not properly loaded'));
        }
    });
}
