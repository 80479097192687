/**
 * Filter array with unique values by predicate.
 * @param arr
 * @param fn
 * @returns
 */
function uniqBy(arr, predicate) {
    return arr.filter((el, index) => {
        return (arr.findIndex((step) => {
            return predicate
                ? typeof predicate === 'function'
                    ? predicate(el) === predicate(step)
                    : el[predicate] === step[predicate]
                : el === step;
        }) === index);
    });
}
export default uniqBy;
