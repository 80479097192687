// please try to keep alphabetical for easy scanning
export const ADD_TO_CART_BUTTON = 'add-to-cart-button'; // used by QA
export const ADD_TO_CALENDAR_BUTTON = 'add-to-calendar'; // used by QA
export const AGORA_VIDEO_CONTAINER = 'agora-video-container';
export const AGORA_VIDEO_PLAYER = 'agora-video-player'; // used by QA
export const AGORA_TEXT_CUES_WRAPPER = 'agora-text-cues-wrapper';
export const BUY_NOW_BTN = 'buy-now-btn'; // used by QA
export const CAMERA_BUTTON_OFF = 'camera-button-off';
export const CAMERA_BUTTON_ON = 'camera-button-on';
export const CART_BUTTON = 'cart-button';
export const CART_ITEM_CONTAINER = 'cart-item-container'; // used by QA
export const CART_ITEM_DECREASE_BUTTON = 'cart-item-decrease-button'; // used by QA
export const CART_ITEM_INCREASE_BUTTON = 'cart-item-increase-button'; // used by QA
export const CART_ITEM_REMOVE_BUTTON = 'cart-item-remove-button'; // used by QA
export const CART_ITEM_QUANTITY_INPUT = 'cart-item-quantity-input';
export const CLOSED_CAPTIONING_BUTTON = 'closed-captioning-button'; // used by QA
export const CHAT_CONTAINER = 'chat-container';
export const CHAT_EMOJI_BUTTONS = 'emoji-buttons'; // used by QA
export const CHAT_MESSAGE_TEXT = 'chat-message-text'; // used by QA
export const CHAT_MODERATOR_REPLIED_MESSAGE_TEXT = 'moderator-replied-text'; // used by QA
export const CHAT_MODERATOR_USERNAME = 'moderator-username'; // used by QA
export const CHAT_TOGGLE_BUTTON = 'chat-toggle-button';
export const CHAT_USERNAME = 'chat-username'; // used by QA
export const CHAT_VIEWER_MESSAGE_TEXT = 'viewer-message-text'; // used by QA
export const CHAT_VIEWER_USERNAME = 'viewer-username'; // used by QA
export const CHAT_WING = 'chat-wing';
export const CLOSE_PLAYER_BUTTON = 'close-player-button'; // used by QA
export const CONTROLS_OVERLAY = 'controls-overlay';
export const COPY_BUTTON = 'cy-copy-link'; // used by QA
export const COPY_BUTTON_IMG = 'copy-link-img'; // used by QA
export const COPY_BUTTON_LABEL = 'copy-link-label'; // used by QA
export const CHAT_INPUT = 'chat-input'; // used by QA
export const CHECKOUT_BTN = 'checkout-button'; // used by QA
export const CONTINUE_SHOPPING_BTN = 'continue-shopping-button';
export const CTA_BTN = 'ctaButton';
export const CTA_BTN_AUTO = 'ctaButton-auto'; // used by QA
export const CUSTOM_CTA_BTN = 'custom-cta-button';
export const EMBED_IN_EMAIL_BUTTON = 'embed-in-email-button'; // used by QA
export const EMBED_LIVESTREAM_DATE_WRAPPER = 'embed-livestream-date-wrapper';
export const EMOJI_BUTTON = 'emoji-button';
export const ENTER_GIVEAWAY_SUCCESS_MODAL = 'enter-giveaway-success-modal';
export const ERROR_CONTENT_IMG = 'error-content-img'; // used by QA
export const ERROR_CONTENT_DESCRIPTION = 'error-content-description'; // used by QA
export const ERROR_CONTENT_OK_BUTTON = 'error-content-dismiss-ok-button'; // used by QA
export const GATED_OVERLAY = 'gated-overlay';
export const GATED_OVERLAY_SUBMIT_BUTTON = 'gated-overlay-submit-button';
export const GATED_OVERLAY_INPUT = 'gated-overlay-input';
export const GATED_OVERLAY_ERROR_MESSAGE = 'gated-overlay-error-message';
export const GATED_OVERLAY_POSTER_IMG = 'gated-overlay-poster-img';
export const GATED_OVERLAY_VIDEO = 'gated-overlay-video';
export const GATED_OVERLAY_TRAILER_SOURCE = 'gated-overlay-trailer-source';
export const GATED_CTA = 'gated-cta';
export const SHARE_BUTTON = 'share-button'; // used by QA
export const SHARE_TIMESTAMP_INPUT_FIELD = 'share-timestamp-input'; // used by QA
export const SHARE_PANEL_CLOSE_BUTTON = 'close-share-button'; // used by QA
export const SUBTITLES_OPTION_BUTTON = 'subtitles-option-button';
export const SUBTITLES_OFF_BUTTON = 'subtitles-off-button';
export const MAIN_MENU_SUBTITLES_BUTTON = 'main-menu-subtitles-button';
export const FULLSCREEN_BUTTON = 'fullscreen-button';
export const GIVEAWAY_TANDC_LINK = 'giveaway-tandc-link';
export const GUEST_SPEAKER_LOADER = 'guestSpeakerLoader';
export const HERO_UNIT_WRAPPER = 'hero-unit-wrapper'; // used by QA
export const HERO_UNIT_TEXT_CONTENT_WRAPPER = 'hero-unit-text-content-wrapper'; // used by QA
export const HERO_UNIT_EVENT_TITLE = 'hero-unit-event-title'; // used by QA
export const HERO_UNIT_EVENT_SCHEDULED_DATE = 'hero-unit-event-scheduled-date'; // used by QA
export const HERO_UNIT_WATCH_NOW_BUTTON = 'hero-unit-watch-now-button'; // used by QA
export const HERO_UNIT_SECONDARY_PROMO_BUTTON = 'hero-unit-secondary-promo-button'; // used by QA
export const HERO_UNIT_SHARE_BUTTON = 'hero-unit-share-button'; // used by QA
export const HERO_UNIT_SHARE_CANCEL_BUTTON = 'hero-unit-share-cancel-button'; // used by QA
export const HERO_UNIT_SHARE_OPTIONS = 'hero-unit-share-options'; // used by QA
export const HERO_UNIT_ADD_TO_CALENDAR_BUTTON = 'hero-unit-add-to-calendar-button'; // used by QA
export const HERO_UNIT_ADD_TO_CALENDAR_CANCEL_BUTTON = 'hero-unit-add-to-calendar-cancel-button'; // used by QA
export const HERO_UNIT_COUNTDOWN = 'hero-unit-countdown'; // used by QA
export const INTERACTIVE_VIDEO_CONTAINER = 'interactive-video-container';
export const INTERACTIVE_VIDEO_PROMPT = 'interactive-video-prompt';
export const INTERACTIVE_VIDEO_CHOICE = 'interactive-video-choice';
export const INVITE_NOTIFICATION_FOLD_BTN = 'inviteNotificationFoldBtn';
export const INVITE_NOTIFICATION_DISMISS_BTN = 'inviteNotificationDismissBtn'; // used by QA
export const INVITE_NOTIFICATION_ACCEPT_BTN = 'inviteNotificationAcceptBtn'; // used by QA
export const LIVESTREAM_ENTER_STREAM_CTA = 'livestream-enter-stream-cta';
export const LIVESTREAM_HOUR_COUNTDOWN = 'livestream-hour-countdown';
export const LIVESTREAM_FOOTER = 'livestream-footer';
export const LIVESTREAMING_REPLAY_TIMELINE_SLIDER = 'livestreaming-replay-timeline-slider';
export const LIVE_STREAMING_FOOTER_ROW_FIRST = 'live-streaming-footer-row-first';
export const LIVE_STREAMING_FOOTER_ROW_FIRST_LEFT = 'live-streaming-footer-row-first-left';
export const PRODUCT_LIST_ITEM = 'product-list-item'; // used by QA
export const PRODUCT_LIST_ITEM_PRODUCT_NAME = 'product-list-item-product-name'; // used by QA
export const PRODUCT_LIST_ITEM_LINK = 'product-list-item-link';
export const MICROPHONE_BUTTON_MUTED = 'microphone-button-muted';
export const MICROPHONE_BUTTON_UNMUTED = 'microphone-button-unmuted';
export const MINIMIZE_PLAYER_BUTTON = 'minimize-player-button'; // used by QA
export const MODAL_CANCEL_BUTTON = 'modal-cancel-button';
export const PRODUCTS_MODAL = 'products-modal';
export const PRODUCTS_MODAL_DRAWER = 'products-modal-drawer';
export const PRODUCT_DETAIL_COLOR_BUTTON = 'product-detail-color-button'; // used by QA
export const PRODUCT_DETAIL_COLOR_BUTTON_SHOW_HIDE = 'product-detail-color-button-show-hide';
export const POST_BUTTON = 'post-button'; // used by QA
export const POSTER_OVERLAY = 'poster-overlay';
export const NEXT_VIDEOS = 'next-videos'; // used by QA
export const PREV_VIDEOS = 'prev-videos'; // used by QA
export const NEXT_VIDEOS_TILE = 'next-videos-tile';
export const PRODUCT_CARD = 'product-card'; // used by QA
export const PRODUCT_PRICE_LABEL = 'product-price-label';
export const UNIT_PRICE_LABEL = 'unit-price-label'; // used by QA
export const PRODUCT_CARD_ITEM_NAME = 'product-card-item-name'; // used by QA
export const PRODUCT_CARD_ITEM_UNIT_NAME = 'product-card-item-unit-name';
export const PRODUCT_CARD_ITEM_IMG = 'product-card-item-img';
export const PRODUCT_CARD_ITEM_PRICE = 'product-card-item-price'; // used by QA
export const PRODUCT_COUNTDOWN_TIMER = 'product-countdown-timer';
export const PRODUCT_DETAIL = 'product-detail';
export const PRODUCT_DETAIL_ITEM_NAME = 'product-detail-item-name'; // used by QA
export const PRODUCT_DETAIL_EXTERNAL_LINK = 'product-detail-external-link';
export const PRODUCT_DETAIL_ITEM_UNIT_NAME = 'product-detail-item-unit-name';
export const PRODUCT_DETAIL_COLOR_VALUE = 'product-detail-color-value'; // used by QA
export const PRODUCT_DETAIL_LIST_VALUE = 'product-detail-list-value'; // used by QA
export const PRODUCT_DETAIL_ITEM_PRICE = 'product-detail-item-price'; // used by QA
export const PRODUCT_DETAIL_QUANTITY_PICKER = 'product-detail-quantity-picker';
export const PRODUCT_DETAIL_SOLD_OUT_BUTTON = 'product-detail-sold-out-button'; // used by QA
export const PRODUCT_DETAIL_SUBTITLE = 'product-detail-subtitle';
export const PRODUCT_DETAIL_HEAD_IMAGE = 'product-detail-head-image';
export const PRODUCT_DETAIL_ITEM_DESCRIPTION = 'product-detail-item-description'; // used by QA
export const PRODUCT_DETAIL_ITEM_DESCRIPTION_TITLE = 'product-detail-item-description-title';
export const PRODUCT_DETAIL_MORE_BUTTON = 'product-detail-more-button'; // used by QA
export const PRODUCT_DETAIL_FOOTER = 'product-detail-footer';
export const PRODUCT_DETAIL_FOOTER_WRAPPER = 'product-detail-footer-wrapper';
export const PRODUCT_DETAIL_WARNING = 'product-detail-warning';
export const PRODUCT_MOMENT_STYLED_CHIP = 'product-moment-styled-chip';
export const QR_CODE_TEXT_MESSAGE = 'qr-code-text-msg'; // used by QA
export const QR_CODE_IMAGE = 'qr-code-image'; // used by QA
export const READY_TO_STREAM_WAITING_MESSAGE = 'ready-to-stream-waiting-for-host-to-bring-on-air-message'; // used by QA
export const READY_TO_STREAM_NOTIFICATION_FOLD_BTN = 'ready-to-stream-notification-fold-btn'; // used by QA
export const READY_TO_STREAM_UNREADY_BTN = 'ready-to-stream-unready-btn'; // used by QA
export const RENAME_BUTTON = 'rename-button';
export const SHOPPING_BAG_BUTTON = 'shopping-bag-button'; // used by QA
export const SHOPPING_WING = 'right-wing';
export const SHOPPING_WING_CART_BUTTON = 'right-wing-cart-button';
export const SB_INTERACTION_GIVEAWAY_BUTTON = 'sb-interaction-giveaway-button'; // used by QA
export const SB_INTERACTION_GIVEAWAY_INPUT = 'sb-interaction-giveaway-input'; // used by QA
export const SB_INTERACTION_GIVEAWAY_WRAPPER = 'sb-interaction-giveaway-wrapper'; // used by QA
export const SB_INTERACTION_QUESTION_INPUT = 'sb-interaction-question-input'; // used by QA
export const SB_INTERACTION_QUESTION_BUTTON = 'sb-interaction-question-button'; // used by QA
export const SB_INTERACTION_QUESTION_CONTAINER = 'sb-interaction-question-container';
export const SB_INTERACTION_POLL_CONTAINER = 'sb-interaction-poll-container';
export const SB_INTERACTION_POLL_OPTION = 'sb-interaction-poll-option';
export const SB_INTERACTION_QUESTION_WRAPPER_BACKGROUND = 'sb-interaction-question-wrapper-background';
export const SB_INTERACTION_WRAPPER = 'sb-interaction-wrapper';
export const SHOP_NOW_BTN = 'shop-now-btn'; // used by QA
export const STREAM_SETTING_BUTTON = 'stream-setting-button'; // used by QA
export const STREAM_SETTING_MODAL = 'stream-setting-modal'; // used by QA
export const STREAM_SETTING_MODAL_CLOSE_BUTTON = 'setting-dialog-close-btn'; // used by QA
export const STREAM_SETTING_MODAL_USERNAME = 'stream-setting-username'; // used by QA
export const STREAM_SETTING_MODAL_USERNAME_EDIT_BUTTON = 'stream-setting-username-edit-button'; // used by QA
export const STREAM_SETTING_MODAL_READY_BUTTON = 'stream-setting-ready-button'; // used by QA
export const STREAM_SETTING_MODAL_CANCEL_BUTTON = 'stream-setting-cancel-button'; // used by QA
export const SWITCH_CAMERA_BUTTON = 'switch-camera-button';
export const TAP_TO_UNMUTE = 'tap-to-unmute'; // used by QA
export const TOGGLE_QR_CODE_BUTTON = 'toggle-qr-code-button'; // used by QA
export const VTT_TEXT_CUES = 'vtt-text-cues';
export const CURRENT_PLAY_TIME = 'video-play-time'; // used by QA
export const VIDEO_DURATION = 'video-duration'; // used by QA
export const RENAME_MODAL = 'rename-modal';
export const CHAT_SETTINGS_MODAL = 'chat-settings-modal';
export const CHAT_SETTINGS_MODAL_USERNAME = 'chat-settings-modal-username';
export const CHAT_SETTINGS_MODAL_PRIVACY = 'chat-settings-modal-privacy';
export const COOKIE_CONSENT_MODAL = 'cookie-consent';
export const PRODUCT_INDEX = 'product-index';
export const EMAIL_EMBED_COPY_BUTTON = 'email-embed-copy-button'; // used by QA
export const PLAYLIST_THUMBNAIL = 'playlist-thumbnail';
export const PRODUCT_IMAGE_CAROUSEL = 'product-image-carousel';
export const THUMBNAIL_CONTAINER = 'thumbnail-container';
export const STORYBLOCK = 'storyblock';
export const STORYBLOCK_HIDDEN = 'storyblock-hidden';
export const NEXT_PRODUCT_CARD_BUTTON = 'next-productcard-button';
export const PREVIOUS_PRODUCT_CARD_BUTTON = 'previous-productcard-button'; // used by QA
export const PRODUCT_CARD_CAROUSEL = 'product-card-carousel';
export const PRODUCT_CARD_CAROUSEL_NAVIGATION = 'product-card-carousel-navigation';
export const HEART_BUTTON_WRAPPER = 'heart-button-wrapper';
// Lead Generation IDs
export const LEAD_GEN_FIRST_NAME_INPUT = 'lead-gen-first-name-input';
export const LEAD_GEN_LAST_NAME_INPUT = 'lead-gen-last-name-input';
export const LEAD_GEN_EMAIL_ADDRESS_INPUT = 'lead-gen-email-address-input';
export const LEAD_GEN_COMPANY_NAME_INPUT = 'lead-gen-company-name-input';
export const LEAD_GEN_GIFT_SELECT = 'lead-gen-gift-select';
export const LEAD_GEN_FORM_SUBMIT_BTN = 'lead-gen-form-submit-btn';
export const LEAD_GEN_FORM_ERROR_MESSAGE = 'lead-gen-form-error-message';
export const VIDEO_CONTROLS_MINIMIZE_ICON_MAXIMIZE = 'video-controls-minimize-icon-maximize';
export const VIDEO_CONTROLS_MINIMIZE_ICON_MINIMIZE = 'video-controls-minimize-icon-minimize';
export const VIDEO_CONTROLS_MINIMIZE_ICON_CLOSE = 'video-controls-minimize-icon-close';
export const VIDEO_CONTROLS_MINIMIZE_ICON_CARET_DOWN = 'video-controls-minimize-icon-caret-down';
export const REMIND_ME_TYPE_TOGGLE = 'remind-me-type-toggle';
export const CUSTOM_ASSET = 'custom-asset';
