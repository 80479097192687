import { stringifyQueryParams } from '@core/window';
import { defaultApiHost } from '@src/helpers';
const fetchDefaults = {
    cache: 'no-cache',
    credentials: 'include',
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json',
    },
};
const resolveUrl = (url, baseUrl) => url.startsWith('/') && !url.startsWith('//') ? baseUrl + url : url;
/**
 *
 */
export const fetcher = async ({ url, query, fetchParams = {}, baseUrl = defaultApiHost, }) => {
    fetchParams = {
        ...fetchDefaults,
        ...fetchParams,
    };
    const queryPrefix = url.includes('?') ? '&' : '?';
    const fetchUrl = resolveUrl(url, baseUrl) + queryPrefix + stringifyQueryParams(query);
    //try {
    return await fetch(fetchUrl, fetchParams);
    //} catch (e) {
    //
    // possibly failing due to adblockers blocking requests to fireworkadservices domains, filling up sentry with errors
    // Originally we were logging this error out:
    //
    // (TODO CS-7105) figure out better solution for this
    //
    // https://firework.sentry.io/issues/5747234928/?alert_rule_id=9448026&alert_type=issue&environment=production&notification_uuid=51e5afb4-12b4-406f-98d1-25172f7577e4&project=2054398&referrer=slack
    // logError(e, {
    //   captureContext: {
    //     extra: {
    //       fetchUrl,
    //       fetchParams,
    //     },
    //   },
    // })
    //throw e
    //}
};
