var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Analytics_instances, _Analytics_trackUserEvent;
import merge from '@core/lodash/merge';
import { getGuestId, getLastAssistanceIdWithAnswer, getLastAvaEngagedWithAnswerTimestamp, getLastBusinessMembershipIdCookie, getLastChannelId, getLastChatChannelId, getLastEngagedTimestamp, getLastVideoId, getSession, sendPixelTrackingWithSession, } from '@core/tracking';
import { setConsent } from '@core/tracking/consent';
import { getLastShowroomIdCookie } from '@core/tracking/customTracking';
/**
 *
 */
export class Analytics {
    constructor() {
        _Analytics_instances.add(this);
    }
    /**
     *
     */
    get guest_id() {
        return getGuestId();
    }
    /**
     *
     */
    get session_id() {
        return getSession().id;
    }
    /**
     * @returns An empty array. This method is set to have API parity with the
     *     fallback analytics module. The fallback analytics module has a queue
     *     to keep the events in memory until the analytics module is loaded.
     */
    get eventQueue() {
        return [];
    }
    /** @returns False. This is not the fallback analytics module. */
    isFallback() {
        return false;
    }
    /**
     * Sends pixel tracking events. A wrapper of sendPixelTrackingWithSession.
     */
    sendEvent(url, data, isUserEngagement = false, consent = 'essential') {
        sendPixelTrackingWithSession({
            endpoint: {
                url,
                isUserEngagement,
                consent,
            },
            data,
            host: undefined,
        });
    }
    /**
     * Publicly exposed method to send user tracking events
     */
    push(data) {
        const event = data?.event;
        __classPrivateFieldGet(this, _Analytics_instances, "m", _Analytics_trackUserEvent).call(this, event, {
            event_properties: { user_data: data },
        });
    }
    /**
     * A purchase event for 3rd parties to help with GMV attribution.
     * This is preferred for custom push events because we can dictate
     * the data shape to help the data team with consistent analytics.
     */
    purchase(data) {
        __classPrivateFieldGet(this, _Analytics_instances, "m", _Analytics_trackUserEvent).call(this, 'purchase', {
            event_properties: { user_data: data },
        });
    }
    /**
     * Allows storing of user's selected consent. Consent is later used to
     * restrict tracking of user.
     */
    setConsent(consent) {
        setConsent(consent);
    }
}
_Analytics_instances = new WeakSet(), _Analytics_trackUserEvent = function _Analytics_trackUserEvent(event, data) {
    const businessMembershipId = getLastBusinessMembershipIdCookie();
    const showroomId = getLastShowroomIdCookie();
    const mergedData = merge({}, {
        event_properties: {
            _last_assistance_id: getLastAssistanceIdWithAnswer(),
            _last_chat_channel_id: getLastChatChannelId(),
            _last_channel_id: getLastChannelId(),
            _last_video_id: getLastVideoId(),
            last_ava_engaged_timestamp: getLastAvaEngagedWithAnswerTimestamp(),
            last_engaged_timestamp: getLastEngagedTimestamp(),
            _business_membership_id: businessMembershipId?.value,
            last_business_membership_id_timestamp: businessMembershipId?.createTime,
            _last_showroom_id: showroomId?.value,
            last_showroom_id_timestamp: showroomId?.createTime,
        },
    }, data);
    sendPixelTrackingWithSession({
        endpoint: {
            url: `/trk/user:${event}`,
            // We don't consider user events as activities.
            isUserEngagement: false,
            consent: 'essential',
        },
        data: mergedData,
        host: undefined,
    });
};
