import { PlayerPlacementEnum } from '@core/types';
import { resolveTranslateRange } from './resolveTranslateRange';
/**
 * Helper function to resolve which quadrant the PIP is in. This is used in
 * conjuction with resolvePipTranslate to determine the new translate values.
 */
export const resolveMinimizedPlayerQuadrant = ({ windowSize, translate, isHorizontal, initialPlayerPlacement, playerMargin, }) => {
    // current translate values. Positive when moving right or down
    const { x, y } = translate;
    const { xRange, yRange } = resolveTranslateRange({
        windowSize,
        isHorizontal,
        playerMargin,
    });
    // new x and y values are calculated based on the player's placement
    // this is because the origin is reflected in the translate values
    // example: if initialPlayerPlacement is in the bottom right corner, the translate values
    // will be negative in both x and y. So to standardize which quadrant we are
    // in, regardless of the player_placement, we need to add the window size to
    // the translate values, if initialPlayerPlacement includes right or bottom.
    const newXValue = initialPlayerPlacement.includes('right') ? x + xRange : x;
    const newYValue = initialPlayerPlacement.includes('bottom') ? y + yRange : y;
    const windowCenterX = xRange / 2;
    const windowCenterY = yRange / 2;
    // 0,0 is in the top left corner
    return newXValue < windowCenterX
        ? newYValue < windowCenterY
            ? PlayerPlacementEnum.TOP_LEFT
            : PlayerPlacementEnum.BOTTOM_LEFT
        : newYValue < windowCenterY
            ? PlayerPlacementEnum.TOP_RIGHT
            : PlayerPlacementEnum.BOTTOM_RIGHT;
};
