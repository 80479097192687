import typeOf from 'just-typeof';
/**
 * Test for number type value.
 * @param value
 * @returns
 */
function isNumber(value) {
    return typeOf(value) === 'number';
}
export default isNumber;
