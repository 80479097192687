import styled from '@emotion/styled';
import { Image } from '@core/components/Image';
import { BORDER_RADIUS } from '@embed/styled';
export const BLUR_RADIUS = '20px';
export const StoryblockContainer = styled.div `
  max-height: 100%;
  max-width: 100%;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  overflow: hidden;
`;
export const StoryblockPlayer = styled.div `
  border-radius: var(
    --storyblock-border-radius,
    ${(props) => (props.borderStyle === 'hard' ? '0' : BORDER_RADIUS)}
  );

  --player-border-radius: var(
    --storyblock-border-radius,
    ${(props) => (props.borderStyle === 'hard' ? '0' : BORDER_RADIUS)}
  );
  height: ${(props) => (props.branding ? `calc(100% - 20px)` : '100%')};
  max-height: ${(props) => (props.branding ? `calc(100% - 20px)` : '100%')};
  overflow: hidden;
  position: relative;
`;
export const DockInfo = styled('div') `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--storyblock-background, transparent);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FWNIframeClickOverlay = styled('div') `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
`;
export const VideoPoster = styled(Image) `
  border-radius: var(--border-radius, 0);
  height: 100%;
`;
export const DockInfoMessage = styled.div `
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  background: var(--storyblock-background, transparent);
  backdrop-filter: blur(${BLUR_RADIUS});
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
