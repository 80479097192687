import { WidgetTypesEnum } from '@core/types';
import { AppContextType } from '@embed/components/helpers';
import { FWNElement } from './bases/FWNElement';
class StorylinkElement extends FWNElement {
    constructor() {
        super(...arguments);
        this.onClick = () => {
            window._fwn?.play?.({
                type: WidgetTypesEnum.STORYLINK,
                widget_id: this.widgetId,
                appContextType: AppContextType.EMBED_STORYLINK_CONTEXT_TYPE,
                tap_to_watch: false,
                ...this.attrs,
            }, { muted: false });
        };
    }
    get attrs() {
        return Object.fromEntries(Array.from(this.attributes)
            .filter((attr) => !['class', 'style'].includes(attr.name))
            .map((attr) => [attr.name, attr.value]));
    }
    createRenderRoot() { }
    connectedCallback() {
        this.addEventListener('click', this.onClick);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!this.getAttribute('class') && !this.getAttribute('style')) {
            const className = `fw-${Math.round(Math.random() * 10000)}`;
            const styleTag = document.createElement('style');
            styleTag.innerHTML = `
      fw-storylink.${className} {
        cursor: pointer;
        font-weight: bold;
        position: relative;
      }
      fw-storylink.${className}:before {
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0.25;
        position: absolute;
        width: 100%;

        border: 1px solid gray;
      }

      fw-storylink.${className}:after {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        width: 0%;

        border: 1px solid;
        border-width: 1px;
        border-image-slice: 1;
        border-image-source: var(--gradient);

        transition: 0.25s;
      }

      fw-storylink.${className}:hover:after {
        width: 100%;
      }
    `;
            this.appendChild(styleTag);
            this.classList.add(className);
        }
    }
}
if (!customElements.get('fw-storylink')) {
    customElements.define('fw-storylink', StorylinkElement);
}
