import { isMobileWeb } from '@core/userAgent';
/**
 *
 */
export const filterCurrentInViewport = (inViewportIds) => Object.entries(inViewportIds)
    .filter(([, value]) => value)
    .map(([key]) => key);
/**
 *
 */
export const sortByOtherArray = (arr, other) => [...arr].sort((a, b) => (other.indexOf(a) > other.indexOf(b) ? 1 : -1));
/**
 *
 */
export const formatDuration = (duration) => Math.round(duration * 1000) / 1000;
/**
 *
 */
export const containerPlacement = (container) => {
    const r = Math.round;
    const rect = container.getBoundingClientRect();
    const viewportWidth = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const viewportHeight = window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        viewport_width: r(viewportWidth),
        viewport_height: r(viewportHeight),
        offset_top: r(rect.top + scrollTop),
        offset_left: r(rect.left + scrollLeft),
        container_width: r(rect.width),
        container_height: r(rect.height),
    };
};
/**
 *
 */
export const filterRestrictedRegionVideos = (videos) => {
    return videos.filter((video) => video?.encoded_id &&
        (video?.download_url ||
            (video?.video_type === 'live_stream' &&
                (video?.live_stream_playback_url || video?.live_stream_replay_url))));
};
/**
 *
 */
export const getPlatform = () => {
    if (typeof window === 'undefined') {
        return null;
    }
    return isMobileWeb() ? 'mobile_web' : 'web';
};
/**
 *
 */
