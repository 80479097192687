/** Name of the iframe into which original page is loaded. */
import { StorageKeyEssentialEnum } from '@core/storage/types';
// (ZE-392) Refactor `fw-iframe` with a `PIP_IFRAME_NAME` constant
export const PIP_IFRAME_NAME = 'fw-iframe';
/** Session storage key to store Soft PIP player state for later re-creation */
export const SOFT_PIP_PLAYER_STATE_KEY = StorageKeyEssentialEnum.fw_ply_sft_pip;
/** Default outbound target */
export const PIP_IFRAME_OUTBOUND_TARGET = '_blank';
/** Time in ms to wait before confirming the iframe has a src attribute after render */
export const PIP_IFRAME_SRC_CHECK_DELAY = 100;
// Storage keys used by PIP feature
/** Session storage key to restore PIP iframe scroll position */
export const PIP_IFRAME_SCROLL_POSITION = StorageKeyEssentialEnum.fw_ply_scrll;
/** Set when the user closes the pip, used to remember not to open pip again after reload in pinned mode */
export const PIP_DELIBERATELY_CLOSED = StorageKeyEssentialEnum.fw_ply_pipexit;
/** Check whether PIP iframe is possible (stored in local storage) */
export const ABLE_TO_IFRAMEIZE = StorageKeyEssentialEnum.fw_ply_amnmze;
