import { useMemo } from 'react';
import { tFunctionImplementation } from './tFunctionImplementation';
/**
 *
 */
export function useTranslationImplementation(language, ns, options, postProcessFn) {
    const t = useMemo(() => options?.i18n
        ? options?.i18n?.getFixedT(language, ns)
        : (tKey, tOptions) => {
            tKey = tFunctionImplementation(tKey, tOptions);
            /** Apply pseudo localization or other postprocess. For dev purposes only */
            return postProcessFn ? postProcessFn(tKey) : tKey;
        }, [language, ns, options?.i18n, postProcessFn]);
    const i18n = options?.i18n;
    const ready = !!options?.i18n;
    const ret = Object.create([t, i18n, ready]);
    ret.t = t;
    ret.i18n = i18n;
    ret.ready = ready;
    return ret;
}
