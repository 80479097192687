/**
 *
 */
export function createDeviceSettingsActions(set) {
    return {
        cameraIdChanged: (value) => {
            set({
                cameraId: value,
            }, false, 'cameraIdChanged');
        },
        speakerIdChanged: (value) => {
            set({ speakerId: value }, false, 'speakerIdChanged');
        },
        microphoneIdChanged: (value) => {
            set({
                microphoneId: value,
            }, false, 'microphoneIdChanged');
        },
        getCameraIdFailed: () => {
            set({ failedToGetCameraId: true }, false, 'getCameraIdFailed');
        },
        toggleFacingMode: () => {
            set((state) => {
                return {
                    facingMode: state.facingMode === 'environment' ? 'user' : 'environment',
                };
            }, false, 'facingModeChanged');
        },
    };
}
