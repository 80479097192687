import { lens } from '@dhmk/zustand-lens';
import { createActions } from './actions';
import { createState } from './state';
export const SLICE_KEY = 'channel';
/**
 * Creates a slice for channels in global store
 */
export function createSlice() {
    return {
        [SLICE_KEY]: lens((set) => ({
            ...createState(),
            actions: createActions(set),
        })),
    };
}
