import { createRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { customEvent, EventType } from '@core/customEvent';
import { getSentryConfig, logError } from '@core/logging';
import { KEY_RENDER_STARTED, performanceFactory } from '@core/performance';
import { ensurePolyfills } from '@core/polyfills';
import { createEmbedEmotionCache } from '@core/styles/createEmbedEmotionCache';
import { WidgetTypesEnum } from '@core/types';
import { getPageUrl } from '@core/window';
import ErrorBoundary from './components/common/ErrorBoundary';
import { Player } from './Player';
/**
 * Function to render Player component
 */
export function playerRender({ target, widgetId, widgetParams }) {
    const { channel } = widgetParams;
    const widget_id = widgetId;
    const initialParams = {
        cover: true,
        player_tiles: false,
        location: window.location,
        page_url: getPageUrl(),
        referrer: document.referrer,
        target,
        type: WidgetTypesEnum.PLAYER,
        widget_id,
    };
    performanceFactory({ ...initialParams, ...widgetParams }).measure(KEY_RENDER_STARTED);
    ensurePolyfills(() => {
        const sentryConfig = getSentryConfig();
        sentryConfig.addTag('fwn_channel', channel ?? '');
        sentryConfig.addTag('fwn_context', 'player');
        try {
            if (target) {
                customEvent({
                    type: EventType.FW_PLAYER_RENDER,
                    data: {
                        widget_id,
                    },
                    target,
                });
                const root = createRoot(target);
                root.unmount();
                root.render(<ErrorBoundary onError={(error) => logError(error)}>
            <CacheProvider value={createEmbedEmotionCache({
                        container: target,
                    })}>
              <Player initialParams={initialParams} widgetParams={widgetParams}/>
            </CacheProvider>
          </ErrorBoundary>);
            }
        }
        catch (error) {
            logError(error);
        }
    });
}
