import { logError } from '@core/logging';
import { fetcher } from '@embed/tools/fetchers';
/**
 *
 */
export async function fetchFwaiAds({ xml, params, query, rethrow, ad_host, }) {
    try {
        const response = await fetcher({
            url: `${ad_host ?? params?.api_host_ads}/api/fwai/ad_videos`,
            fetchParams: {
                method: 'POST',
                body: xml,
                headers: {
                    'Content-Type': 'application/xml',
                },
            },
            query,
        });
        const { ads } = await response.json();
        return ads;
    }
    catch (error) {
        logError(error);
        if (rethrow) {
            throw error;
        }
        return [];
    }
}
/**
 *
 */
export async function fetchSsaiAds({ adUnit, adConfig, appContext, params, }) {
    try {
        const placement = adConfig?.placements.filter((unit) => unit.ad_tags[0]?.id === adUnit.id);
        const response = await fetcher({
            url: `${params?.api_host_ads}/api/ad_videos`,
            query: {
                channel: appContext?.username,
                ad_setting_id: adConfig?.id,
                placement_id: placement?.[0].id,
                ad_tag_id: adUnit.id,
                ad_target_platform: adConfig?.ad_target_platform,
                context: adConfig?.context,
                presentation_type: adConfig?.presentation_type,
                ads_count: 1,
                page_url: params?.page_url ||
                    (typeof window !== 'undefined' ? window.location.href : ''),
            },
        });
        const { ads } = await response.json();
        return ads || [];
    }
    catch (error) {
        logError(error);
        return [];
    }
}
