import { Title } from './PlayerTitle.styles';
/**
 * Title component to be optionally used by EmbedFeed and Storyblock. Will only render if there are videos
 */
export const PlayerTitle = ({ hasVideos, ...rest }) => {
    if (!hasVideos) {
        return null;
    }
    return <Title {...rest}/>;
};
