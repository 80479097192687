import { createContext, useContext } from 'react';
/**
 * ParamsContext to carry resolved widget params for reusable components.
 * for any component.
 */
export const ParamsContext = createContext(undefined);
/**
 * useContextParams hook for passing widget params into reusable components.
 */
export function useContextParams() {
    const params = useContext(ParamsContext);
    if (!params) {
        throw new Error('Params not found in the context');
    }
    return params;
}
