import { logError } from '@core/logging';
import { getCookie } from '@core/storage';
import { getRawItem } from '@core/storage/localStorage';
/**
 *
 */
export function getCookieWithFallback(key) {
    let fetchedCookie = undefined;
    try {
        fetchedCookie = getCookie(key);
        if (fetchedCookie !== undefined) {
            try {
                return JSON.parse(fetchedCookie);
            }
            catch (e) {
                // it's possible we still have value which is only string,
                // in that case expiration is handled by cookie automatically
                return { value: fetchedCookie };
            }
        }
        // Because we do not control clearing of cookies, if this cookie
        // value is null, we will try and get the value from local storage.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return getRawItem(key);
    }
    catch (error) {
        logError(error);
    }
}
