import { createContext, useContext } from 'react';
/**
 * TranslationContext to carry translation functions
 * for any component.
 */
export const TranslationContext = createContext(undefined);
/**
 * useContextTranslation hook for translation
 * functions provided by context.
 */
export function useContextTranslation() {
    const transFns = useContext(TranslationContext);
    if (!transFns) {
        throw new Error('Translation functions not found in the context');
    }
    return transFns;
}
