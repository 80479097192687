import { useContext } from 'react';
import { FeedQueryContext, HydratedFeedContext } from './contexts';
import { useFeedQuery } from './useFeedQuery';
/**
 * HOC to provide embed feed context.
 */
export const FeedQueryWrapper = ({ configs, children, params, }) => {
    // Top level component may pass a hydrated feed.
    // i.e We can pass a feed to programmatic player, which will use it instead of
    // fetching it from the server. This is useful for headless feed use case. We
    // want to pass the feed in the headless feed to the programmatic player.
    const hydratedFeed = useContext(HydratedFeedContext);
    const feedQuery = useFeedQuery({
        config: configs,
        params,
        feed: hydratedFeed,
    });
    return (<FeedQueryContext.Provider value={feedQuery}>
      {children}
    </FeedQueryContext.Provider>);
};
