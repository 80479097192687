/**
 * Returns the language of the html page,
 * or the language of the browser if the html page does not have a language.
 */
export const getLanguage = () => {
    let lang = undefined;
    if (typeof window !== 'undefined') {
        if (window.document) {
            const html = window.document.querySelector('html');
            if (html) {
                lang = html.lang?.toLowerCase();
            }
        }
        if (!lang && window.navigator) {
            // Fallback value for `lang` is taken from `navigator.language`
            // which is the only place where its 100% legitimate to ignore
            // linter rule.
            // eslint-disable-next-line zeffo/no-navigator-language
            lang = window.navigator.language;
        }
    }
    return lang || undefined;
};
