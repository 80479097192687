/**
 * URL param used to generate share link. Can be used
 * as a fragment or query param.
 *
 * E.g.
 * - `https://example.com/watch?v=123#fwplayer=base64`
 * - `https://example.com/watch?v=123&fwplayer=base64`
 */
export const SHARE_LINK_PARAM = 'fwplayer';
/**
 * Whitelist of URL params to be parsed for v2.
 */
export const WHITELIST_KEYS = [
    'fw_mode',
    'fw_channel',
    'fw_channel_id',
    'fw_video',
    'fw_video_id',
    'fw_playlist',
    'fw_playlist_id',
    'fw_hashtag',
    'fw_start_time',
    'fw_loop',
    'repeat',
    'start_time',
];
