/**
 * FastClick is a injectable script that removes the 300ms delay on touch devices. This is
 * useful for most web applications, but can cause issues with custom elements.
 *
 * This function disables FastClick on an element by adding the class 'needsclick'.
 * https://github.com/ftlabs/fastclick?tab=readme-ov-file#ignore-certain-elements-with-needsclick
 *
 * @param element
 */
export const disableFastClick = (element) => {
    element.classList.add('needsclick');
};
