import { useMemo } from 'react';
import { Api_AdPlacementType } from '@core/api';
/**
 *
 */
export const useAdsOnly = (props) => {
    const { appContext } = props;
    const isAdsOnly = useMemo(() => {
        if (!appContext || !('adConfig' in appContext)) {
            return false;
        }
        return !!appContext.adConfig?.placements?.find((placement) => placement.placement_type === Api_AdPlacementType.OUTSTREAM);
    }, [appContext]);
    return isAdsOnly;
};
