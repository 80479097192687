import React from 'react';
import styled from '@emotion/styled';
import ThumbnailWrapper from './ThumbnailWrapper';
const Container = styled('div') `
  // [CS-2501] Do not remove the "url" part of the background-image property.
  // It serves to improve the LCP web vital by enabling <Placeholder> as a LCP candidate.
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAGQBAMAAABi+PBPAAAALVBMVEXOzs7T09PW1tbZ2dni4uLf39/s7OzQ0NDo6Ojk5OTb29vq6urm5ubd3d3u7u7SSsE5AAACR0lEQVR42u3TMUorURgFYDvrTGP9MjswXepYpH+WaW0CopVbEGJpI9jZCbYhkA1EsE0j2KbMGszcfybjVQloJeT77wIO5x6+g/OzuHm6yXXcS7r7u7jB9k7re0v39BjX2163vc72Lq/SHR5kt16vVil5Ok7Rs1lKXtxE9HNKHt5G7v/B4OQkJS9HEf2akvsPkVv2esfHKbYoukWT2+kcHaXoi4uPwW3jKrltXCW3jZvkpvFo86JyNG6St4Wr3H9N47jPjdf1b4+z347GL88R3eRW0VF5tEyVXyO6bVyWdW632+R2onL+2z/YOGsc92XjMtu42L3xeb3xNKLrjRcRXWcPs43TyLFx/dn9fONo3ORW0Ts2Hs+nbeXNwouoXDcefmq83Ly08VM07reNy8gtssaXOzaOxvNZRC+yjYdfNl5movr5xnnjb3/77zieTDLH93vgeLZ3jiccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcd77Hj1I8eR/SvHhx9++x1urtNRptEvkAAAAABJRU5ErkJggg==');
  background-size: cover;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;
/**
 * Single placelholder for embed feed.
 */
const Placeholder = ({ isLarge, isSmall, mode, }) => {
    return (<ThumbnailWrapper isPlaceholder isLarge={isLarge} mode={mode} isSmall={isSmall}>
      <Container />
    </ThumbnailWrapper>);
};
export default Placeholder;
