import merge from '@core/lodash/merge';
import { getSession } from '@core/tracking';
import { prepareGuestFields } from '@core/tracking/senders/helpers/prepareGuestFields';
import { prepareSessionFieldsLegacy } from '@core/tracking/senders/helpers/prepareSessionFields';
import { sendBeaconLegacy } from './helpers/beacon';
import { getGlobalTrackingFieldsLegacy } from './helpers/globalTrackingFields';
/**
 * Sends tracking to Naboo API backend.
 * @deprecated We should stop sending tracking to Naboo API backend. Use pixel
 * tracking instead.
 */
export const sendTrackingLegacy = async ({ url: path, query = {}, data = {}, host, }) => {
    const session = getSession();
    const mergedData = merge({}, getGlobalTrackingFieldsLegacy(), prepareGuestFields(), prepareSessionFieldsLegacy(session), data);
    return sendBeaconLegacy({ path, query, host, data: mergedData });
};
