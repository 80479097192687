import { useLayoutEffect } from 'react';
import { logError } from '@core/logging';
import { ensureResizeObserver } from '@player/utils/resizeObserverPromise';
import { useStatefulRef } from '../useStatefulRef';
/**
 * Allows users to trigger callback function on resizing for the element passed
 *
 * @example
 * const ref = useResizeObserver(callbackFn)
 * return <div ref={ref}>Div to be measured</div>
 */
export function useResizeObserver(callbackFn) {
    const ref = useStatefulRef(null);
    useLayoutEffect(() => {
        let observerInstance;
        ensureResizeObserver()
            .then((ResizeObserver) => {
            observerInstance = new ResizeObserver(callbackFn);
            if (!ref.current) {
                return;
            }
            observerInstance.observe(ref.current);
        })
            .catch(logError);
        return () => {
            observerInstance?.disconnect();
        };
        // ref.current here triggers a rerender since its a statefulref, hence it should be a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current, callbackFn]);
    return ref;
}
