import { yieldToMain } from '@core/helpers/yieldToMain';
import { logError } from '@core/logging';
import { persistFWReferrer, sendPixelTrackingWithSession } from '@core/tracking';
import { persistBusinessMembershipId, persistGuestId, persistUTM, } from '@core/tracking/funnelTracking';
import { getPageUrl } from '@core/window';
import { fwnAnalyticsGetter } from './getter';
async function initModule({ shouldSendPageLoadPixelEvent = true, shouldPersistGuestId = true, }) {
    try {
        Object.defineProperty(window._fwn, 'analytics', {
            get: fwnAnalyticsGetter,
            configurable: false,
        });
        persistUTM();
        if (shouldPersistGuestId) {
            persistGuestId();
        }
        persistBusinessMembershipId();
        const pageUrl = getPageUrl();
        const data = {};
        try {
            if (pageUrl) {
                persistFWReferrer(pageUrl);
                // We are sending fw_bmid as part of system:page_load to easily test
                // fw_bmid retention for user
                data.fw_bmid = new URL(pageUrl).searchParams.get('fw_bmid');
            }
        }
        catch (e) { }
        if (shouldSendPageLoadPixelEvent) {
            // For the analytics.js widget we don't want to send this event as the analytics.js
            // bundle is included on every page and very noisy, so this will only fire for fwn.js
            // script tags.
            await yieldToMain();
            sendPixelTrackingWithSession({
                endpoint: {
                    url: '/trk/system:page_load',
                    isUserEngagement: false,
                    consent: 'essential',
                },
                data,
                host: undefined,
            });
        }
    }
    catch (e) {
        if (e instanceof Error) {
            logError(new Error('Failed to attach analytics module'), {
                captureContext: {
                    extra: {
                        errorMessage: e.message,
                    },
                },
            });
        }
    }
}
/**
 * Attaches the analytics module.
 */
export function attach(configs = {}) {
    if (typeof window === 'undefined') {
        return;
    }
    if (!Object.prototype.hasOwnProperty.call(window._fwn, 'analytics')) {
        initModule(configs);
    }
    else {
        // If a fallback analytics module is already attached, we need to send the
        // queued events
        if (window._fwn.analytics.isFallback()) {
            const eventQueue = window._fwn.analytics.eventQueue;
            initModule(configs);
            eventQueue.forEach(({ url, data, isUserEngagement }) => {
                sendPixelTrackingWithSession({
                    endpoint: {
                        url,
                        consent: 'essential',
                        isUserEngagement: !!isUserEngagement,
                    },
                    data,
                    host: undefined,
                });
            });
        }
    }
}
