import { fetcher } from '@expPlatform/embeds/embed/tools/fetchers';
import memoizeOne from 'memoize-one';
/**
 * Fetches ad settings from the given url. Memoizes the last invocation
 * to provide a very simple cache mechanism - do not duplicate identical
 * requests.
 */
export const fetchAdSettings = memoizeOne(async (url) => {
    const response = await fetcher({
        url,
    });
    const adConfigResponse = response.status === 200 ? await response.json() : null;
    return adConfigResponse;
});
