import { useState } from 'react';
/**
 * Note: This hook returns a stateful ref object,
 * whose current property will trigger a rerender when it is mutated
 *
 * @returns
 *     A stateful ref that triggers a re-render anytime the ref is changed.
 *
 * @reference
 *     https://non-traditional.dev/creating-a-stateful-ref-object-in-react-fcd56d9dea58
 *
 * @example
 * const ref = useStatefulRef<HTMLDivElement>(null)
 * return <div ref={ref}>Div to be measured</div>
 */
export function useStatefulRef(initialVal) {
    let [currentVal, setCurrentVal] = useState(initialVal);
    const [statefulRef] = useState({
        get current() {
            return currentVal;
        },
        set current(value) { },
    });
    Object.defineProperty(statefulRef, 'current', {
        get: () => currentVal,
        set: (newValue) => {
            if (!Object.is(currentVal, newValue)) {
                currentVal = newValue;
                setCurrentVal(currentVal);
            }
        },
    });
    return statefulRef;
}
