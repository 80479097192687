import { FWNElement } from './bases/FWNElement';
class HeroUnitElement extends FWNElement {
    constructor() {
        super(...arguments);
        this.height = '75vh';
        this.width = '100%';
    }
    render() {
        import('@embed/herounitRender').then(({ herounitRender }) => {
            if (this.shadowRoot) {
                herounitRender({
                    target: this.shadowRoot,
                    widgetId: this.widgetId,
                    widgetParams: {
                        height: this.height,
                        width: this.width,
                        ...this.getAttributes(),
                    },
                });
            }
        });
    }
}
if (!customElements.get('fw-herounit')) {
    customElements.define('fw-herounit', HeroUnitElement);
}
