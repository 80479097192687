import { StorageConsentLevel, StorageKeyEssentialEnum, StorageKeyFunctionalEnum, StorageKeyPerformanceEnum, StorageKeyTargetingEnum, } from './types';
/**
 * Return consent level by key
 * @param key
 * @returns
 */
export function getConsentLevelByKey(key) {
    if (key in StorageKeyEssentialEnum) {
        return StorageConsentLevel.ESSENTIAL;
    }
    else if (key in StorageKeyFunctionalEnum) {
        return StorageConsentLevel.FUNCTIONAL;
    }
    else if (key in StorageKeyPerformanceEnum) {
        return StorageConsentLevel.PERFORMANCE;
    }
    else if (key in StorageKeyTargetingEnum) {
        return StorageConsentLevel.TARGETING;
    }
    else {
        return StorageConsentLevel.NOOP;
    }
}
