import axios from 'redaxios';
/**
 *
 */
export const setupRedaxiosClient = (apiHost, _context) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    const axiosInstance = axios.create({
        baseURL: apiHost,
        headers,
    });
    return axiosInstance;
};
