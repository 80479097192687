/**
 * @fileoverview
 *
 * Functions to initiate and control `_fwn.players`
 * SDK attribute which provides programmatic access
 * to all avilable players on a scene. Players are
 * accessible by a `widget_id` and `name` key.
 */
import { getFWN } from '@core/fwn';
import { PlayerControl } from './PlayerControl';
const allPlayersCache = {};
/**
 * Getter to access current module level cache
 */
export function fwnPlayersGetter() {
    return allPlayersCache;
}
/**
 * Register a player intance under certain keys (widget_id, name).
 */
export function registerFWNPlayer(keys, instance) {
    const fwn = getFWN();
    if (fwn) {
        const player = new PlayerControl(instance);
        keys.forEach((key) => {
            fwn.players[key] = player;
        });
        return player;
    }
}
/**
 * Unrgister - destroy a player instance.
 */
export function unregisterFWNPlayer(keys) {
    const fwn = getFWN();
    if (fwn) {
        keys.forEach((key) => {
            delete fwn.players[key];
        });
    }
}
