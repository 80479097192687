import { useEffect } from 'react';
import { ParamsContext } from '@core/params/context';
import { useStore } from '@core/widgets/store';
import { appContextSelector, paramsSelector, } from '@core/widgets/store/selectors';
/**
 * Wrapper component to fetch params from publisher client and channel
 */
export const WidgetParams = ({ children, }) => {
    const actions = useStore((state) => state.actions);
    const appContext = useStore(appContextSelector);
    const params = useStore(paramsSelector);
    const { channel } = appContext || {};
    useEffect(() => {
        if (channel?.config) {
            actions.fetchedParams(channel.config);
        }
    }, [channel?.config, actions]);
    return (<ParamsContext.Provider value={params}>{children}</ParamsContext.Provider>);
};
