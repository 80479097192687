import { DEFAULT_PER_PAGE } from '@core/widgets/common';
/**
 *
 */
export const createEmbedFeedState = () => ({
    autoplayId: null,
    pausedAutoplayId: null,
    player: {
        isOpen: false,
        video: null,
    },
    inViewportIdsReportQueue: [],
    inViewportIdsReportSuccess: [],
    inViewportIds: {},
    thumbnailIdsLoaded: {},
    thumbnailImpressionIdsReportSuccess: {},
    thumbnailImpressionVideoReportQueue: [],
    placeholders: [...new Array(DEFAULT_PER_PAGE)],
});
