/**
 *
 */
export function createShoppingState() {
    return {
        cart: [],
        cartCurrency: '',
        cartUrl: '',
        cartAddToCartText: '',
        cartItemInCartText: '',
        productsHydrated: {},
        pendingCartUpdate: 0,
        lastCartUpdateTs: 0,
    };
}
