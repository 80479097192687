import { decodeUrl } from 'compact-base64';
import { isTruthy } from '@core/helpers/boolean';
import { logError } from '@core/logging';
import { pruneNilValues } from '@core/object';
import { SHARE_LINK_PARAM, WHITELIST_KEYS } from '@core/sharing/constants';
import { parseQueryParams } from '@core/window';
/**
 * URL params will be translated to standard Programmatic
 * player attributes.
 */
const TRANSLATE_KEYS = {
    mode: ['fw_mode'],
    channel: ['fw_channel'],
    channel_id: ['fw_channel_id'],
    video_id: ['fw_video', 'fw_video_id'],
    playlist_id: ['fw_playlist', 'fw_playlist_id'],
    hashtag: ['fw_hashtag'],
    loop: ['fw_loop', 'repeat'],
    start_time: ['fw_start_time', 'start_time', 'starts_at'],
};
const CAST_TYPES = {
    start_time: parseFloat,
    loop: (value) => isTruthy(value),
};
/**
 * Parse player params from "fwplayer" location's hash fragment
 * or querystring (Sharelink v1)
 * @param w
 */
function parseV1(w) {
    try {
        const base64data = w.location.hash.match(new RegExp(`${SHARE_LINK_PARAM}=(?<base64data>[a-zA-Z0-9_]+)`))?.groups?.base64data ||
            parseQueryParams(w.location.search)[SHARE_LINK_PARAM];
        if (base64data) {
            const data = JSON.parse(decodeUrl(base64data));
            return data.video_id ? data : undefined;
        }
    }
    catch (err) {
        // Some very old browsers doesn't support named group, and we do not support those browsers.
        return undefined;
    }
}
/**
 * Parse player params for "Universal link" either from
 * location's hash fragment or querystring.
 * @param input
 */
function parseV2(input) {
    const inputObj = parseQueryParams(input.replace(/^[#|?]/, ''));
    const whitelistedEntries = Object.fromEntries(Object.entries(inputObj).filter(([key]) => WHITELIST_KEYS.includes(key)));
    const data = pruneNilValues(Object.fromEntries(Object.entries(TRANSLATE_KEYS).map(([key, transArr]) => {
        const value = transArr.reduce((prev, curr) => {
            return whitelistedEntries[curr] ?? prev;
        }, undefined);
        const castFn = CAST_TYPES[key];
        return [key, value && castFn ? castFn(value) : value];
    })));
    if (data?.video_id) {
        data.v = 2;
        return data;
    }
}
/**
 *
 */
export const parseShareLinkParams = (w) => {
    try {
        return parseV1(w) ?? parseV2(w.location.hash) ?? parseV2(w.location.search);
    }
    catch (err) {
        logError(err);
    }
    return undefined;
};
