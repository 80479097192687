import isEmpty from '@core/lodash/isEmpty';
import { pruneNilValues } from '@core/object';
import { setCookieWithFallback } from '@core/tracking/funnelTracking';
import { FWREFERRER_KEY } from './constant';
import { parseFWReferrerFromURL } from './helpers';
/**
 * Persisting `fwreferrer` values from given url
 * in a localStorage with default expiration
 */
export function persistFWReferrer(url) {
    const fwreferrer = pruneNilValues(parseFWReferrerFromURL(url));
    if (!isEmpty(fwreferrer)) {
        setCookieWithFallback(FWREFERRER_KEY, JSON.stringify(fwreferrer));
    }
    return fwreferrer;
}
