import { uuidv4 } from '@expPlatform/embeds/embed/helpers';
/**
 * Base class for all web components.
 */
export class BaseElement extends HTMLElement {
    constructor() {
        super(...arguments);
        this.widgetId = uuidv4();
    }
    /**
     *
     */
    connectedCallback() {
        this.createRenderRoot();
        this.render();
    }
    /**
     *
     */
    disconnectedCallback() { }
    /**
     *
     */
    createRenderRoot() {
        if (!this.shadowRoot) {
            this.attachShadow({ mode: 'open' });
        }
    }
    /**
     *
     */
    render() { }
}
