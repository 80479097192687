import { instanceGetter } from './instance';
/**
 * Initialize cookies module for FWN.
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'cookies')) {
        Object.defineProperty(window._fwn, 'cookies', {
            get: instanceGetter,
        });
    }
}
