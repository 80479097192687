/**
 * @fileoverview Global CSS for FW custom components.
 * Uses `<Global />` from emotion to inject CSS to custom
 * component Shadow DOM root.
 */
import { css, Global } from '@emotion/react';
import { GLOBAL_COL_STYLE, GLOBAL_ROW_STYLE, } from '@core/components/Layout/Grid/GlobalGridCSS';
import { CSS_COLORS } from '@core/styles/colors';
import { FONT_FAMILY } from '@core/styles/cssVariables';
import { CSS_RESET } from '@core/styles/reset';
import { loadTheme } from '@core/styles/themes';
import { cssVar, cssVarsFromParams, cssVarsFromTheme } from '@core/styles/tools';
import { VARIABLES as SHARED } from '@core/styles/variables';
import { VARIABLES as ADD_TO_CALENDAR } from './variables/addToCalendar';
import { VARIABLES as EMBED_FEED } from './variables/embedFeed';
import { VARIABLES as HERO_UNIT } from './variables/heroUnit';
import { VARIABLES as SHARE } from './variables/share';
import { VARIABLES as STORYBLOCK } from './variables/storyblock';
/**
 *
 */
export function GlobalCustomCSS(props) {
    const { params, extra = '', scope = ':host' } = props;
    const theme = loadTheme(params.theme);
    return (<>
      <Global styles={css `
          ${scope} {
            ${CSS_RESET}
            ${CSS_COLORS}

            display: block;
            font-family: var(${FONT_FAMILY}, inherit);

            // Important to never exceed wrapping container size
            max-width: 100%;
            max-height: 100%;

            /* Process variables from params (channel remote settings) into desired format */
            ${cssVarsFromParams(params)}

            /* Process theme variables into desired format */
            ${cssVarsFromTheme(theme)}

            /* Shared global variables supposed to be used by all Zeffo products */
            ${SHARED.map((args) => cssVar(...args)).join('\n')}

            /* Widget specific variables */
            ${ADD_TO_CALENDAR.map((args) => cssVar(...args)).join('\n')}
            ${EMBED_FEED.map((args) => cssVar(...args)).join('\n')}
            ${SHARE.map((args) => cssVar(...args)).join('\n')}
            ${STORYBLOCK.map((args) => cssVar(...args)).join('\n')}
            ${HERO_UNIT.map((args) => cssVar(...args)).join('\n')}

            ${GLOBAL_COL_STYLE}
            ${GLOBAL_ROW_STYLE}
            ${extra ?? ''}
          }
        `}/>
    </>);
}
