import { storyblockRender } from '@embed/storyblockRender';
import { FWNElement } from './bases/FWNElement';
class StoryblockElement extends FWNElement {
    constructor() {
        super(...arguments);
        this.height = '75vh';
        this.width = '100%';
    }
    render() {
        const params = {
            height: this.height,
            width: this.width,
            ...this.getAttributes(),
        };
        const computedHeight = parseInt(getComputedStyle(this).height);
        const computedWidth = parseInt(getComputedStyle(this).width);
        this.style.height = computedHeight
            ? getComputedStyle(this).height
            : params.height;
        this.style.width = computedWidth
            ? getComputedStyle(this).width
            : params.width; // Explicitly set width to force wrapping flex-box to respect it.
        if (this.shadowRoot) {
            storyblockRender({
                target: this.shadowRoot,
                widgetId: this.widgetId,
                widgetParams: params,
            });
        }
    }
}
if (!customElements.get('fw-storyblock')) {
    customElements.define('fw-storyblock', StoryblockElement);
}
