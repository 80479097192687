/**
 * @fileoverview
 *
 * Implementation of localStorage as a map of `StorageMemoryCache`
 * instances for consent level.
 */
import { StorageMemoryCache } from '../classes/StorageMemoryCache';
import { StorageConsentLevel, StorageKeyEssentialEnum, StorageKeyFunctionalEnum, StorageKeyPerformanceEnum, StorageKeyTargetingEnum, } from '../types';
import { createProxy } from './proxy';
let storage;
try {
    // eslint-disable-next-line zeffo/no-window-storage
    storage = typeof window !== 'undefined' ? window.localStorage : undefined;
}
catch (e) { }
export const mapping = {
    [StorageConsentLevel.NOOP]: [],
    [StorageConsentLevel.ESSENTIAL]: [
        Object.values(StorageKeyEssentialEnum),
        storage,
    ],
    [StorageConsentLevel.FUNCTIONAL]: [
        Object.values(StorageKeyFunctionalEnum),
        storage,
    ],
    [StorageConsentLevel.PERFORMANCE]: [
        Object.values(StorageKeyPerformanceEnum),
        storage,
    ],
    [StorageConsentLevel.TARGETING]: [
        Object.values(StorageKeyTargetingEnum),
        storage,
    ],
};
export const localStorages = createProxy(StorageMemoryCache, mapping);
