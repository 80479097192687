/**
 * Get button details
 * @param api
 * @param params
 * @param abortSignal
 * @returns
 */
export async function getSmartButton(api, params, abortSignal) {
    const response = await api.get(`/api/smart_buttons`, {
        params,
        signal: abortSignal,
    });
    if (!response.data || response.status !== 200) {
        throw new Error(`${response.status}`);
    }
    return response.data;
}
