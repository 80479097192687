// Regex to remove prefixes from variable names.
const CLEAN_REGEX = new RegExp('(^--fw-|^--params-|^--theme-|^--)', 'ig');
/**
 * Clean up the variable name to remove prefixes.
 * @param name
 * @returns
 */
export function cleanVarName(name) {
    return name.replace(CLEAN_REGEX, '');
}
/**
 * Transform UI params into `--params-` prefixed CSS variables they represent.
 * @param params
 * @returns
 */
export function cssVarsFromParams(params) {
    const { ui_font_css, ui_primary_color, ui_button_color, ui_button_font_color, } = params;
    return `
    ${ui_font_css ? `@import url('${ui_font_css}');` : ''}    
    ${ui_primary_color ? `--params-button-color: ${ui_primary_color};` : ''}
    ${ui_primary_color ? `--params-primary: ${ui_primary_color};` : ''}
    ${ui_primary_color
        ? `--params-gradient: linear-gradient(${ui_primary_color}, ${ui_primary_color});`
        : ''}
    ${ui_button_color ? `--params-button-color: ${ui_button_color};` : ''}
    ${ui_button_font_color
        ? `--params-button-font-color: ${ui_button_font_color};`
        : ''}
  `;
}
/**
 * Build a theme CSS declarations by transforming theme into `--theme-` prefixed CSS variables.
 * @param theme
 * @returns
 */
export function cssVarsFromTheme(theme) {
    return `
    ${theme.variables
        .filter(([, value]) => !!value)
        .map(([name, value]) => `--theme-${cleanVarName(name)}: ${value};`)
        .join('\n')}

      ${theme.scheme?.light
        ? `@media (prefers-color-scheme: light) {
        ${theme.scheme.light
            .filter(([, value]) => !!value)
            .map(([name, value]) => `--theme-${cleanVarName(name)}: ${value};`)
            .join('\n')}
      }`
        : ''}

      ${theme.scheme?.dark
        ? `@media (prefers-color-scheme: dark) {
        ${theme.scheme.dark
            .filter(([, value]) => !!value)
            .map(([name, value]) => `--theme-${cleanVarName(name)}: ${value};`)
            .join('\n')}
      }`
        : ''}
  `;
}
/**
 * Builds a CSS variable declaration with fallbacks.
 * Follows the precedence of
 *  1. `--fw-` prefix for Firework variables declared outside of the widget,
 *  2. `--params-` prefix for params variables received from channel settings, and
 *  3. `--theme-` prefix for theme variables.
 *  4. fallback
 *
 * Internal option means `--fw-` and `--params-` prefixes will be skipped.
 *
 * Resulting CSS declaration will look like this:
 * `--<name>: var(--fw-<name>, var(--params-<name>, var(--theme-<name>, <fallback>)));`
 */
export function cssVar(name, options = {}) {
    name = cleanVarName(name);
    const { fallback, external } = options;
    function cssVarString(name, fallback) {
        return `var(${name}${fallback ? `, ${fallback}` : ''})`;
    }
    const names = [];
    if (external) {
        names.push(`--fw-${name}`);
        names.push(`--params-${name}`);
    }
    names.push(`--theme-${name}`);
    return `--${name}: ${names
        .reverse()
        .reduce((acc, name) => cssVarString(name, acc), fallback ? fallback : '')};`;
}
