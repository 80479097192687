/**
 *
 */
export const isVideoCollectionFeed = (feed) => {
    return Boolean(feed && 'playlists' in feed);
};
/**
 *
 */
export const isVideoFeed = (feed) => {
    return Boolean(feed && 'feed_items' in feed);
};
