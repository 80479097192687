/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'renderScreenReaderAlert')) {
        Object.defineProperty(window._fwn, 'renderScreenReaderAlert', {
            value: async (...args) => {
                const { renderScreenReaderAlert, renderScreenReaderAlertsStyles } = await import('@core/accessibility/screenReader/renderScreenReaderAlerts');
                renderScreenReaderAlertsStyles();
                renderScreenReaderAlert(...args);
            },
            writable: false,
        });
    }
}
