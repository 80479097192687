import { logError } from '@core/logging';
/**
 * Set the href for current browser window.
 * @param href
 */
export const setWindowHref = (href) => {
    // Assigning to href and calling replaceState together to change the
    // href value immediately so additional effects gets updated value.
    window.location.href = href;
    try {
        const url = new URL(href);
        // if the href contains a fragment, we need to force the reload.
        // browsers will not actually navigate if the only difference between
        // the current location.href and the new value is the presence of a fragment
        if (url.hash) {
            window.location.reload();
        }
    }
    catch (e) {
        logError(new Error(`Failed to parse href, "${href}" is not a valid URL`));
        window.location.reload();
    }
};
