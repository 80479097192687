/**
 * @fileoverview
 *
 * Picture-in-Picture (PIP) is a feature that allows the Player to continue seamless playback
 * while allowing user to interact with the original page in a PIP iframe.
 */
import { enterPIP, exitPIP, hasPIPIframe, isInPIPIframe, } from '@core/pictureInPicture';
/**
 * PIPController is a class that provides a public interface to enter and exit PIP mode.
 */
export class PIP {
    /**
     * Enter the PIP mode with the given URL.
     * @param url
     */
    enter(url, __testOnlyOptions) {
        const src = url || window.location.href;
        const scrollY = url ? 0 : window.scrollY;
        enterPIP({
            src,
            scrollY,
            title: 'Firework Picture-in-picture IFrame',
            ...__testOnlyOptions,
        });
    }
    /**
     * Exit the PIP mode.
     */
    exit() {
        exitPIP();
    }
    /**
     * Check if the PIP mode is active.
     */
    active() {
        return isInPIPIframe() || hasPIPIframe();
    }
}
