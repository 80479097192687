import flatten from '@core/lodash/flatten';
import uniq from '@core/lodash/uniq';
import { ProductImageBuilder } from './productImageBuilder';
import { ProductUnitBuilder } from './productUnitBuilder';
import { generateId } from './utils';
/**
 *
 */
export class ProductBuilder {
    /**
     *
     */
    constructor(verifyRequiredFields = false) {
        this.verifyRequiredFields = verifyRequiredFields;
        this.product = {
            business_store_id: 0,
            business_store_name: '',
            business_store_uid: '',
            custom_cta_target: '',
            custom_cta_title: null,
            custom_cta_title_translation: null,
            custom_cta_url: null,
            hide_primary_cta: false,
            id: generateId(),
            product_currency: '',
            product_currency_symbol: '',
            product_description: '',
            product_subtitle: '',
            product_handle: '',
            product_hide_price: false,
            product_id: generateId(),
            product_ext_id: '',
            product_images: [],
            product_name: '',
            product_options: [],
            product_shipping: '',
            selected_product_unit_id: '',
            product_units: [],
            vendor_id: '',
            vendor_name: '',
            hidden: false,
            available_for_live_stream_replay: true,
        };
    }
    /**
     * @deprecated Use `extId` instead.
     */
    ext_id(value) {
        // Need to show a warning message to the external developer.
        // eslint-disable-next-line no-console
        console.warn('FW Product Builder: `ext_id` is deprecated. Use `extId` instead.');
        return this.extId(value);
    }
    /**
     *
     */
    extId(value) {
        // Still parse it because client code might not comply with the type.
        this.product.product_ext_id = String(value);
        return this;
    }
    /**
     *
     */
    name(value) {
        this.product.product_name = value;
        return this;
    }
    /**
     *
     */
    description(value) {
        this.product.product_description = value;
        return this;
    }
    /**
     *
     */
    subtitle(value) {
        this.product.product_subtitle = value;
        return this;
    }
    /**
     *
     */
    currency(value) {
        this.product.product_currency = value;
        return this;
    }
    /**
     *
     */
    variant(callback) {
        const unitBuilder = new ProductUnitBuilder(this.verifyRequiredFields);
        callback(unitBuilder);
        const unit = unitBuilder.build();
        // Merges unit images with product images. Units only have references to the
        // product image but do not hold images themselves.
        this.product.product_images.push(...unitBuilder.getImages());
        // Backfill unit product id and ext id.
        unit.product_id = this.product.product_id;
        unit.product_ext_id = this.product.product_ext_id;
        this.product.product_units = this.product.product_units ?? [];
        // Updates existing unit if it exists. Pushes new unit otherwise.
        const existingUnitIndex = this.product.product_units.findIndex((u) => u.unit_ext_id === unit.unit_ext_id);
        if (existingUnitIndex !== -1) {
            this.product.product_units[existingUnitIndex] = {
                ...this.product.product_units[existingUnitIndex],
                ...unit,
            };
        }
        else {
            this.product.product_units.push(unit);
        }
        return this;
    }
    /**
     *
     */
    image(callback) {
        const imageBuilder = new ProductImageBuilder();
        callback(imageBuilder);
        const image = imageBuilder.build();
        image.image_alt_text ?? (image.image_alt_text = this.product.product_name);
        this.product.product_images.push(image);
        return this;
    }
    /**
     * Sets product_handle
     */
    handle(value) {
        // Still parse it because client code might not comply with the type.
        this.product.product_handle = String(value);
        return this;
    }
    /**
     *
     */
    build() {
        this.validate();
        // Extract options from product units and make it an array of unique option
        // keys.
        // unit1: { Size: 'S', Color: 'Red' }
        // unit2: { Size: 'M', Color: 'Red' }
        // => product_options: ['Size', 'Color']
        this.product.product_options = uniq(flatten(this.product.product_units
            .map((u) => u.unit_options[0])
            .filter(Boolean)
            .map((o) => Object.keys(o))));
        if (!this.product.product_ext_id) {
            // Backfill due to legacy code. Client should always hydrate product with an
            // external id.
            this.product.product_ext_id = generateId();
        }
        return this.product;
    }
    /**
     *
     */
    validate() {
        const missingFields = [];
        if (!this.product.product_ext_id) {
            missingFields.push('extId');
        }
        if (this.verifyRequiredFields) {
            if (!this.product.product_name) {
                missingFields.push('name');
            }
            if (!this.product.product_description) {
                missingFields.push('description');
            }
            // Talk with Zero, this doesn't seem necessary
            // if (!this.product.product_subtitle) {
            //   missingFields.push('subtitle')
            // }
            if (!this.product.product_currency) {
                missingFields.push('currency');
            }
            if (!this.product.product_units.length) {
                missingFields.push('variant');
            }
        }
        if (missingFields.length > 0) {
            // Needed to show warnings to the external developer.
            // eslint-disable-next-line no-console
            console.warn(`FW Product Builder: Product is missing required fields: ${missingFields.join(', ')}`);
        }
    }
}
