import { devtools } from 'zustand/middleware';
/**
 * Adds Redux devtools.
 * https://github.com/pmndrs/zustand#redux-devtools
 */
export const addDevtool = (creator) => {
    return devtools(creator, {
        name: 'FWN EmbedFeed Widget',
    });
};
