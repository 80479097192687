/**
 * @fileoverview
 * This file contains all the functions that are used to retrieve data from the
 * Embed feed API.
 */
/**
 * Retrieves a channel by username
 * @param api
 * @returns
 */
export async function getChannel(api, username) {
    const response = await api.get(`/embed/v2/channels/${encodeURIComponent(username)}`);
    return response.data;
}
/**
 * Retrieves a channel by id
 * @param api
 * @returns
 */
export async function getChannelById(api, encodedId) {
    const response = await api.get(`/api/channels/by_id/${encodeURIComponent(encodedId)}`);
    return response.data;
}
/**
 *
 * @param username
 * @param pageSize
 * @param firstVideoId
 * @returns
 */
export async function createEmbedUserTimelineFeed(api, username, pageSize, firstVideoId) {
    const url = new URL(`${api.defaults.baseURL}/embed/v2/channels/${username}/timeline_feeds`);
    pageSize && url.searchParams.append('page_size', `${pageSize}`);
    firstVideoId && url.searchParams.append('first_video_id', firstVideoId);
    const response = await api.post(url.href);
    return response.data;
}
/**
 *
 * @param api
 * @param playlistId
 * @param pageSize
 * @param firstVideoId
 * @returns
 */
export async function createEmbedPlaylistFeed(api, playlistId, pageSize, firstVideoId) {
    const url = new URL(`${api.defaults.baseURL}/embed/v2/playlists/${playlistId}/feeds`);
    pageSize && url.searchParams.append('page_size', `${pageSize}`);
    firstVideoId && url.searchParams.append('first_video_id', firstVideoId);
    const response = await api.post(url.href);
    return response.data;
}
/**
 *
 * @param api
 * @param username
 * @param hashtagExpression
 * @param pageSize
 * @param firstVideoId
 * @returns
 */
export async function createEmbedUserTimelineHashtagFeed(api, username, hashtagExpression, pageSize, firstVideoId) {
    const url = new URL(`${api.defaults.baseURL}/embed/v2/channel_hashtags/${username}/feeds`);
    pageSize && url.searchParams.append('page_size', `${pageSize}`);
    const response = await api.post(url.href, {
        hashtag_filter_expression: hashtagExpression ? hashtagExpression : '',
        first_video_id: firstVideoId ? firstVideoId : '',
    });
    return response.data;
}
/**
 *
 * @param api
 * @param username
 * @param pageSize
 * @param skus
 * @returns
 */
export async function createEmbedUserProductSKUFeed(api, username, pageSize, skus) {
    const url = new URL(`${api.defaults.baseURL}/embed/v2/channel_skus/${username}/feeds`);
    pageSize && url.searchParams.append('page_size', `${pageSize}`);
    skus && url.searchParams.append('skus', `${skus}`);
    const response = await api.post(url.href);
    return response.data;
}
/**
 *
 * @param api
 * @param id
 * @param pageSize
 * @param firstPlaylistId
 * @returns
 */
export async function createEmbedPlaylistCollectionFeed(api, id, pageSize, firstPlaylistId) {
    const url = new URL(`${api.defaults.baseURL}/embed/v2/playlist_groups/${id}/`);
    pageSize && url.searchParams.append('page_size', `${pageSize}`);
    firstPlaylistId &&
        url.searchParams.append('first_playlist_id', firstPlaylistId);
    const response = await api.get(url.href);
    return response.data;
}
/**
 * Creates a showroom schedule feed.
 * @param {AxiosInstance} api - The AxiosInstance used for making API requests.
 * @param {string} showroomId - The ID of the showroom for which the feed should be created.
 * @return {Promise<Api_Feed>} A promise that resolves to the created showroom schedule feed.
 */
export async function createShowroomScheduleFeed(api, showroomId) {
    const url = new URL(`${api.defaults.baseURL}/embed/v2/showrooms/${showroomId}/feeds`);
    const response = await api.post(url.href);
    return response.data;
}
/**
 * Creates request for next page of given feed
 * @param api
 * @param feed
 * @param pageSize
 * @returns
 */
export async function getMoreFeedItems(api, feed, pageSize) {
    const url = new URL(`${api.defaults.baseURL}${feed.next_page}`);
    pageSize && url.searchParams.append('page_size', `${pageSize}`);
    const response = await api.get(url.href);
    return response.data;
}
