import { ServiceApiContext } from '@core/api/context/serviceApi';
import { useApiWithParams } from '@core/api/hooks/useApi';
import { useStore } from '@core/widgets/store/store';
import { paramsSelector } from '../store/selectors';
/**
 * HOC that wraps children in a context provider delivering the api object.
 */
export const WidgetServiceApiProvider = ({ children }) => {
    const params = useStore(paramsSelector);
    const api = useApiWithParams(params);
    return (<ServiceApiContext.Provider value={api}>
      {children}
    </ServiceApiContext.Provider>);
};
