import createStoreHook from 'zustand';
import { getGlobalStore } from './globalStore';
/**
 * CS-1330: We cannot share hook across binaries. This can cause preact runtime
 * issues if the customer websites load two different Firework scripts.
 */
let useGlobalStore;
/**
 *
 */
export const resetUseGlobalStore = () => {
    useGlobalStore = undefined;
};
/**
 *
 */
export const getGlobalStoreHook = () => {
    if (!useGlobalStore) {
        useGlobalStore = createStoreHook(getGlobalStore());
    }
    return useGlobalStore;
};
