/**
 * Build share link search params (query string)
 * @param params
 * @param preserveSearchParams
 * @returns
 */
export function buildShareLinkSearchParams(params, preserveSearchParams = new URLSearchParams()) {
    const renamedParams = Object.fromEntries(Object.entries({
        playlist: params.playlist_id ?? undefined,
        start_time: params.start_time ?? undefined,
        video: params.video_id ?? undefined,
        ...params,
        playlist_id: null,
        video_id: null,
    })
        .map(([key, value]) => [`fw_${key}`, value])
        .filter(([, value]) => !!value));
    Object.entries(renamedParams).forEach(([key, value]) => {
        preserveSearchParams.set(key, value);
    });
    return preserveSearchParams;
}
