/**
 * A reset CSS which should not harm the existing styles.
 */
export const CSS_RESET = `
  all: initial;

  * {
    box-sizing: border-box;
  }

  video::cue {
    background: transparent;
    visibility: hidden;
    font-size: 0.001px;
  }

  /* Hide arrows from input number: Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide arrows from input number: Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
