/**
 * Channels actions
 */
export function createActions(set) {
    return {
        updateChannel: (channel) => set((state) => ({
            byId: {
                ...state.byId,
                [channel.id ?? channel.encoded_id]: channel,
            },
        }), false, 'updateChannel'),
    };
}
