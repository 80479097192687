import { SHOPPING_SLICE_KEY } from './slice';
/**
 *
 */
export function shoppingCartSelector(state) {
    return state[SHOPPING_SLICE_KEY].cart;
}
/**
 *
 */
export function shoppingCartCurrencySelector(state) {
    return state[SHOPPING_SLICE_KEY].cartCurrency;
}
/**
 *
 */
export function shoppingCartUrlSelector(state) {
    return state[SHOPPING_SLICE_KEY].cartUrl;
}
/**
 *
 */
export function shoppingCartTextSelector(state) {
    return {
        cartAddToCartText: state[SHOPPING_SLICE_KEY].cartAddToCartText,
        cartItemInCartText: state[SHOPPING_SLICE_KEY].cartItemInCartText,
    };
}
/**
 *
 */
export function shoppingActionsSelector(state) {
    return state[SHOPPING_SLICE_KEY].actions;
}
/**
 *
 */
export function productsHydratedSelector(state) {
    return state[SHOPPING_SLICE_KEY].productsHydrated;
}
/**
 *
 */
export function cartUpdatedCallbackSelector(state) {
    return state[SHOPPING_SLICE_KEY].cartUpdatedCallback;
}
/**
 *
 */
export function cartDisplayedCallbackSelector(state) {
    return state[SHOPPING_SLICE_KEY].cartDisplayedCallback;
}
/**
 *
 */
export function productsLoadedCallbackSelector(state) {
    return state[SHOPPING_SLICE_KEY].productsLoadedCallback;
}
/**
 *
 */
export function hasPendingCartUpdateSelector(state) {
    return state[SHOPPING_SLICE_KEY].pendingCartUpdate > 0;
}
/**
 *
 */
export function lastCartUpdateSelector(state) {
    return state[SHOPPING_SLICE_KEY].lastCartUpdateTs;
}
