import create from 'zustand';
import { isDebug } from '@core/debug';
import { createContext } from '@core/zustand';
import { createEmbedFeedActions } from './actions';
import { addDevtool } from './helpers';
import { createEmbedFeedState } from './state';
/**
 * Creates a zustand store for EmbedFeed widget
 *
 * @returns A zustand store
 */
export const createStore = () => {
    let createState = (set, get) => ({
        ...createEmbedFeedState(),
        actions: createEmbedFeedActions(set, get),
    });
    // Note: devtool must be configured before other middleware.
    if (isDebug()) {
        createState = addDevtool(createState);
    }
    return create(createState);
};
export const { Provider, useStore, useStoreApi } = createContext();
