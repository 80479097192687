import { lens } from '@dhmk/zustand-lens';
import { createGatedVideoActions } from './actions';
import { createGatedVideoState } from './state';
export const GATED_VIDEO_SLICE_KEY = 'gatedVideo';
/**
 * Creates a slice of the global state for gated video.
 */
export function createGatedVideoSlice() {
    return {
        [GATED_VIDEO_SLICE_KEY]: lens((set) => ({
            ...createGatedVideoState(),
            actions: createGatedVideoActions(set),
        })),
    };
}
