import { useCallback, useState } from 'react';
import { useContextTranslation } from '@core/i18n';
import { useSessionStorage } from '@core/storage/hooks';
import { ABLE_TO_IFRAMEIZE } from './constants';
import { enterPIP, exitPIP } from './controls';
import { isInPIPIframe } from './isInPIPIframe';
import { setWindowHref } from './setWindowHref';
/**
 *  Hook providing the Picture-in-Picture (PIP) controls and state.
 */
export const usePIPControls = (props) => {
    const { pip_observe: observeDOM, pip_outbound_target: outboundTarget } = props.params;
    const enabled = props.enabled && !isInPIPIframe();
    const { t } = useContextTranslation();
    const [isAbleToIframize, setIsAbleToIframize] = useSessionStorage(ABLE_TO_IFRAMEIZE, true);
    const [isIframized, setIsIframized] = useState(false);
    const [latestHref, setLatestHref] = useState(window.location.href);
    const onLocationChange = useCallback((href) => {
        setLatestHref(href);
    }, []);
    const onLoad = useCallback(() => {
        setIsIframized(true);
    }, []);
    const onError = useCallback(() => {
        setIsIframized((previous) => {
            if (previous === false) {
                setIsAbleToIframize(false);
            }
            return false;
        });
    }, [setIsAbleToIframize]);
    const enter = useCallback((href) => {
        const src = href || window.location.href;
        const scrollY = href !== window.location.href ? 0 : window.scrollY;
        if (isAbleToIframize && enabled) {
            enterPIP({
                onError,
                onLoad,
                onLocationChange,
                options: { observeDOM, outboundTarget },
                scrollY,
                src,
                title: t('player:Picture in Picture frame'),
            });
        }
        else if (src !== window.location.href) {
            setWindowHref(src);
        }
    }, [
        isAbleToIframize,
        enabled,
        onError,
        onLoad,
        onLocationChange,
        observeDOM,
        outboundTarget,
        t,
    ]);
    const exit = useCallback(() => {
        exitPIP();
    }, []);
    return {
        isIframized,
        isAbleToIframize,
        latestHref,
        enter,
        exit,
    };
};
