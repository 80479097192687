/**
 * @fileoverview
 *
 * Hook resolving proper appContext based on a type.
 * Channel config is loaded here via useChannel hook.
 *
 * TODO(CS-1468): Refactor useEmbedAppContext hook to update the state instead of returning the value
 */
import { useMemo } from 'react';
import { useAdConfigStorage } from '@ads/hooks/useAdConfigStorage';
import { useChannel } from '@core/channel';
import { getGuestId } from '@core/tracking';
import { useSetBusinessId } from '@core/tracking/funnelTracking';
import { useStore } from '@core/widgets/store';
import { paramsSelector } from '@core/widgets/store/selectors';
import { uuidv4 } from '@embed/helpers';
import { use50PercentTraffic } from '@embed/hooks/subscriptionFlags/use50PercentTraffic';
import { getAppContext } from './getAppContext';
/**
 * Hook returning appContext. Fetches channel and
 * ad config data if needed.
 */
export const useEmbedAppContext = ({ config, appContextType, extraParams, }) => {
    const storeParams = useStore(paramsSelector);
    const params = useMemo(() => {
        return {
            ...storeParams,
            ...extraParams,
        };
    }, [extraParams, storeParams]);
    const channel = useChannel({
        username: params.channel,
        encodedId: params.channel_id,
        target: params.target,
    });
    use50PercentTraffic(channel);
    useSetBusinessId(channel?.business_id);
    const adConfig = useAdConfigStorage(params);
    const embedInstanceId = useMemo(uuidv4, []);
    return useMemo(() => {
        if (!config) {
            return null;
        }
        const guestId = getGuestId();
        const appContext = getAppContext({
            embedFeedConfig: config,
            appContextType,
            channel,
            embedInstanceId,
            params,
            guestId,
            adConfig,
        });
        return appContext;
    }, [config, appContextType, channel, embedInstanceId, params, adConfig]);
};
