import { CookiesCache } from '../classes/CookiesCache';
import { StorageMemoryCache } from '../classes/StorageMemoryCache';
import { StorageConsentLevel } from '../types';
/**
 * Create proxy for storage instances. Instance for given consent level
 * will be lazy-loaded and cached on demand.
 * @param cls
 * @param mapping
 * @returns
 */
export function createProxy(cls, mapping) {
    const handler = {
        get: (cache, prop) => {
            if (!cache[prop]) {
                if (!mapping[prop]) {
                    return undefined;
                }
                if (cls === StorageMemoryCache) {
                    const obj = new cls(...mapping[prop]);
                    cache[prop] = obj;
                }
                if (cls === CookiesCache) {
                    cache[prop] = new cls(...mapping[prop]);
                }
            }
            return cache[prop];
        },
    };
    return new Proxy({
        [StorageConsentLevel.NOOP]: undefined,
        [StorageConsentLevel.ESSENTIAL]: undefined,
        [StorageConsentLevel.FUNCTIONAL]: undefined,
        [StorageConsentLevel.PERFORMANCE]: undefined,
        [StorageConsentLevel.TARGETING]: undefined,
    }, handler);
}
