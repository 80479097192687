import { useMemo } from 'react';
import { FeedType } from '@core/feed';
import { filterTruthy } from '@core/helpers/filterTruthy';
import { AppContextType } from '@embed/components/helpers';
/**
 * Hook to map params to embed feed configuration which is
 * used to configure API calls
 */
export const useMapParamsToConfiguration = ({ appContextType, params, channel, }) => {
    const hasCohorts = useMemo(() => Object.keys(params).some((param) => param.startsWith('cohort_')), [params]);
    return useMemo(() => {
        const defaultValues = {
            firstVideoId: params.video || params.video_id,
            maxVideos: 
            // Ignore the max videos for carousel in grid mode
            appContextType === AppContextType.EMBED_GRID_CONTEXT_TYPE
                ? undefined
                : params.max_videos,
        };
        if (params.channel || params.channel_id || params.showroom_id) {
            if (params.showroom_id) {
                return [
                    {
                        ...defaultValues,
                        type: FeedType.SHOWROOM_SCHEDULE,
                        showroomId: params.showroom_id,
                    },
                ];
            }
            if (params.channel_id && !channel) {
                return null;
            }
            if (!(params.hashtag ||
                params.playlist ||
                params.playlist_collection ||
                params.skus ||
                hasCohorts)) {
                return [
                    {
                        ...defaultValues,
                        type: FeedType.USER_TIMELINE,
                        username: params.channel ?? channel?.username,
                    },
                ];
            }
            const configs = params.priority?.split(',').map((param) => {
                const trimmed = param.trim();
                switch (trimmed) {
                    case 'hashtag':
                        return params.hashtag
                            ? {
                                ...defaultValues,
                                type: FeedType.USER_TIMELINE_HASHTAG,
                                username: params.channel ?? channel?.username,
                                hashtagExpression: params.hashtag,
                            }
                            : null;
                    case 'playlist':
                        return params.playlist
                            ? {
                                ...defaultValues,
                                type: FeedType.PLAYLIST,
                                username: params.channel ?? channel?.username,
                                playlistId: params.playlist,
                            }
                            : null;
                    case 'playlist_collection':
                        return params.playlist_collection
                            ? {
                                ...defaultValues,
                                type: FeedType.PLAYLIST_COLLECTION,
                                username: channel?.username,
                            }
                            : null;
                    case 'skus':
                        return params.skus
                            ? {
                                ...defaultValues,
                                type: FeedType.PRODUCT_SKU,
                                username: params.channel ?? channel?.username,
                                skus: params.skus,
                            }
                            : null;
                    case 'channel':
                        return {
                            ...defaultValues,
                            type: FeedType.USER_TIMELINE,
                            username: params.channel ?? channel?.username,
                        };
                    default:
                        return null;
                }
            }) ?? [];
            return filterTruthy(configs);
        }
        return null;
    }, [
        params.video,
        params.video_id,
        params.max_videos,
        params.channel,
        params.channel_id,
        params.hashtag,
        params.playlist,
        params.playlist_collection,
        params.skus,
        params.priority,
        params.showroom_id,
        appContextType,
        channel,
        hasCohorts,
    ]);
};
