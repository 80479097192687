/**
 *
 */
export async function attach() {
    const propName = 'abTesting';
    const { addTest, allTests, getABTestsForTracking } = await import('@core/abTesting/index');
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, propName)) {
        Object.defineProperty(window._fwn, propName, {
            get: () => ({ addTest, allTests, getABTestsForTracking }),
        });
    }
}
