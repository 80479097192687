import { FWNElement } from './bases/FWNElement';
class ShareElement extends FWNElement {
    render() {
        import('@embed/shareRender').then(({ shareRender }) => {
            if (this.shadowRoot) {
                shareRender({
                    target: this.shadowRoot,
                    widgetId: this.widgetId,
                    widgetParams: this.getAttributes(),
                });
            }
        });
    }
}
if (!customElements.get('fw-share')) {
    customElements.define('fw-share', ShareElement);
}
