import { useCallback, useEffect, useState } from 'react';
import isEqual from '@core/lodash/isEqual';
/**
 *
 */
const read = (storage) => (key) => {
    if (typeof window === 'undefined') {
        return undefined;
    }
    try {
        const value = storage.getItem(key);
        if (!value) {
            return;
        }
        return JSON.parse(value);
    }
    catch (error) {
        // Storage access blocked or json parse failed
        return;
    }
};
/**
 *
 */
const write = (storage) => (key, value) => {
    if (typeof window === 'undefined') {
        return;
    }
    try {
        storage.setItem(key, JSON.stringify(value));
    }
    catch (error) {
        // Storage access blocked or json stringify failed
    }
};
/**
 *
 */
const remove = (storage) => (key) => {
    if (typeof window === 'undefined') {
        return;
    }
    try {
        storage.removeItem(key);
    }
    catch (err) { }
};
/**
 *
 */
export function useStorage(key, initial, opts) {
    const { storage } = opts;
    const resolveStorageValue = useCallback(() => {
        const storedValue = read(storage)(key);
        if (storedValue !== undefined) {
            return storedValue;
        }
        return initial;
    }, [initial, key, storage]);
    const [value, setLocalValue] = useState(resolveStorageValue);
    useEffect(() => {
        setLocalValue((value) => {
            const resolved = resolveStorageValue();
            /**
             * `useStorage` hook handles equality using `lodash.isEqual`
             * so reference types like [] or {} are compared deeply.
             * Using === equality would cause infinite loop.
             */
            if (isEqual(resolved, value)) {
                return value;
            }
            return resolved;
        });
    }, [resolveStorageValue]);
    const setValue = useCallback((value, { skipUpdates = false } = {}) => {
        write(storage)(key, value);
        !skipUpdates && setLocalValue(value);
    }, [key, storage]);
    const removeValue = useCallback(() => remove(storage)(key), [key, storage]);
    return [value, setValue, removeValue];
}
