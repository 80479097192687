/**
 * CSS colors used in the app (not sure if all).
 */
export const CSS_COLORS = `
  --blk1: #121212;
  --blk1-rgb: 18 18 18;
  --blk2: #424242;
  --blk2-rgb: 40 40 40;
  --blk3: #505050;
  --blk3-rgb: 80 80 80;
  
  --white: #fff;
  --white-rgb: 255 255 255;
  --red: #fa3b3b;
  --red-rgb: 250 59 59;
  --red2: #f96474;
  --red3: #e90000;
  --red4: #ff4d4f;
  --salmon: #fc8989;
  --orange: #e98c00;
  --orange2: #faad14;
  --green: #2cc056;
  --business-crimson: #f40c70;
  --blue: #0f6bff;

  /* Legacy Gray Colors */
  --gray1: #171717;
  --gray1-rgb: 23 23 23;
  --gray2: #2b2b2b;
  --gray2-rgb: 43 43 43;
  --gray3: #404040;
  --gray3-rgb: 64 64 64;
  --gray4: #9b9b9b;
  --gray4-rgb: 155 155 155;
  --gray5: #bdbdbd;
  --gray5-rgb: 189, 189, 189;
  --gray6: #e2e2e2;
  --gray6-rgb: 226, 226, 226;

  /* Box Shadow */
  --box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  --box-shadow-hover: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  --box-shadow-subtle: 0 1.5px 3px rgba(0, 0, 0, 0.08), 0 1.5px 3px rgba(0, 0, 0, 0.12);
`;
