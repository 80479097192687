/**
 * Yield to main thread in a declarative way. Useful to break script
 * execution of nonessential parts to mitigate long tasks.
 *
 * See https://web.dev/articles/optimize-long-tasks#use_asyncawait_to_create_yield_points
 * for more information.
 *
 * @returns
 */
export function yieldToMain() {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}
