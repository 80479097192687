import { getGlobalConfig } from '@core/globalConfig';
import { sanitizeWidgetParams } from '@core/params/helpers/sanitizeWidgetParams';
import { urlWidgetParams } from '@core/params/helpers/urlWidgetParams';
import { initialState } from '@core/widgets/store';
import { parseQueryParams } from '@core/window';
/**
 * Mixin providing access to params (widget, global, ...).
 * @param superClass
 * @returns
 */
export const ParamsMixin = (superClass) => {
    class ParamsMixinClass extends superClass {
        /**
         * Get all custom element html attributes as a key value map.
         *
         * @returns The attributes key value map.
         */
        getAttributes() {
            const attributes = this.getAttributeNames();
            const result = {};
            for (const attribute of attributes) {
                const value = this.getAttribute(attribute);
                if (value !== null) {
                    result[attribute] = value;
                }
            }
            return result;
        }
        /**
         * Merge default params, global params, custom element attributes and url params.
         */
        get params() {
            return {
                ...initialState.defaultParams,
                ...sanitizeWidgetParams(getGlobalConfig()),
                ...sanitizeWidgetParams(this.getAttributes()),
                ...sanitizeWidgetParams(urlWidgetParams(parseQueryParams())),
            };
        }
    }
    return ParamsMixinClass;
};
