import { postMessageWrapper } from '@core/customEvent';
import { getFWN } from '@core/fwn';
import { PlayerLayout } from '@core/player/types';
import { GLOBAL_PLAYERS_STATE_SYNC, REMOVE_PLAYER_STATE_SYNC, UPDATE_PLAYER_STATE_SYNC, } from '@embed/components/VideoPlayer/constants';
import { openReasonPriority, VideoPlayerOpenReason, } from '@embed/components/VideoPlayer/types';
/**
 *
 */
export function attachListeners() {
    const dispatchGlobalPlayersState = (state) => {
        postMessageWrapper({
            action: GLOBAL_PLAYERS_STATE_SYNC,
            sentinel: 'fwn-internal',
            state,
        });
    };
    const listener = (event) => {
        const playersStateSync = getFWN()?.playersStateSync || {};
        if (event.data?.sentinel === 'fwn-internal') {
            const { action, playerState, widgetId } = event.data;
            switch (action) {
                case UPDATE_PLAYER_STATE_SYNC: {
                    if (!playerState) {
                        return;
                    }
                    const newPriority = openReasonPriority[playerState.openReason];
                    for (const id of Object.keys(playersStateSync)) {
                        if (id === playerState.widget_id) {
                            continue;
                        }
                        if (openReasonPriority[playersStateSync[id].openReason] <= newPriority) {
                            playersStateSync[id].openReason = VideoPlayerOpenReason.NOT_OPENED;
                            playersStateSync[id].layout = PlayerLayout.DEFAULT;
                        }
                    }
                    playersStateSync[playerState.widget_id] = playerState;
                    dispatchGlobalPlayersState(playersStateSync);
                    break;
                }
                case REMOVE_PLAYER_STATE_SYNC: {
                    delete playersStateSync[widgetId];
                    dispatchGlobalPlayersState(playersStateSync);
                    break;
                }
                default:
                    return;
            }
        }
    };
    try {
        window.parent.addEventListener('message', listener);
    }
    catch (e) {
        window.addEventListener('message', listener);
    }
}
