import React from 'react';
/**
 *
 */
export default class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { errored: false };
    }
    /**
     *
     */
    componentDidCatch(error) {
        this.setState({ errored: true });
        this.props.onError && this.props.onError(error);
    }
    /**
     *
     */
    render() {
        if (this.props.discardOnError && this.state.errored) {
            return null;
        }
        return this.props.children;
    }
}
