import { useEffect } from 'react';
import { persistChannelId } from '@core/tracking/funnelTracking';
/**
 *
 */
export const usePersistChannelId = (encodedChannelId) => {
    useEffect(() => {
        if (encodedChannelId) {
            persistChannelId(encodedChannelId);
        }
    }, [encodedChannelId]);
};
