import { THUMBNAIL_SIZE_LARGE } from '@embed/styled';
import testWebpSupport from '@player/utils/testWebpSupport';
let WEBP_SUPPORTED = undefined;
testWebpSupport((result) => {
    WEBP_SUPPORTED = !!result;
});
/**
 * Takes the url to an image and returns a new url with appropriate resizing params,
 * or same url if the image can't be resized. Will return `url` unchanged if
 * `default_avatar` or `default_event_trailer` present
 *
 * @param url - a URL to an image to possibly resize
 * @param options - any resizing options
 */
export const resizeThumbnailUrl = (url, options = {}) => {
    if (!url) {
        return '';
    }
    if (url &&
        (url.match('default_avatar') || url.match('default_event_trailer'))) {
        // User has not set an avatar, the default avatar returned in api cannot be
        // dynamically resized. Same goes with default event trailer poster.
        return url;
    }
    if (url.includes('/000')) {
        url = url.replace('/000/', '/540/');
    }
    const defaults = {
        format: WEBP_SUPPORTED ? 'webp' : 'jpg',
        quality: 62, // deprecated qualities -> [61]
        size: [THUMBNAIL_SIZE_LARGE[0], THUMBNAIL_SIZE_LARGE[1]],
    };
    const { format, quality, size } = {
        ...defaults,
        ...(options || {}),
    };
    let append = '';
    append += quality ? `.${quality}` : '';
    append += format ? `.${format}` : '';
    const match = url.match(/\/transcoded\/(?<width>\d{3,4})\//i);
    let output = '';
    if (size) {
        if (match?.groups?.width) {
            output =
                url.replace(`transcoded/${match.groups.width}/`, `transcoded/${size[0]}_${size[1]}/`) + append;
        }
        else {
            output =
                url.replace(/\/(original|\d{3,4}[_|x]\d{3,4})\//, `/${size[0]}_${size[1]}/`) + append;
        }
    }
    else {
        output = url + append;
    }
    const outputURL = new URL(output);
    return outputURL.toString();
};
