/**
 * @fileoverview
 * Play trigger tracking storage in a simple Map
 */
const playTriggerStorage = new Map();
/**
 * Retrieves the play trigger value for a video from a stored value.
 */
export function getPlayTrigger(widgetId, videoId) {
    return playTriggerStorage.get(`${widgetId}_${videoId}`);
}
/**
 * Sets the play trigger value for a video for later use.
 */
export function setPlayTrigger(widgetId, videoId, type) {
    playTriggerStorage.set(`${widgetId}_${videoId}`, type);
}
export const TEST_ONLY = {
    reset() {
        playTriggerStorage.clear();
    },
};
