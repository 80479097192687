/**
 * Test if value is string
 * @param value
 * @returns
 */
function isString(value) {
    if (value !== null && typeof value?.valueOf() === 'string') {
        return true;
    }
    return false;
}
export default isString;
