/**
 * @returns The shared state actions.
 */
export function sharedStateActionsSelector(state) {
    return state.actions;
}
/**
 * @returns The persisted data value.
 */
export function persistedDataSelector(state, key) {
    return state.persistedData[key];
}
/**
 * @returns The current page load id.
 */
export function pageLoadIdSelector(state) {
    return state.pageLoadId;
}
