/**
 * @fileoverview
 * Object of all cookie storage instances accessible by consent key.
 */
import { CookiesCache } from '../classes/CookiesCache';
import { StorageConsentLevel, StorageKeyEssentialEnum, StorageKeyFunctionalEnum, StorageKeyPerformanceEnum, StorageKeyTargetingEnum, } from '../types';
import { createProxy } from './proxy';
const consentLevelToStorageKeysEnum = {
    [StorageConsentLevel.NOOP]: [],
    [StorageConsentLevel.ESSENTIAL]: [Object.values(StorageKeyEssentialEnum)],
    [StorageConsentLevel.FUNCTIONAL]: [Object.values(StorageKeyFunctionalEnum)],
    [StorageConsentLevel.PERFORMANCE]: [Object.values(StorageKeyPerformanceEnum)],
    [StorageConsentLevel.TARGETING]: [Object.values(StorageKeyTargetingEnum)],
};
export const cookiesStorages = createProxy(CookiesCache, consentLevelToStorageKeysEnum);
