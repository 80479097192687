import { EnumFunnelTrackingCookies, getCookieWithFallback, removeCookieAndFallback, setCookieWithFallback, } from '@core/tracking/funnelTracking';
import { uuidv4 } from '@embed/helpers';
/**
 * Memory storage for guest id to not make it read from cookies
 * every time getGuestId is called
 */
let guestId = null;
/**
 * Used for clearing guest id from memory and storage
 */
export const clearGuestId = () => {
    guestId = null;
    removeCookieAndFallback(EnumFunnelTrackingCookies.FW_GUEST_ID);
};
/**
 *
 */
export const isValidGuestId = (guestId) => !!guestId && guestId !== 'null' && guestId !== 'undefined';
/**
 * Set guest id into memory and storage
 */
export const setGuestId = (newGuestId) => {
    guestId = newGuestId;
    setCookieWithFallback(EnumFunnelTrackingCookies.FW_GUEST_ID, newGuestId);
};
/**
 * Reading of guest_id from storage
 */
const fetchGuestId = () => getCookieWithFallback(EnumFunnelTrackingCookies.FW_GUEST_ID)?.value;
/**
 * Only public facing function to get guest id.
 * Resolve steps:
 *  1. get guest_id from memory
 *  2. get guest_id from storage and put to memory
 *  3. create new guest_id and put to storage and memory
 */
export const getGuestId = () => {
    try {
        if (guestId) {
            return guestId;
        }
        const persistedGuestId = fetchGuestId();
        if (persistedGuestId && isValidGuestId(persistedGuestId)) {
            guestId = persistedGuestId;
            return guestId;
        }
        else {
            clearGuestId();
        }
        guestId = uuidv4();
        setGuestId(guestId);
        return guestId;
    }
    catch (e) {
        // it's possible that writing to storages will fail, for example exceeded quota
        // in that case we just store in memory and return
        guestId = uuidv4();
        return guestId;
    }
};
