import { useEffect, useRef, useState } from 'react';
import { fetchFwaiAds } from '@ads/utils/api';
import { customEvent, EventType } from '@core/customEvent';
/**
 * [CS-6333] Uses externally-provided vast_xml and ad_positions params
 */
export const useExternalVastXmlAds = (params) => {
    const { ad_positions = '0', vast_xml, ad_channel_id } = params;
    useEffect(() => {
        // Workaround for Albertson to null out the videos by updating the vast_xml attribute
        if (vast_xml === 'null') {
            setState({
                loading: false,
                adVideos: [],
                positions: [],
            });
        }
    }, [vast_xml]);
    const [state, setState] = useState(() => {
        if (vast_xml) {
            return {
                loading: true,
                adVideos: [],
                positions: ad_positions.split(',').map(Number),
            };
        }
        return null;
    });
    const loading = useRef(false);
    useEffect(() => {
        if (!(vast_xml && ad_channel_id) || loading.current) {
            return;
        }
        const fetchAds = async () => {
            loading.current = true;
            try {
                const adVideos = await fetchFwaiAds({
                    xml: vast_xml,
                    query: {
                        fw_ad_channel: ad_channel_id,
                    },
                    rethrow: true,
                    ad_host: params.api_host,
                });
                if (!adVideos.length) {
                    customEvent({
                        data: {
                            name: params.name,
                        },
                        target: params.target,
                        type: EventType.FW_ADS_UNFILLED_AD,
                    });
                }
                else {
                    customEvent({
                        data: {
                            name: params.name,
                        },
                        target: params.target,
                        type: EventType.FW_ADS_FILLED_AD,
                    });
                }
                loading.current = false;
                // This null coalescing isn't actually necessary but TS can't tell that to get here, state is already defined
                setState((state) => ({
                    positions: state?.positions ?? [],
                    adVideos,
                    loading: false,
                }));
            }
            catch (e) {
                customEvent({
                    data: {
                        error: e.message,
                        name: params.name,
                    },
                    target: params.target,
                    type: EventType.FW_ADS_UNFILLED_AD,
                });
                setState({
                    positions: [],
                    adVideos: [],
                    loading: false,
                });
            }
        };
        if (vast_xml !== 'null') {
            fetchAds();
        }
    }, [ad_channel_id, params, vast_xml]);
    return state;
};
