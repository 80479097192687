import { DESKTOP_WIDTH_BREAKPOINT, PIP_REDUCED_LONG_LENGTH, PIP_REDUCED_SHORT_LENGTH, PIP_STANDARD_LONG_LENGTH, PIP_STANDARD_SHORT_LENGTH, } from '@embed/components/VideoPlayer/constants';
/**
 * It returns the maximum allowable translate values in x and y direction, based
 * on window size and PIP size.
 */
export const resolveTranslateRange = ({ windowSize, isHorizontal, playerMargin, }) => {
    const [x, y] = windowSize.width < DESKTOP_WIDTH_BREAKPOINT
        ? [PIP_REDUCED_SHORT_LENGTH, PIP_REDUCED_LONG_LENGTH]
        : [PIP_STANDARD_SHORT_LENGTH, PIP_STANDARD_LONG_LENGTH];
    // size in x and y direction changes based on whether the player is horizontal or vertical
    const xPipSize = isHorizontal ? y : x;
    const yPipSize = isHorizontal ? x : y;
    // DraggableWrapper keeps minimized player within the window so the max in
    // either direction is the window size, minus the size of the player in that
    // axis
    const xRange = windowSize.width - xPipSize - playerMargin;
    const yRange = windowSize.height - yPipSize - playerMargin;
    return { xRange, yRange };
};
