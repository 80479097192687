import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BORDER_RADIUS } from '@embed/styled';
import { ScrollDirectionEnum } from './GridConstants';
export const GridWrapper = styled.div `
  color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  overflow: auto;

  --border-radius: var(
    --thumbnail-border-radius,
    ${(props) => (props.borderStyle === 'hard' ? '0' : BORDER_RADIUS)}
  );
`;
export const GridInner = styled.div `
  position: relative;
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
`;
export const Grid = styled.div `
  transform: translateZ(0);
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  gap: var(--thumbnail-gap, 10px);

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${(props) => (props.gridMode === 'pinned' || props.gridMode === 'row') &&
    css `
      border-radius: var(--border-radius);
    `}

  ${(props) => props.gridMode === 'grid' &&
    css `
      display: grid !important;
      grid-template-columns: repeat(${props.columns}, 1fr);
      position: relative;
      left: auto;
      right: auto;
      width: 100%;
    `}
`;
export const RowNavArrow = styled.button `
  background-color: transparent;
  background-position:
    center center,
    center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size:
    40px 40px,
    cover;
  border: 0;
  cursor: pointer;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 46px;
  z-index: 1;

  ${(props) => props.direction === ScrollDirectionEnum.RIGHT &&
    css `
      background-image: url('${props.img}'),
        linear-gradient(to right, transparent, rgb(0 0 0 / 50%));
      border-bottom-right-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      right: 0;

      &:hover {
        background-image: url('${props.img}'),
          linear-gradient(to right, transparent, rgb(0 0 0 / 75%));
      }
    `};

  ${(props) => props.direction === ScrollDirectionEnum.LEFT &&
    css `
      background-image: url('${props.img}'),
        linear-gradient(to right, rgb(0 0 0 / 50%), transparent);
      border-bottom-left-radius: var(--border-radius);
      border-top-left-radius: var(--border-radius);
      left: 0;

      &:hover {
        background-image: url('${props.img}'),
          linear-gradient(to right, rgb(0 0 0 / 75%), transparent);
      }
    `};

  &:focus-visible {
    outline-offset: -3px;
  }
`;
export const LoadMoreButton = styled('button') `
  align-self: center;
  background: rgb(128 128 128 / 80%);
  border-radius: ${BORDER_RADIUS};
  border: none;
  color: white;
  font-size: 1.4em;
  grid-column: 1 / -1;
  line-height: 2em;
  margin: 1em;
  outline: none;
  padding: 0 2em;
  white-space: nowrap;
`;
export const GridRowSlot = styled('slot') `
  &::slotted(*) {
    grid-column: 1 / -1;
  }
`;
