import { decodeUrl as base64decodeUrl } from 'compact-base64';
import { liveStreamActionsSelector } from '@core/livestream/model';
import { logError } from '@core/logging';
import { getGlobalStore } from '@core/store';
import { parseQueryParams } from '@core/window';
const INVITATION_QUERY_KEY = 'fwinvite';
const INVITATION_TOKEN_KEY = 'invitation_token';
const LIVE_STREAM_ID_KEY = 'live_stream_id';
function logQueryError(query) {
    logError(new Error(`Invalid invitation query string: ${query}`));
}
/**
 * Loads invitation from URL params and adds it to the global store.
 */
export function loadInvitation() {
    if (!location?.search) {
        return;
    }
    const encodedData = parseQueryParams(location.search)[INVITATION_QUERY_KEY];
    if (!encodedData) {
        return;
    }
    try {
        const data = JSON.parse(base64decodeUrl(encodedData));
        if (!data[INVITATION_TOKEN_KEY] || !data[LIVE_STREAM_ID_KEY]) {
            logQueryError(encodedData);
            return;
        }
        const { inviteReceived } = liveStreamActionsSelector(getGlobalStore().getState());
        inviteReceived(data[INVITATION_TOKEN_KEY], data[LIVE_STREAM_ID_KEY]);
    }
    catch {
        logQueryError(encodedData);
    }
}
