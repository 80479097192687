import { withLenses } from '@dhmk/zustand-lens';
import { subscribeWithSelector } from 'zustand/middleware';
import createStore from 'zustand/vanilla';
import { isDebug } from '@core/debug';
import { getFWN } from '@core/fwn';
import isEqual from '@core/lodash/isEqual';
import { addDevtool } from './helpers';
import { addPersist } from './persist';
import { createState } from './state';
let globalStore = getFWN()?.globalStore;
let ref = {};
/** Rehydrates store on DOM storage event. */
function withStorageDOMEvents(store) {
    const storageEventCallback = (e) => {
        if (e.key === store.persist?.getOptions().name && e.newValue) {
            const parsed = JSON.parse(e.newValue);
            // [CS-4873] when we try to sync storage changes without checking if the two values are actually equal, we can
            // end up in an infinite update loop that causes all kinds of downstream issues. Using JSON.parse with lodash's isEqual to ensure
            // property order doesn't make it seem like something's changed when it hasn't
            if (!isEqual(parsed, ref)) {
                store.persist.rehydrate();
                ref = parsed;
            }
        }
    };
    window.addEventListener('storage', storageEventCallback);
    return () => {
        window.removeEventListener('storage', storageEventCallback);
    };
}
function createGlobalStore() {
    let storeCreator = withLenses(createState);
    // Note: devtools have to be attached after lenses are applied
    if (isDebug()) {
        storeCreator = addDevtool(storeCreator);
    }
    // Do not add persist middleware in tests because it will cause side effects
    // that are carried out across tests.
    if (typeof jest === 'undefined') {
        storeCreator = addPersist(storeCreator);
    }
    const store = createStore(subscribeWithSelector(storeCreator));
    withStorageDOMEvents(store);
    return store;
}
/**
 * @returns The cached global store instance or creates a new one.
 */
export const getGlobalStore = () => {
    if (!globalStore) {
        globalStore = createGlobalStore();
        const fwn = getFWN();
        if (fwn) {
            fwn.globalStore = globalStore;
        }
    }
    return globalStore;
};
/** Resets the global store instance. */
export const resetGlobalStore = () => {
    globalStore = undefined;
    if (typeof window !== 'undefined' && window._fwn) {
        window._fwn.globalStore = undefined;
    }
};
