import { SHARE_LINK_PARAM, WHITELIST_KEYS } from '@core/sharing/constants';
/**
 * Helper function to remove `fwplayer` param and
 * other v2 `fw_` params from url.
 */
export const cleanUrlFromShareLink = (url) => {
    const urlObj = new URL(url);
    const params = [SHARE_LINK_PARAM, ...WHITELIST_KEYS];
    let hash = urlObj.hash.replace(/^#/, '');
    params.forEach((key) => {
        urlObj.searchParams.delete(key);
        if (hash.includes(key)) {
            hash = hash.replace(new RegExp(`(^|&)${key}=[^&]*`), '');
        }
    });
    // Final cleanup
    urlObj.hash = hash.replace(/^&/, '').replace(/^#/, '');
    return urlObj.toString();
};
