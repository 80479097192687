import { logError, logInfo } from '@core/logging';
import { checkFeaturesSupport, requestBackupPolyfill, requestRemotePolyfill, requestTimeout, } from './utils';
/**
 * Resolve polyfills from local test, remote (polyfill.io) or backup (static).
 */
function requestPolyfills() {
    // Test local detects to determine if polyfills are needed
    return checkFeaturesSupport().catch((e) => {
        logInfo(`FWN: ${e?.message}`);
        // Run race for polyfill.io vs. timeout
        const remotePromise = requestRemotePolyfill();
        return Promise.race([remotePromise, requestTimeout()])
            .then((msg) => {
            logInfo(`FWN: ${msg}`);
        })
            .catch((e) => {
            logInfo(`FWN: ${e?.message}`);
            // Timeout won, race again with backup
            return Promise.race([remotePromise, requestBackupPolyfill()])
                .then((msg) => {
                logInfo(`FWN: ${msg}`);
            })
                .catch((e) => {
                logError(e);
            });
        });
    });
}
/**
 * Triggers the callback when polyfills are loaded.
 */
export function ensurePolyfills(callback) {
    window.fwnPolyfillPromise = window.fwnPolyfillPromise ?? requestPolyfills();
    // Using finally so the callback will be triggered regardless whether polyfill
    // is loaded or not. This is to prevent the app from being stuck in loading.
    window.fwnPolyfillPromise.finally(() => {
        callback();
    });
}
