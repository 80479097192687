/**
 * Detect fn
 */
export default () => 'Blob' in self &&
    (function () {
        try {
            new Blob();
            return true;
        }
        catch (e) {
            return false;
        }
    })() &&
    (function () {
        try {
            Blob();
            return false;
        }
        catch (e) {
            return true;
        }
    })();
