import { withLenses } from '@dhmk/zustand-lens';
import create from 'zustand';
import { isDebug } from '@core/debug';
import { createContext } from '@core/zustand';
import { actions } from './actions';
import { addDevtool } from './helpers';
import { initialState } from './initialState';
/**
 *
 */
export function createInitStateCreator(extraState) {
    return (set, get) => {
        const { actions: customActions, ...customState } = extraState?.(set, get) ?? {};
        return {
            ...initialState,
            ...customState,
            actions: {
                ...actions(set, get),
                ...customActions,
            },
        };
    };
}
/**
 * @param extraState Custom state to be merged with initial state.
 * @returns A zustand store
 */
export const createStore = (name, extraState) => {
    let createState = createInitStateCreator(extraState);
    createState = withLenses(createState);
    // Note: devtool must be configured before other middleware.
    if (isDebug()) {
        createState = addDevtool(createState, name);
    }
    const store = create(createState);
    if (window.Cypress) {
        window.Cypress['widgetStore'] = store;
    }
    return store;
};
export const { Provider, useStore, useStoreApi } = createContext();
