var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _StorageMemoryCache_data, _StorageMemoryCache_storage, _StorageMemoryCache_isStorageAccessible;
import { Cache } from './Cache';
/**
 * StorageCache wrapper for Web Storage API of localStorage/sessionStorage which implements
 * interim consent cache and flush mechanism. Can be used as memory only storage.
 *
 * Note: No `storage` event should be dispatched
 * as it will cause recursion loop in the code. Dispatching
 * such event on window wont propagate to the other tabs anyway.
 */
export class StorageMemoryCache extends Cache {
    /**
     * Constructor
     * @param storage
     * @param keys
     */
    constructor(keys, storage) {
        // console.warn('StorageMemoryCache instance created')
        super(keys);
        _StorageMemoryCache_data.set(this, void 0);
        _StorageMemoryCache_storage.set(this, void 0);
        _StorageMemoryCache_isStorageAccessible.set(this, true
        /**
         * Constructor
         * @param storage
         * @param keys
         */
        );
        __classPrivateFieldSet(this, _StorageMemoryCache_storage, storage, "f");
        __classPrivateFieldSet(this, _StorageMemoryCache_data, new Map(), "f");
        this.populate();
    }
    /**
     * Provides the number of key/value pairs currently present in the list
     */
    get length() {
        if (__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f") && this.shouldUseStorage()) {
            return [...Array(__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").length).keys()].filter((_, i) => this.validateKey(__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")?.key(i))).length;
        }
        else {
            return __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").size;
        }
    }
    /**
     * Returns stored key
     * @param key
     * @returns
     */
    getItem(key) {
        if (!this.validateKey(key)) {
            return null;
        }
        if (__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f") && this.shouldUseStorage()) {
            return __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")?.getItem(key);
        }
        return __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").get(key) ?? null;
    }
    /**
     * Sets key to value
     * @param key
     * @param value
     */
    setItem(key, value) {
        if (!this.validateKey(key)) {
            return;
        }
        if (__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f") && this.shouldUseStorage()) {
            try {
                __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").setItem(key, value);
            }
            catch (e) {
                __classPrivateFieldSet(this, _StorageMemoryCache_isStorageAccessible, false, "f");
                __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").set(key, value);
            }
        }
        else {
            __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").set(key, value);
        }
    }
    /**
     * Removes item from storage
     * @param key
     */
    removeItem(key) {
        if (!this.validateKey(key)) {
            return;
        }
        if (__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f") && this.shouldUseStorage()) {
            __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").removeItem(key);
        }
        __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").delete(key);
    }
    /**
     * Removes all key/value pairs, if there are any.
     */
    clear() {
        if (__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f") && this.shouldUseStorage()) {
            for (let i = 0; i < __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").length; i++) {
                const key = __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").key(i);
                if (this.validateKey(key)) {
                    __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").removeItem(key);
                }
            }
        }
        __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").clear();
    }
    /**
     * Returns the name of the nth key, or null if n is greater than or equal to the number of key/value pairs.
     * Note: Its not possible to implement this method as all keys might be scattered between multiple caches.
     * @param _index
     */
    key(_index) {
        throw new Error('Method not implemented.');
    }
    /**
     * Test storage instance availability and consent
     * @returns
     */
    shouldUseStorage() {
        return (Boolean(__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")) &&
            __classPrivateFieldGet(this, _StorageMemoryCache_isStorageAccessible, "f") &&
            this.isConsentGiven());
    }
    /**
     * Load the storage content into memory interim cache. Sweeps
     * the storage if needed (e.g.: Consent is removed).
     */
    populate(opts = {}) {
        try {
            if (__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")) {
                const length = __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").length;
                for (let i = 0; i < length; i++) {
                    const key = __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").key(i);
                    if (this.validateKey(key)) {
                        __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").set(key, __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f").getItem(key));
                    }
                }
                if (opts.sweep && length && length > 0) {
                    const keys = new Array(length)
                        .fill(undefined)
                        .map((_, i) => __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")?.key(i))
                        .filter((key) => key && this.validateKey(key));
                    keys.forEach((key) => {
                        __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")?.removeItem(key);
                    });
                }
            }
        }
        catch (e) { }
    }
    /**
     * Flush the interim consent cache to the localStorage/sessionStorage instance.
     */
    flush() {
        if (__classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")) {
            try {
                __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").forEach((value, key) => {
                    if (value) {
                        __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")?.setItem(key, value);
                    }
                });
                __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").clear();
            }
            catch (e) {
                __classPrivateFieldSet(this, _StorageMemoryCache_isStorageAccessible, false, "f");
            }
        }
    }
    /**
     * Clear the storage for test purposes. Skip consent checks.
     */
    clearForTest() {
        __classPrivateFieldGet(this, _StorageMemoryCache_storage, "f")?.clear();
        __classPrivateFieldGet(this, _StorageMemoryCache_data, "f").clear();
    }
}
_StorageMemoryCache_data = new WeakMap(), _StorageMemoryCache_storage = new WeakMap(), _StorageMemoryCache_isStorageAccessible = new WeakMap();
