/**
 * List of add-to-calendar widget variables.
 */
export const VARIABLES = [
    ['--add-to-calendar-background', { external: true }],
    ['--add-to-calendar-border', { external: true }],
    ['--add-to-calendar-color', { external: true }],
    ['--add-to-calendar-font-family', { external: true }],
    ['--add-to-calendar-font-size', { external: true }],
    ['--add-to-calendar-font-weight', { external: true }],
    ['--add-to-calendar-height', { external: true }],
    ['--add-to-calendar-hover-background', { external: true }],
    ['--add-to-calendar-hover-border', { external: true }],
    ['--add-to-calendar-hover-color', { external: true }],
    ['--add-to-calendar-hover-font-size', { external: true }],
    ['--add-to-calendar-hover-font-weight', { external: true }],
    ['--add-to-calendar-width', { external: true }],
];
