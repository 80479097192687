import { logError } from '@core/logging';
import { removeCookie } from '@core/storage';
import { removeItem } from '@core/storage/localStorage';
/**
 * Remove cookie from current domain and all subdomains including root domain.
 */
export function removeCookieAndFallback(key, domain = window?.location?.hostname) {
    try {
        // Remove the key of the current domain name
        removeCookie(key);
        // Remove from all subdomains including root domain
        const domainSplitArr = domain.split('.');
        for (let i = 1; i < domainSplitArr.length; i++) {
            const domainToTry = domainSplitArr.slice((i + 1) * -1).join('.');
            try {
                removeCookie(key, { domain: domainToTry });
            }
            catch (err) { }
        }
        removeItem(key);
    }
    catch (error) {
        logError(error);
    }
}
