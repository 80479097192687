import { theme as darkTheme } from './dark';
import { theme as lightTheme } from './light';
export const theme = {
    key: 'preferred',
    variables: lightTheme.variables,
    scheme: {
        light: lightTheme.variables,
        dark: darkTheme.variables,
    },
};
