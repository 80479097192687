import { logError } from '@core/logging';
import { getCookieWithFallback } from '@core/tracking/funnelTracking';
import { FWREFERRER_KEY } from './constant';
/**
 * Read `fwreferrer` values from store (localStorage) and compose an object
 * which is to be added to each tracking request data.
 */
export function getFWReferrerForTracking() {
    try {
        let fwreferrer = getCookieWithFallback(FWREFERRER_KEY)?.value;
        if (typeof fwreferrer === 'string') {
            fwreferrer = JSON.parse(fwreferrer);
        }
        return {
            _referrer_video_id: fwreferrer?.video_id ?? '',
            _referrer_channel_id: fwreferrer?.channel_id ?? '',
            _referrer_product_id: fwreferrer?.product_id ?? '',
            referrer_origin: fwreferrer?.origin ?? '',
            referrer_guest_id: fwreferrer?.guest_id ?? '',
            _referrer_business_store_id: fwreferrer?.business_store_id ?? '',
        };
    }
    catch (e) {
        logError(e);
        return {};
    }
}
