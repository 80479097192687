import { useEffect, useMemo, useState } from 'react';
import { fetchAdSettings } from '@expPlatform/embeds/ads/utils/fetchAdSettings';
import { Api_AdPlacementType } from '@core/api';
import memoize from '@core/lodash/memoize';
import { useSessionStorage } from '@core/storage/hooks';
import { StorageKeyEssentialEnum } from '@core/storage/types';
import { isMobileWeb } from '@core/userAgent';
import { uuidv4 } from '@embed/helpers';
/**
 * Returns ad config from storage if it's not too old.
 * @param fromStorage
 * @returns
 */
const getAdConfigFromStorage = (fromStorage) => {
    if (!fromStorage || !fromStorage.expire) {
        return undefined;
    }
    const TOO_OLD = 30 * 60 * 1000; //30mins
    if (Date.now() > fromStorage.expire + TOO_OLD) {
        return undefined;
    }
    return fromStorage.config;
};
/**
 * Returns tuple of storage key and object key based on
 * widget params configuration.
 * @param params
 * @param appContext
 * @returns
 */
const getStorageAndObjectKey = (params, appContext) => {
    switch (true) {
        case !!params.playlist:
            return [StorageKeyEssentialEnum.fw_ads_spcfgpl, params.playlist || ''];
        case !!params.channel:
        case !!params.channel_id:
            return [
                StorageKeyEssentialEnum.fw_ads_spcfgchnl,
                params.channel || params.channel_id || '',
            ];
        case !!appContext?.oauthClientId:
            return [
                StorageKeyEssentialEnum.fw_ads_spcfgoauthcid,
                appContext?.oauthClientId || '',
            ];
        default:
            return ['', ''];
    }
};
const hydrateOutstreamAdConfig = memoize((adTag) => {
    const outstreamAdTag = {
        // in future we may change this, but in current POC we will assume it's criteo
        // since this is not used anywhere else
        ad_system: 'criteo',
        ad_sdk: 'criteo',
        ad_insertion_method: 'FWAI',
        ad_tag: adTag,
        custom_params: '',
        id: uuidv4(),
    };
    const outstreamPlacement = {
        id: uuidv4(),
        positions: [0],
        refresh_interval: 90,
        placement_type: Api_AdPlacementType.OUTSTREAM,
        initial_offset: 3,
        ad_video_interval: 3,
        ad_tags: [outstreamAdTag],
    };
    const outstreamAdConfig = {
        ad_target_platform: 'web',
        context: 'embed',
        dedupe_direct_ads: false,
        id: uuidv4(),
        presentation_type: 'player',
        placements: [outstreamPlacement],
    };
    return outstreamAdConfig;
});
/**
 * Hook to fetch and cache AdConfig from API/storage.
 * Used by `useEmbedAppContext` to provide ad config to the app.
 */
export const useAdConfigStorage = (params, appContext) => {
    const isDesktopMode = !isMobileWeb();
    const [storageKey, objectKey] = useMemo(() => getStorageAndObjectKey(params, appContext), [appContext, params]);
    const [storedValue, setStoredValue] = useSessionStorage(storageKey, {});
    const adConfigStorage = objectKey ? storedValue[objectKey] : undefined;
    const [adConfig, setAdConfig] = useState(getAdConfigFromStorage(adConfigStorage));
    const isExpired = adConfigStorage?.expire && Date.now() > adConfigStorage?.expire;
    const adSettingsUrl = useMemo(() => {
        try {
            const url = new URL(`${params?.api_host_ads}/api/ad_setting`);
            url.searchParams.set('ad_target_platform', isDesktopMode ? 'desktop_web' : 'mobile_web');
            if (params?.playlist) {
                url.searchParams.set('playlist_id', params.playlist);
            }
            else if (params?.channel) {
                url.searchParams.set('channel', params?.channel);
            }
            else if (appContext?.oauthClientId) {
                url.searchParams.set('oauth_app_uid', appContext?.oauthClientId);
            }
            else {
                throw new Error('Missing required params');
            }
            return url.toString();
        }
        catch {
            return '';
        }
    }, [
        appContext?.oauthClientId,
        isDesktopMode,
        params?.api_host_ads,
        params?.channel,
        params.playlist,
    ]);
    useEffect(() => {
        ;
        (async () => {
            // TODO(AD-227): Make `show_ads` reactive so setting to adConfig to null wont disable
            // ads forever. Changing show_ads on the fly should enable ads again.
            if (!params.show_ads) {
                setAdConfig(null);
                return;
            }
            // if sp_outstream_tag exist, we are creating ad config for Criteo
            if (params.sp_outstream_tag) {
                setAdConfig(hydrateOutstreamAdConfig(params.sp_outstream_tag));
                return;
            }
            if ((typeof adConfig === 'undefined' || isExpired) && storageKey) {
                try {
                    const adConfigResponse = await fetchAdSettings(adSettingsUrl);
                    // GET /api/ad_setting can return 200 with error, ie. {error: 'playlist not found'}
                    if (!adConfigResponse || adConfigResponse.error) {
                        throw new Error('api');
                    }
                    if (!adConfig) {
                        setAdConfig(adConfigResponse);
                    }
                    const ttl = 5 * 60 * 1000; //5min
                    setStoredValue({
                        ...storedValue,
                        [objectKey]: {
                            expire: new Date().getTime() + ttl,
                            config: adConfigResponse,
                        },
                    });
                }
                catch {
                    // in case of error we store null for shorter time to prevent calling server all the time
                    const ttl = 60 * 1000;
                    setStoredValue({
                        ...storedValue,
                        [objectKey]: {
                            expire: new Date().getTime() + ttl,
                            config: null,
                        },
                    });
                    setAdConfig(null);
                }
            }
        })();
    }, [
        adConfig,
        adSettingsUrl,
        isExpired,
        objectKey,
        params.show_ads,
        params.sp_outstream_tag,
        setStoredValue,
        storageKey,
        storedValue,
    ]);
    return adConfig;
};
