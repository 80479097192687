import { initAllFwnModules } from '@assets/fwnInit';
import { yieldToMain } from '@core/helpers/yieldToMain';
async function main() {
    initAllFwnModules();
    // Import webcomponents so they
    // are bundled with fwn.js. Break the
    // components to individual tasks so they
    // won't cause long-running task warnings.
    await yieldToMain();
    require('../embed-feed-element');
    await yieldToMain();
    require('../storyblock-element');
    await yieldToMain();
    require('../player-element');
    await yieldToMain();
    require('../storylink-element');
    await yieldToMain();
    require('../herounit-element');
    await yieldToMain();
    require('../add-to-calendar-element');
    await yieldToMain();
    require('../share-element');
    await yieldToMain();
    require('../button-wrapper-element');
    await yieldToMain();
    require('../showroom-schedule-element');
}
main();
