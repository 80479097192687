import styled from '@emotion/styled';
import { Z_DRAG_WRAPPER } from '@core/zindexes';
import { DESKTOP_MEDIAQUERY } from '@embed/components/helpers';
export const Container = styled('div') `
  font-size: 12px;
  line-height: 1.2;

  & a,
  & button {
    -webkit-tap-highlight-color: transparent;
  }
`;
export const Wrapper = styled.div `
  font-weight: normal;

  ${(props) => props.gridMode === 'pinned' &&
    `
    background: black;
    box-sizing: border-box;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 5px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;

    overflow: hidden;
    z-index: ${Z_DRAG_WRAPPER} !important;

    @media only screen and (${DESKTOP_MEDIAQUERY}) {
      border-radius: 12px 12px 0 0;
      left: 50%;
      width: 60vw;
      transform: translateX(-50%);
    }
  `};
  ${(props) => props.gridMode === 'row' &&
    `
    position: relative;
  `}
`;
