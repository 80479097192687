/**
 * Checks for empty objects or arrays
 * @param obj
 * @returns
 */
function isEmpty(obj) {
    return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    [Object, Array].includes((obj || {}).constructor) &&
        !Object.entries(obj || {}).length);
}
export default isEmpty;
