import { useCallback, useEffect, useMemo, useState } from 'react';
import { AdFetchingStatus } from '@expPlatform/embeds/ads/constants';
import { getFWAIAds } from '@expPlatform/embeds/ads/utils/adTag';
import { Api_AdPlacementType } from '@core/api';
import uniqBy from '@core/lodash/uniqBy';
/**
 *
 */
export const useAdInFeed = (props) => {
    const { appContext, params } = props;
    const adPlacement = useMemo(() => {
        return appContext?.adConfig?.placements?.find((placement) => placement.placement_type === Api_AdPlacementType.RM_INFEED ||
            placement.placement_type === Api_AdPlacementType.OUTSTREAM);
    }, [appContext]);
    const [state, setState] = useState({
        loading: false,
        adVideos: [],
        positions: [],
    });
    useEffect(() => {
        if (!params.vast_attrs && params.wait_for_vast_attrs === true) {
            return;
        }
        if (!!adPlacement && adPlacement.ad_tags.length > 0) {
            setState((state) => ({
                ...state,
                loading: true,
            }));
        }
    }, [adPlacement, params.vast_attrs, params.wait_for_vast_attrs]);
    const loadAds = useCallback(async (adUnit) => {
        const response = await getFWAIAds(adUnit.ad_tags[0], params);
        if (response.status === AdFetchingStatus.OK) {
            setState((state) => {
                let adVideos = uniqBy(state.adVideos.concat(response.ads), (video) => video.encoded_id);
                if (params.allow_duplicate_ads) {
                    const counts = {};
                    adVideos = response.ads.map((ad) => {
                        var _a;
                        counts[_a = ad.encoded_id] ?? (counts[_a] = -1);
                        const count = ++counts[ad.encoded_id];
                        let suffix = '';
                        if (count > 0) {
                            suffix = `--${Math.random()}`;
                        }
                        return { ...ad, encoded_id: `${ad.encoded_id}${suffix}` };
                    });
                }
                return {
                    loading: false,
                    adVideos,
                    positions: adUnit.positions || [0],
                };
            });
        }
        else {
            setState((state) => ({
                ...state,
                loading: false,
            }));
        }
        return response;
    }, 
    // TODO(ZE-231): Fix the deps and re-enable the rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adPlacement, params]);
    useEffect(() => {
        if (!state.loading) {
            return;
        }
        if (!adPlacement) {
            setState((state) => ({
                ...state,
                loading: false,
            }));
            return;
        }
        ;
        (async () => {
            const response = await loadAds(adPlacement);
            // if we are in outstream player and get only one ad from first call, we prefetch next ad with small delay
            if (adPlacement.placement_type === Api_AdPlacementType.OUTSTREAM &&
                adPlacement.ad_tags[0]?.ad_sdk !== 'criteo' &&
                response.status === AdFetchingStatus.OK &&
                response.ads.length === 1) {
                setTimeout(async () => {
                    await loadAds(adPlacement);
                }, 3000);
            }
        })();
        // TODO(ZE-231): Fix the deps and re-enable the rule
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adPlacement, state.loading, params.vast_attrs]);
    const loadMore = useCallback(() => {
        if (adPlacement &&
            adPlacement.placement_type === Api_AdPlacementType.OUTSTREAM &&
            adPlacement.ad_tags[0]?.ad_sdk !== 'criteo') {
            loadAds(adPlacement);
        }
    }, [adPlacement, loadAds]);
    return useMemo(() => ({
        ...state,
        actions: {
            loadMore,
        },
    }), [state, loadMore]);
};
