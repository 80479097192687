import React from 'react';
import styled from '@emotion/styled';
import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from '@core/styles/cssVariables';
import { THUMBNAIL_CONTAINER } from '@player/testIds';
import { THUMBNAIL_SIZE_LARGE, THUMBNAIL_SIZE_MEDIUM, THUMBNAIL_SIZE_SMALL, } from '../../styled';
const [WIDTH_MEDIUM, HEIGHT_MEDIUM] = THUMBNAIL_SIZE_MEDIUM;
const [WIDTH_SMALL, HEIGHT_SMALL] = THUMBNAIL_SIZE_SMALL;
const [WIDTH_LARGE, HEIGHT_LARGE] = THUMBNAIL_SIZE_LARGE;
/**
 * Thumbnail wrapper to provide 1,65 aspect ratio
 * for its children. Pinned and row mode uses exact size 154x250.
 */
const Inner = styled.div `
  box-sizing: border-box;

  overflow: hidden;
  padding-top: 0;
  position: relative;

  ${(props) => `
    aspect-ratio: var(--thumbnail-aspect-ratio, ${props.width} / ${props.height});
    flex: 0 0 var(${THUMBNAIL_WIDTH}, ${props.width}px);
    height: var(${THUMBNAIL_HEIGHT}, ${props.height}px);
    width: var(${THUMBNAIL_WIDTH}, ${props.width}px);
  `}

  ${(props) => props.displayMode === 'grid' &&
    `
    flex: 0;
    height: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 165%;
    width: 100%;
  `}
`;
const Outer = styled.div `
  border-radius: var(--border-radius);
  ${({ showV2Card }) => showV2Card
    ? `
    border-radius: 0;
  `
    : ''}
  box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  width: 100%;
`;
/**
 *
 */
const ThumbnailWrapper = ({ mode, isLarge, isSmall, children, label, isPlaceholder, showV2Card, ...rest }) => {
    // Large carousel experiment
    const width = isLarge ? WIDTH_LARGE : isSmall ? WIDTH_SMALL : WIDTH_MEDIUM;
    const height = isLarge ? HEIGHT_LARGE : isSmall ? HEIGHT_SMALL : HEIGHT_MEDIUM;
    return (<Inner role="group" aria-roledescription="slide" aria-label={label} displayMode={mode} height={height} width={width} {...rest}>
      <Outer showV2Card={!!showV2Card && !isPlaceholder} data-testid={THUMBNAIL_CONTAINER}>
        {children}
      </Outer>
    </Inner>);
};
export default ThumbnailWrapper;
