import { DEVICE_SETTINGS_SLICE_KEY } from './slice';
/**
 *
 */
export function partialize(state) {
    // actions is needed to extract device settings state.
    // we do not want to persist failedToGetCameraId state.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { actions, failedToGetCameraId, ...deviceSettingsState } = state[DEVICE_SETTINGS_SLICE_KEY];
    return {
        [DEVICE_SETTINGS_SLICE_KEY]: {
            ...deviceSettingsState,
        },
    };
}
/**
 *
 */
export function merge(persistedState, currentState) {
    return {
        [DEVICE_SETTINGS_SLICE_KEY]: {
            ...currentState[DEVICE_SETTINGS_SLICE_KEY],
            ...persistedState[DEVICE_SETTINGS_SLICE_KEY],
        },
    };
}
