import { Fragment, lazy, Suspense } from 'react';
const PlaylistThumbnailComponent = lazy(() => import('./PlaylistThumbnail'));
const PlaylistVideoPlayerComponent = lazy(() => import('./PlaylistVideoPlayer'));
/**
 * Lazy loaded PlaylistThumbnail component
 * @param props
 * @returns
 */
export const PlaylistThumbnail = (props) => (<Suspense fallback={<Fragment />}>
    <PlaylistThumbnailComponent {...props}/>
  </Suspense>);
/**
 * Lazy loaded PlaylistVideoPlayer component
 * @param props
 * @returns
 */
export const PlaylistVideoPlayer = (props) => (<Suspense fallback={<Fragment />}>
    <PlaylistVideoPlayerComponent {...props}/>
  </Suspense>);
