import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlayerLayout } from '@core/player/types';
import { GLOBAL_PLAYERS_STATE_SYNC } from '@embed/components/VideoPlayer/constants';
import { openReasonPriority, VideoPlayerOpenReason, } from '@embed/components/VideoPlayer/types';
/**
 *
 */
export const usePlayerState = (appContext, params) => {
    const [playersStateSync, setPlayersStateSync] = useState(typeof window !== 'undefined' && window._fwn?.playersStateSync
        ? window._fwn.playersStateSync
        : {});
    useEffect(() => {
        const listener = (event) => {
            if (event.data?.sentinel === 'fwn-internal') {
                const { action, state } = event.data;
                if (action !== GLOBAL_PLAYERS_STATE_SYNC) {
                    return;
                }
                setPlayersStateSync(state);
            }
        };
        try {
            window.parent.addEventListener('message', listener);
            return () => {
                window.parent.removeEventListener('message', listener);
            };
        }
        catch (e) {
            window.addEventListener('message', listener);
            return () => {
                window.removeEventListener('message', listener);
            };
        }
    }, [params.channel, params.playlist]);
    const videoPlayerState = useMemo(() => {
        for (const widgetId of Object.keys(playersStateSync)) {
            const player = playersStateSync[widgetId];
            if (player.layout === PlayerLayout.MINIMIZED ||
                player.layout === PlayerLayout.FULLSCREEN) {
                return player;
            }
        }
        return null;
    }, [playersStateSync]);
    const isOpened = params.widget_id && playersStateSync[params.widget_id]
        ? playersStateSync[params.widget_id].openReason !==
            VideoPlayerOpenReason.NOT_OPENED
        : false;
    const canClaimPlayer = useCallback((reason) => {
        return (!videoPlayerState ||
            videoPlayerState.widget_id === params.widget_id ||
            openReasonPriority[videoPlayerState.openReason] <=
                openReasonPriority[reason]);
    }, 
    // TODO(ZE-231): Fix the deps and re-enable the rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videoPlayerState]);
    const isPlayingCurrentContent = useMemo(() => {
        if (!videoPlayerState) {
            return false;
        }
        return !!((params.playlist && params.playlist === videoPlayerState.playlist) ||
            (appContext?.username &&
                !params.playlist &&
                !videoPlayerState.playlist &&
                videoPlayerState.channel === appContext?.username));
        // TODO(ZE-231): Fix the deps and re-enable the rule
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoPlayerState]);
    const currentPlayerId = videoPlayerState?.widget_id || null;
    const currentPlayerLayout = videoPlayerState?.layout || null;
    const currentPlayerVideoId = videoPlayerState?.videoId || null;
    return useMemo(() => {
        return {
            canClaimPlayer,
            isOpened,
            isPlayingCurrentContent,
            currentPlayerId,
            currentPlayerLayout,
            currentPlayerVideoId,
        };
    }, [
        canClaimPlayer,
        isOpened,
        isPlayingCurrentContent,
        currentPlayerId,
        currentPlayerLayout,
        currentPlayerVideoId,
    ]);
};
