/**
 * @fileoverview FW widgets can read URL params with `fwparam_` prefix and
 * override widget params of the same name.
 *
 * For example:
 * If we render <fw-storyblock api_host="https://myWidgetParamDomain.com/" /> in
 * https://fw.tv/embed.html?fwparam_api_host=https://myUrlParamDomain.com/
 * The `api_host` param will be set to "https://myUrlParamDomain.com/"
 */
const URL_PREFIX = 'fwparam_';
/**
 * @returns A map of url params with `fwparam_` prefix. The `fwparam_` prefix is
 *     stripped out.
 */
export const urlWidgetParams = (searchParams) => {
    const results = {};
    Object.keys(searchParams).forEach((key) => {
        const paramKey = key.substring(URL_PREFIX.length);
        if (key.startsWith(URL_PREFIX)) {
            results[paramKey] = searchParams[key];
        }
    });
    return results;
};
