/**
 * @fileoverview
 *
 * Functions to initiate and control `_fwn.player`
 * Web SDK attribute which provides programmatic access
 * to a current fullscreen/minimized player.
 */
import { getFWN } from '@core/fwn';
import { PlayerNoop } from './PlayerNoop';
const noopPlayer = new PlayerNoop();
let activePlayerCache = null;
/**
 * Used to provide `_fwn.player` attribute getter
 * and with access to module level cache.
 */
export function fwnPlayerGetter() {
    return activePlayerCache ?? (activePlayerCache = noopPlayer);
}
/**
 * Used to provide `_fwn.player` attribute setter
 * interface to module level cache.
 */
export function fwnPlayerSetter(player) {
    activePlayerCache = player;
}
/**
 * Used by a component to set an active player via one
 * of the keys. Keys will be used to search for a player
 * in all players cache and assign a reference to it.
 */
export function setActiveFWNPlayer(keys) {
    const fwn = getFWN();
    if (fwn) {
        fwn.player = noopPlayer;
        for (const key of keys) {
            if (fwn.players[key]) {
                fwn.player = fwn.players[key];
                break;
            }
        }
    }
}
/**
 * Used by a component to unset active player during cleanup.
 */
export function unsetActiveFWNPlayer() {
    const fwn = getFWN();
    if (fwn) {
        fwn.player = noopPlayer;
    }
}
