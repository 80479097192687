import { lens } from '@dhmk/zustand-lens';
import { createLiveStreamActions } from './actions';
import { createLiveStreamState } from './state';
export const LIVESTREAM_SLICE_KEY = 'liveStream';
/**
 *
 */
export function createLiveStreamSlice() {
    return {
        [LIVESTREAM_SLICE_KEY]: lens((set) => ({
            ...createLiveStreamState(),
            actions: createLiveStreamActions(set),
        })),
    };
}
