/**
 *
 */
export function actionsSelector(state) {
    return state.actions;
}
/**
 *
 */
export function appContextSelector(state) {
    return state.appContext;
}
/**
 *
 */
export function paramsSelector(state) {
    return state.params;
}
/**
 *
 */
export function paramsLangSelector(state) {
    return state.params.lang;
}
/**
 *
 */
export function videosSelector(state) {
    return state.navigation.map((id) => state.videos[id]);
}
