/**
 * @fileoverview
 *
 * WidgetWrapper is a HOC which makes sure the Widget component
 * gets properly initialized and commonly needed store attributes are populated.
 *
 * Currently its initialization of zustand store, appContext resolving
 * and store update, fetchedParams resolving and store update.
 *
 * Both redux-zero and zustand are used to initialize the individual stores although
 * redux-zero will be deprecated soon.
 */
import { WidgetAppContext } from './WidgetAppContext';
import { WidgetDirContext } from './WidgetDirContext';
import { WidgetFeed } from './WidgetFeed';
import { WidgetParams } from './WidgetParams';
import { WidgetServiceApiProvider } from './WidgetServiceApiProvider';
import { WidgetStoreProvider } from './WidgetStoreProvider';
import { WidgetTranslationWrapper } from './WidgetTranslationWrapper';
/**
 *
 */
export const WidgetWrapper = ({ appContextType, initialParams, widgetParams, children }) => {
    return (<WidgetDirContext.Provider value={{ dir: widgetParams?.dir }}>
      <WidgetStoreProvider initialParams={initialParams} widgetParams={widgetParams}>
        <WidgetServiceApiProvider>
          <WidgetFeed appContextType={appContextType}>
            <WidgetAppContext appContextType={appContextType}>
              <WidgetParams>
                <WidgetTranslationWrapper>{children}</WidgetTranslationWrapper>
              </WidgetParams>
            </WidgetAppContext>
          </WidgetFeed>
        </WidgetServiceApiProvider>
      </WidgetStoreProvider>
    </WidgetDirContext.Provider>);
};
