import { getLocalStorage } from '@core/storage';
import { useStorage } from './useStorage';
/**
 * Simple hook to read and write to local storage. Does not synchronize or listen
 * to changes in other tabs.
 *
 * For more advanced use see `@core/hook/useLocalStorage` instead.
 */
export function useLocalStorage(key, initial) {
    const storage = getLocalStorage();
    return useStorage(key, initial, { storage });
}
