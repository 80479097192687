import { useContext } from 'react';
import { FeedQueryContext } from './contexts';
/**
 * Hook to provide access to the feed context in which we store feed controls
 * and state.
 */
export function useFeedQueryContext() {
    const value = useContext(FeedQueryContext);
    if (!value) {
        throw new Error('FeedQuery is not provided');
    }
    return value;
}
