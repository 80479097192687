import { generateId } from './utils';
/**
 *
 */
export class ProductImageBuilder {
    /**
     *
     */
    constructor() {
        this.image = {
            image_ext_id: generateId(),
            image_id: generateId(),
            image_position: 0,
            image_src: '',
            unit_ids: [],
            unit_ext_ids: [],
        };
    }
    /**
     * @deprecated Use `extId` instead.
     */
    ext_id(value) {
        // eslint-disable-next-line no-console
        console.warn('`ext_id` is deprecated, use `extId` instead.');
        return this.extId(value);
    }
    /**
     *
     */
    extId(value) {
        // Still parse it because client code might not comply with the type.
        this.image.image_ext_id = String(value);
        return this;
    }
    /**
     *
     */
    position(value) {
        this.image.image_position = Number(value);
        return this;
    }
    /**
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title(value) {
        this.image.image_alt_text = value;
        return this;
    }
    /**
     *
     */
    url(value) {
        this.image.image_src =
            typeof value === 'string' ? value.replace(/^\/\//, 'https://') : value;
        return this;
    }
    /**
     *
     */
    imageId(value) {
        this.image.image_id = value;
        return this;
    }
    /**
     *
     */
    unitIds(value) {
        this.image.unit_ids = value;
        return this;
    }
    /**
     *
     */
    unitExtIds(value) {
        this.image.unit_ext_ids = value;
        return this;
    }
    /** @deprecated use `imageId` instead. */
    _image_id(value) {
        // eslint-disable-next-line no-console
        console.warn('`_image_id` is deprecated, use `imageId` instead.');
        this.image.image_id = value;
        return this;
    }
    /** @deprecated use `unitIds` instead. */
    _unit_ids(value) {
        // eslint-disable-next-line no-console
        console.warn('`_unit_ids` is deprecated, use `unitIds` instead.');
        this.image.unit_ids = value;
        return this;
    }
    /** @deprecated use `unitExtIds` instead. */
    _unit_ext_ids(value) {
        // eslint-disable-next-line no-console
        console.warn('`_unit_ext_ids` is deprecated, use `unitExtIds` instead.');
        this.image.unit_ext_ids = value;
        return this;
    }
    /**
     * set this image as the primary (will end up being returned from `useProductImage`)
     */
    primary(value) {
        this._primary = value;
        return this;
    }
    /**
     *
     */
    build() {
        this.validate();
        return this.image;
    }
    /**
     *
     */
    validate() {
        const missingFields = [];
        if (!this.image.image_src) {
            missingFields.push('url');
        }
        if (missingFields.length > 0) {
            // Needed to show warnings to the external developer.
            // eslint-disable-next-line no-console
            console.warn(`FW Product Builder: Product image is missing required fields: ${missingFields.join(', ')}`);
        }
    }
}
