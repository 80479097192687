import { FWNPerformance } from './FWNPerformance';
/**
 * Create a FWNPerformance instance and cache it on window object
 * based on key derived from widget `params`.
 */
export function performanceFactory(params) {
    const key = Object.entries({
        [String(params.type)]: params.widget_id?.substring(0, 8),
        channel: params.channel,
        channel_id: params.channel_id,
        hashtag: params.hashtag,
        playlist: params.playlist,
        video: params.video,
    })
        .filter(([, value]) => !!value)
        .map(([key, value]) => `${key}:${value}`)
        .join('/');
    window._fwnPerformance = window._fwnPerformance || {};
    return (window._fwnPerformance[key] =
        window._fwnPerformance[key] || new FWNPerformance({ key }));
}
