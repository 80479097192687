import { getLastEngagedTimestamp } from '@core/tracking';
import { uuidv4 } from '@embed/helpers';
import { clearSessionStore, getStoredSession, setSessionIntoStore, } from './session-storage';
const VERSION = 'fws2'; // Firework Session 2
const MIN_ACTIVITY = 1000 * 60 * 30; // 30 minutes
const createSession = (previousSessionCount = 0) => {
    const sessionId = uuidv4();
    const now = new Date();
    return {
        id: sessionId,
        started_at: now.getTime(),
        type: 'embed_session',
        version: VERSION,
        session_count: previousSessionCount + 1,
    };
};
const isValidSession = (session) => {
    if (session.version !== VERSION) {
        return false;
    }
    const currentTimestamp = new Date().getTime();
    const lastActivity = getLastEngagedTimestamp();
    const lastActivityTimestamp = lastActivity && Date.parse(lastActivity);
    if (lastActivityTimestamp &&
        currentTimestamp - lastActivityTimestamp < MIN_ACTIVITY) {
        return true;
    }
    const sessionStartTimestamp = session.started_at;
    if (currentTimestamp - sessionStartTimestamp < MIN_ACTIVITY) {
        return true;
    }
    return false;
};
/**
 * Returns valid session object. If session is expired, it will
 * create new Session, store it in storage and return.
 */
export const getSession = () => {
    const session = getStoredSession();
    if (session && isValidSession(session)) {
        return session;
    }
    else {
        const newSession = createSession(session?.session_count);
        clearSessionStore();
        setSessionIntoStore(newSession);
        return newSession;
    }
};
