/**
 *
 */
export const isTruthy = (value) => {
    if (!value || value === 'false' || value === '0') {
        return false;
    }
    return true;
};
/**
 *
 */
export const isDefinedTruthy = (value) => {
    return value !== undefined && isTruthy(value);
};
/**
 *
 */
export const isFalsy = (value) => {
    if (!value ||
        value === '' ||
        (value && value === 'false') ||
        (value && value === '0') ||
        value === 0 ||
        value === false) {
        return true;
    }
    return false;
};
/**
 *
 */
export const isDefinedFalsy = (value) => {
    return value !== undefined && isFalsy(value);
};
