/* eslint-disable zeffo/no-window-storage */
import { localStorages } from '@core/storage/instances/localStorages';
import { memoryStorage } from '@core/storage/instances/memoryStorage';
import { sessionStorages } from '@core/storage/instances/sessionStorages';
/**
 * Attach storage instances to FWN object.
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'memoryStorage')) {
        Object.defineProperty(window._fwn, 'memoryStorage', {
            value: memoryStorage,
            writable: false,
        });
    }
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'localStorages')) {
        Object.defineProperty(window._fwn, 'localStorages', {
            value: localStorages,
            writable: false,
        });
    }
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'sessionStorages')) {
        Object.defineProperty(window._fwn, 'sessionStorages', {
            value: sessionStorages,
            writable: false,
        });
    }
}
